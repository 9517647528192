:root,
[data-bs-theme=light] {
  --bs-prismjs-bg: #1e1e3f;
  --bs-prismjs-border: rgba(255, 255, 255, 0.1);
  --bs-prismjs-btn-bg: #2d2d5e;
  --bs-prismjs-btn-bg-hover: #2d2d5e;
  --bs-prismjs-btn-color: rgba(255, 255, 255, 0.75);
  --bs-prismjs-btn-color-hover: #2b2f77;
  --bs-prismjs-scrollbar-color: #323268;
  --bs-prismjs-scrollbar-color-hover: #373773;
}

[data-bs-theme=dark] {
  --bs-prismjs-bg: #151521;
  --bs-prismjs-border: rgba(255, 255, 255, 0.1);
  --bs-prismjs-btn-bg: #27273d;
  --bs-prismjs-btn-bg-hover: #27273d;
  --bs-prismjs-btn-color: rgba(255, 255, 255, 0.75);
  --bs-prismjs-btn-color-hover: #2b2f77;
  --bs-prismjs-scrollbar-color: #2d2d46;
  --bs-prismjs-scrollbar-color-hover: #333350;
}

.highlight {
  position: relative;
  background: var(--bs-prismjs-bg);
  border-radius: 0.46rem;
  padding: 1.75rem 1.5rem 1.75rem 1.5rem;
}
.highlight .nav {
  border-bottom: 1px solid var(--bs-prismjs-border);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  margin-top: -0.25rem;
}
.highlight .nav .nav-item {
  margin-right: 0.75rem;
}
.highlight .nav .nav-link {
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.35rem 1rem;
  border-radius: 0.46rem;
  color: var(--bs-prismjs-btn-color);
  transition: all 0.2s ease-in-out;
  background-color: transparent;
}
.highlight .nav .nav-link:focus, .highlight .nav .nav-link.active {
  transition: all 0.2s ease-in-out;
  background-color: var(--bs-prismjs-btn-bg-hover);
  color: var(--bs-prismjs-btn-color-hover);
}
.highlight .highlight-copy {
  display: none;
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease-in-out;
  background-color: var(--bs-prismjs-btn-bg);
  color: var(--bs-prismjs-btn-color);
}
.highlight .highlight-copy:focus, .highlight .highlight-copy:hover {
  transition: all 0.2s ease-in-out;
  background-color: var(--bs-prismjs-btn-bg-hover);
  color: var(--bs-prismjs-btn-color-hover);
}
.highlight:hover .highlight-copy {
  display: flex;
}
.highlight .highlight-code pre {
  background-color: transparent;
  overflow: auto;
  padding: 0;
  margin: 0;
  scrollbar-color: var(--bs-prismjs-scrollbar-color) transparent;
}
.highlight .highlight-code pre::-webkit-scrollbar-thumb {
  background-color: var(--bs-prismjs-scrollbar-color);
}
.highlight .highlight-code pre::-webkit-scrollbar-corner {
  background-color: transparent;
}
.highlight .highlight-code pre:hover {
  scrollbar-color: var(--bs-prismjs-scrollbar-color-hover) transparent;
}
.highlight .highlight-code pre:hover::-webkit-scrollbar-thumb {
  background-color: var(--bs-prismjs-scrollbar-color-hover);
}
.highlight .highlight-code pre:hover::-webkit-scrollbar-corner {
  background-color: transparent;
}
.highlight .highlight-code pre code[class*=language-] {
  padding: 0;
  margin: 0;
  font-size: 1rem !important;
}

.fslightbox-slide-btn {
  border-radius: 0.46rem;
}

.fslightbox-toolbar {
  border-bottom-left-radius: 0.46rem;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.select2-container--bootstrap5 .select2-selection {
  box-shadow: none;
  height: auto;
  outline: none !important;
}
.select2-container--bootstrap5.select2-container--focus:not(.select2-container--disabled) .form-select-solid, .select2-container--bootstrap5.select2-container--open:not(.select2-container--disabled) .form-select-solid {
  background-color: var(--bs-gray-200);
}
.select2-container--bootstrap5.select2-container--focus:not(.select2-container--disabled) .form-select:not(.form-select-solid):not(.form-select-transparent), .select2-container--bootstrap5.select2-container--open:not(.select2-container--disabled) .form-select:not(.form-select-solid):not(.form-select-transparent) {
  border-color: var(--bs-gray-400);
}
.select2-container--bootstrap5.select2-container--disabled .form-select {
  background-color: var(--bs-gray-200);
  border-color: var(--bs-gray-300);
}
.select2-container--bootstrap5.select2-container--disabled .form-select .select2-selection__rendered,
.select2-container--bootstrap5.select2-container--disabled .form-select .select2-selection__placeholder {
  color: var(--bs-gray-500) !important;
}
.select2-container--bootstrap5.select2-container--disabled .form-select.form-select-transparent {
  background-color: transparent;
  border-color: transparent;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline {
  flex-grow: 1;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field {
  color: var(--bs-gray-700);
  font-weight: 500;
  font-family: inherit !important;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field::placeholder {
  color: var(--bs-gray-500);
}
.select2-container--bootstrap5 .select2-search.select2-search--inline .select2-search__field::-moz-placeholder {
  color: var(--bs-gray-500);
  opacity: 1;
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field {
  color: var(--bs-gray-700);
  font-family: inherit !important;
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field::placeholder {
  color: var(--bs-gray-500);
}
.select2-container--bootstrap5 .form-select-solid .select2-search.select2-search--inline .select2-search__field::-moz-placeholder {
  color: var(--bs-gray-500);
  opacity: 1;
}
.select2-container--bootstrap5 .select2-selection--single {
  display: flex;
  align-items: center;
}
.select2-container--bootstrap5 .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 0;
  padding-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--bs-input-color);
}
.select2-container--bootstrap5 .select2-selection--single .select2-selection__placeholder {
  color: var(--bs-gray-500);
}
.select2-container--bootstrap5 .select2-selection--single.form-select-solid .select2-selection__rendered {
  color: var(--bs-gray-700);
}
.select2-container--bootstrap5 .select2-selection--single.form-select-solid .select2-selection__placeholder {
  color: var(--bs-gray-500);
}
.select2-container--bootstrap5 .select2-selection--single.form-select-transparent .select2-selection__rendered {
  color: var(--bs-gray-800);
}
.select2-container--bootstrap5 .select2-selection--single.form-select-transparent .select2-selection__placeholder {
  color: var(--bs-gray-800);
}
.select2-container--bootstrap5 .select2-selection--single.form-select-dark .select2-selection__rendered {
  color: var(--bs-gray-900);
}
.select2-container--bootstrap5 .select2-selection--multiple {
  display: flex;
  align-items: center;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-search.select2-search--inline {
  display: inline-flex;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  margin: 0;
  padding: 0;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice {
  display: inline-flex;
  align-items: center;
  position: relative;
  background-color: var(--bs-gray-300);
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-gray-700);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-700%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-700%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  display: block;
  position: absolute;
  transform: translateY(-50%);
  opacity: 0.5;
  border: 0;
  transition: color 0.2s ease;
  top: 50%;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove span {
  display: none;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__remove:hover {
  opacity: 1;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-primary);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  transition: color 0.2s ease;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__rendered .select2-selection__choice .select2-selection__choice__display {
  font-weight: 500;
}
.select2-container--bootstrap5 .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  height: 0.6rem;
  width: 0.6rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm {
  min-height: calc(1.5em + 1.1rem + 2px);
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-selection__choice {
  border-radius: 0.35rem;
  padding: 0.1rem 0.35rem;
  margin-right: 0.35rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-selection__choice .select2-selection__choice__display {
  margin-left: 0.95rem;
  font-size: 0.95rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-sm .select2-search__field {
  height: 14px;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) {
  min-height: calc(1.5em + 1.55rem + 2px);
  padding-top: 0.575rem;
  padding-bottom: 0.575rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice {
  border-radius: 0.46rem;
  padding: 0.1rem 0.5rem;
  margin-right: 0.5rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-selection__choice .select2-selection__choice__display {
  margin-left: 1.1rem;
  font-size: 1.1rem;
}
.select2-container--bootstrap5 .select2-selection--multiple:not(.form-select-sm):not(.form-select-lg) .select2-search__field {
  height: 16px;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg {
  min-height: calc(1.5em + 1.65rem + 2px);
  padding-top: 0.525rem;
  padding-bottom: 0.525rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-selection__choice {
  border-radius: 0.92rem;
  padding: 0.15rem 0.65rem;
  margin-right: 0.65rem;
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-selection__choice .select2-selection__choice__display {
  margin-left: 1.25rem;
  font-size: 1.15rem;
}
.select2-container--bootstrap5 .select2-selection--multiple.form-select-lg .select2-search__field {
  height: 18px;
}
.select2-container--bootstrap5 .select2-dropdown {
  border: 0;
  box-shadow: var(--bs-dropdown-box-shadow);
  border-radius: 0.46rem;
  padding: 1rem 0;
  background-color: var(--bs-dropdown-bg);
}
.modal-open .select2-container--bootstrap5 .select2-dropdown {
  z-index: 1056;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search {
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field {
  background-color: var(--bs-body-bg);
  padding: 0.55rem 0.75rem;
  color: var(--bs-gray-700);
  font-size: 0.95rem;
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.35rem;
  outline: 0 !important;
}
.select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field:focus, .select2-container--bootstrap5 .select2-dropdown .select2-search .select2-search__field:active {
  border: 1px solid var(--bs-gray-400);
}
.select2-container--bootstrap5 .select2-dropdown .select2-results > .select2-results__options {
  max-height: 250px;
  overflow-y: auto;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option {
  color: var(--bs-gray-700);
  transition: color 0.2s ease;
  padding: 0.75rem 1.25rem;
  margin: 0 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--highlighted {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
  transition: color 0.2s ease;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
  transition: color 0.2s ease;
  position: relative;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--selected:after {
  top: 50%;
  display: block;
  position: absolute;
  transform: translateY(-50%);
  height: 0.75rem;
  width: 0.75rem;
  content: "";
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-component-hover-color);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='var%28--bs-component-hover-color%29' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 11'%3e%3cpath fill='var%28--bs-component-hover-color%29' d='M4.89557 6.49823L2.79487 4.26513C2.26967 3.70683 1.38251 3.70683 0.857309 4.26513C0.375593 4.77721 0.375593 5.57574 0.857309 6.08781L4.74989 10.2257C5.14476 10.6455 5.81176 10.6455 6.20663 10.2257L13.1427 2.85252C13.6244 2.34044 13.6244 1.54191 13.1427 1.02984C12.6175 0.471537 11.7303 0.471536 11.2051 1.02984L6.06096 6.49823C5.74506 6.83403 5.21146 6.83403 4.89557 6.49823Z'/%3e%3c/svg%3e");
  mask-position: center;
  -webkit-mask-position: center;
  right: 1.25rem;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--disabled {
  color: var(--bs-gray-400);
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__message {
  color: var(--bs-gray-600);
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group {
  padding-left: 0;
  padding-right: 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group .select2-results__group {
  display: block;
  color: var(--bs-gray-800);
  font-weight: 500;
  font-size: 1.15rem;
  padding: 0 1.25rem 0 1.25rem;
  margin: 0 0 0.25rem 0;
}
.select2-container--bootstrap5 .select2-dropdown .select2-results__option.select2-results__option--group .select2-results__option {
  padding: 0.75rem 1.25rem;
  margin: 0 0;
}
.select2-container--bootstrap5 .select2-selection__clear {
  display: block;
  height: 0.7rem;
  width: 0.7rem;
  top: 50%;
  right: 3rem;
  position: absolute;
  transform: translateY(-50%);
  background-color: var(--bs-gray-700) !important;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-gray-700);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-700%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-700%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.select2-container--bootstrap5 .select2-selection__clear span {
  display: none;
}
.select2-container--bootstrap5 .select2-selection__clear:hover {
  background-color: var(--bs-primary) !important;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-primary);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.form-floating .form-select {
  padding-top: 1.85rem !important;
}

.fv-plugins-message-container {
  --input-invalid-color: var(--bs-danger);
  margin-top: 0.3rem;
}
.fv-plugins-message-container .fv-help-block {
  color: var(--bs-danger);
  font-size: 1rem;
  font-weight: 400;
}
.fv-plugins-message-container.valid-feedback, .fv-plugins-message-container.invalid-feedback {
  display: block;
  font-weight: 400;
}

.daterangepicker {
  padding: 0;
  margin: 0;
  border: 0;
  width: auto;
  background-color: var(--bs-body-bg);
  box-shadow: var(--bs-dropdown-box-shadow);
  font-family: Inter, Helvetica, "sans-serif";
  z-index: 1000;
  border-radius: 0.46rem;
}
.daterangepicker:after, .daterangepicker:before {
  display: none;
}
.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: transparent;
}
.modal-open .daterangepicker {
  z-index: 1056;
}
.daterangepicker .calendar-table {
  background-color: var(--bs-body-bg);
  border: 0;
}
.daterangepicker .ranges {
  border-radius: 0.46rem;
  background-color: var(--bs-body-bg);
  position: relative;
  overflow: hidden;
}
.daterangepicker .ranges ul {
  padding: 1rem 0;
  width: 150px;
  overflow: auto;
  max-height: 260px;
}
.daterangepicker .ranges li {
  padding: 0.7rem 1.75rem;
  font-weight: 500;
  font-size: 1rem;
  color: var(--bs-gray-600);
  transition: color 0.2s ease;
}
.daterangepicker .ranges li:hover {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
  transition: color 0.2s ease;
}
.daterangepicker .ranges li.active {
  background-color: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
  transition: color 0.2s ease;
}
.daterangepicker.show-calendar .ranges {
  border-radius: 0;
  border-top-left-radius: 0.46rem;
  margin-top: 0;
  height: 297px;
}
.daterangepicker.show-ranges.show-calendar .ranges {
  border-right: 1px solid var(--bs-gray-200);
}
.daterangepicker.show-ranges .drp-calendar.left {
  border-left: 0;
}
.daterangepicker .drp-buttons {
  padding: 1rem 1.75rem;
  border-top: 1px solid var(--bs-gray-200);
}
.daterangepicker .drp-buttons .btn {
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
}
.daterangepicker .drp-buttons .cancelBtn {
  color: var(--bs-light-inverse);
  border-color: var(--bs-light);
  background-color: var(--bs-light);
}
.daterangepicker .drp-buttons .cancelBtn i,
.daterangepicker .drp-buttons .cancelBtn .svg-icon {
  color: var(--bs-light-inverse);
}
.daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: var(--bs-light-inverse);
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active), .daterangepicker .drp-buttons .cancelBtn.active, .daterangepicker .drp-buttons .cancelBtn.show, .show > .daterangepicker .drp-buttons .cancelBtn {
  color: var(--bs-light-inverse);
  border-color: var(--bs-light-active);
  background-color: var(--bs-light-active) !important;
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn i,
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn .svg-icon, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn i,
.btn-check:active + .daterangepicker .drp-buttons .cancelBtn .svg-icon, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) i,
.daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active) .svg-icon, .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) i,
.daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active) .svg-icon, .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) i,
.daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active) .svg-icon, .daterangepicker .drp-buttons .cancelBtn.active i,
.daterangepicker .drp-buttons .cancelBtn.active .svg-icon, .daterangepicker .drp-buttons .cancelBtn.show i,
.daterangepicker .drp-buttons .cancelBtn.show .svg-icon, .show > .daterangepicker .drp-buttons .cancelBtn i,
.show > .daterangepicker .drp-buttons .cancelBtn .svg-icon {
  color: var(--bs-light-inverse);
}
.btn-check:checked + .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after, .btn-check:active + .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:focus:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:hover:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn:active:not(.btn-active).dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn.active.dropdown-toggle:after, .daterangepicker .drp-buttons .cancelBtn.show.dropdown-toggle:after, .show > .daterangepicker .drp-buttons .cancelBtn.dropdown-toggle:after {
  color: var(--bs-light-inverse);
}
.daterangepicker .drp-selected {
  font-size: 0.9rem;
}
.daterangepicker .drp-calendar.left, .daterangepicker .drp-calendar.right {
  padding: 1rem 1rem;
}
.daterangepicker .drp-calendar.left {
  border-left: 0 !important;
}
.daterangepicker .drp-calendar th,
.daterangepicker .drp-calendar td {
  font-size: 1rem;
  font-weight: 400;
  width: 33px;
  height: 33px;
}
.daterangepicker .drp-calendar th.available:hover,
.daterangepicker .drp-calendar td.available:hover {
  border-radius: 0.46rem;
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
}
.daterangepicker .drp-calendar th {
  font-weight: 500;
  color: var(--bs-gray-800);
}
.daterangepicker .drp-calendar th.month {
  font-weight: 500;
  color: var(--bs-gray-800);
}
.daterangepicker .drp-calendar th.next span, .daterangepicker .drp-calendar th.prev span {
  border-width: 0 1px 1px 0;
  border-color: var(--bs-gray-600);
}
.daterangepicker .drp-calendar th.next.available:hover span, .daterangepicker .drp-calendar th.prev.available:hover span {
  border-color: var(--bs-component-hover-color);
}
.daterangepicker .drp-calendar th.next span {
  margin-right: 1px;
}
.daterangepicker .drp-calendar th.prev span {
  margin-left: 1px;
}
.daterangepicker .drp-calendar td {
  color: var(--bs-gray-700);
}
.daterangepicker .drp-calendar td.available.off {
  color: var(--bs-gray-500);
}
.daterangepicker .drp-calendar td.active {
  background-color: var(--bs-component-active-bg) !important;
  color: var(--bs-component-active-color) !important;
  border-radius: 0.46rem;
}
.daterangepicker .drp-calendar td.active.start-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.daterangepicker .drp-calendar td.active.end-date {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.daterangepicker .drp-calendar td.active.start-date.end-date {
  border-radius: 0.46rem;
}
.daterangepicker .drp-calendar td.today, .daterangepicker .drp-calendar td.today.active {
  background: var(--bs-gray-200) !important;
  color: var(--bs-gray-700) !important;
  border-radius: 0.46rem;
}
.daterangepicker .drp-calendar td.in-range.available:not(.active):not(.off):not(.today) {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
}
.daterangepicker .drp-calendar td:hover {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
}
.daterangepicker select.ampmselect,
.daterangepicker select.minuteselect,
.daterangepicker select.hourselect,
.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
  border-radius: 0.46rem;
  background-color: var(--bs-body-bg) !important;
  border-color: transparent;
  color: var(--bs-input-color);
  font-weight: 500;
  outline: 0 !important;
}
.daterangepicker select.ampmselect:focus,
.daterangepicker select.minuteselect:focus,
.daterangepicker select.hourselect:focus,
.daterangepicker select.monthselect:focus,
.daterangepicker select.yearselect:focus {
  background-color: var(--bs-gray-100);
}

@media (max-width: 767.98px) {
  .daterangepicker.show-calendar .ranges {
    float: none !important;
    height: auto !important;
  }
  .daterangepicker.show-calendar .ranges ul {
    width: 100%;
  }
  .daterangepicker.show-calendar .drp-calendar {
    float: none !important;
    max-width: unset !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.arrowRight:before,
.flatpickr-calendar.rightMost:after,
.flatpickr-calendar.arrowRight:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar.arrowCenter:before,
.flatpickr-calendar.arrowCenter:after {
  left: 50%;
  right: 50%;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  left: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
        /*rtl:begin:ignore*/
  /*
        */
  right: 0;
  /*
        /*rtl:end:ignore*/
  /*
        */
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.flatpickr-calendar {
  width: 280px !important;
  font-family: inherit;
  border: 0;
  border-radius: 0;
  box-shadow: var(--bs-dropdown-box-shadow);
  background-color: var(--bs-body-bg);
  border-radius: 0.46rem;
}
.flatpickr-calendar:before, .flatpickr-calendar:after {
  display: none;
}
.flatpickr-calendar.hasWeeks {
  width: 325px !important;
}

.flatpickr-months {
  padding: 0 1rem;
  padding-top: 0.5rem;
}

.flatpickr-innerContainer {
  padding: 0.5rem 1rem;
}

.flatpickr-days,
.dayContainer {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: var(--bs-gray-600);
  fill: var(--bs-gray-600);
  height: 46px;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.46rem;
  top: 1rem;
}
.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  fill: var(--bs-gray-500);
  height: 13px;
  width: 13px;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  background: var(--bs-gray-100);
}
.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--bs-gray-700);
}
.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*rtl:begin:ignore*/
  left: 1rem;
  /*rtl:end:ignore*/
}
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*rtl:begin:ignore*/
  right: 1rem;
  /*rtl:end:ignore*/
}

.flatpickr-current-month {
  font-weight: 500;
  color: inherit;
}
.flatpickr-current-month .numInputWrapper {
  border-radius: 0.46rem;
  width: 65px;
}
.flatpickr-current-month .numInputWrapper span.arrowUp {
  border-top-right-radius: 0.46rem;
}
.flatpickr-current-month .numInputWrapper span.arrowDown {
  border-bottom-right-radius: 0.46rem;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  border: 0 !important;
  background-color: var(--bs-body-bg);
  font-size: 1rem;
  color: var(--bs-gray-700);
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  margin-right: 0.5rem;
  outline: none !important;
  border-radius: 0.46rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: var(--bs-gray-100);
}
.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  font-size: 1rem;
  color: var(--bs-gray-700);
  font-weight: 500;
  background-color: transparent;
  outline: none;
  padding: 0;
}
.flatpickr-current-month span.cur-month {
  color: var(--bs-gray-700);
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-current-month span.cur-month:hover {
  background: var(--bs-gray-100);
}
.flatpickr-current-month input.cur-year {
  color: var(--bs-gray-700);
  font-size: 1.1rem !important;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  outline: 0 !important;
}

span.flatpickr-weekday {
  color: var(--bs-gray-800);
  font-size: 1rem;
  font-weight: 600;
}

.flatpickr-time {
  border-bottom-right-radius: 0.46rem;
  border-bottom-left-radius: 0.46rem;
}
.flatpickr-calendar.hasTime .flatpickr-time {
  height: height;
  line-height: height;
  max-height: height;
  border-top: 1px solid var(--bs-gray-100);
}
.flatpickr-time .numInputWrapper {
  height: height;
}
.flatpickr-time .flatpickr-am-pm {
  color: var(--bs-gray-700);
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-time input.flatpickr-hour, .flatpickr-time input.flatpickr-minute {
  color: var(--bs-gray-700);
  font-size: 1rem;
  font-weight: 500;
}
.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: transparent;
}

.numInputWrapper span {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom: 0 !important;
  border-right: 0 !important;
}
.numInputWrapper span:hover {
  background: transparent !important;
}
.numInputWrapper span:after {
  top: 50% !important;
  transform: translateY(-50%);
}
.numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--bs-gray-500) !important;
}
.numInputWrapper span.arrowUp:hover:after {
  border-bottom-color: var(--bs-gray-700) !important;
}
.numInputWrapper span.arrowDown:after {
  border-top-color: var(--bs-gray-500) !important;
}
.numInputWrapper span.arrowDown:hover:after {
  border-top-color: var(--bs-gray-700) !important;
}
.numInputWrapper:hover {
  background: transparent;
}

.flatpickr-day {
  font-size: 1rem;
  border-radius: 0.46rem;
  box-shadow: none !important;
  height: 36px;
  width: 100%;
  max-width: 100% !important;
  margin: 0;
  line-height: 36px;
  color: var(--bs-gray-600);
  margin-top: 0 !important;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
  border-color: transparent;
}
.flatpickr-day.today {
  background: var(--bs-gray-100);
  color: var(--bs-gray-600);
  border-color: transparent;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: transparent;
  background: var(--bs-gray-200);
  color: var(--bs-gray-700);
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
  border-color: transparent;
}
.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
  border-color: transparent;
}
.flatpickr-day.today {
  border-color: transparent;
}
.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: transparent;
  background: var(--bs-gray-100);
  color: var(--bs-gray-600);
}
.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: var(--bs-component-active-bg);
  color: var(--bs-component-active-color);
  border-color: transparent;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--bs-gray-500);
  background: transparent;
  border-color: transparent;
}
.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: var(--bs-gray-500);
}

.flatpickr-weekwrapper {
  margin-right: 5px;
}

.tagify {
  --tagify-dd-bg-color: var(--bs-body-bg);
  --tags-border-color: var(--bs-gray-300);
  --tags-hover-border-color: var(--bs-gray-300);
  --tags-focus-border-color: var(--bs-gray-400);
  --tag-bg: var(--bs-gray-200);
  --tag-hover: var(--bs-gray-200);
  --tag-text-color: var(--bs-gray-700);
  --tag-text-color--edit: var(--bs-gray-700);
  --tag-pad: 0 0.5rem;
  --tag-inset-shadow-size: 1rem;
  --tag-invalid-color: var(--bs-danger);
  --tag-invalid-bg: var(--bs-danger-light);
  --tag-remove-bg: var(--bs-gray-200);
  --tag-remove-btn-color: transparent;
  --tag-remove-btn-bg: transparent;
  --tag-remove-btn-bg--hover: transparent;
  --input-color: var(--bs-gray-700);
  --placeholder-color: var(--bs-gray-400);
  --placeholder-color-focus: var(--bs-gray-500);
  --loader-size: .8rem;
  --tagify-dd-item--hidden-duration: 0.3s;
}
.tagify .tagify__tag {
  background-color: var(--tag-bg);
  margin: 0;
  line-height: 1;
}
.tagify .tagify__tag div {
  border-radius: inherit;
}
.tagify .tagify__tag .tagify__tag-text {
  overflow: visible;
}
.tagify .tagify__tag .tagify__tag__removeBtn {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.5rem 0 0;
  border-radius: 0;
  content: " ";
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-gray-500);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-500%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-500%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.tagify .tagify__tag .tagify__tag__removeBtn:after {
  display: none;
}
.tagify .tagify__tag .tagify__tag__removeBtn:hover {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-primary);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.tagify .tagify__tag.tagify--notAllowed div .tagify__tag-text {
  color: var(--bs-danger);
  opacity: 0.5;
}
.tagify .tagify__tag.tagify--notAllowed .tagify__tag__removeBtn {
  opacity: 0.5;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-danger);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-danger%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-danger%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.tagify .tagify__tag.tagify--notAllowed .tagify__tag__removeBtn:hover {
  background: transparent;
  opacity: 0.75;
}
.tagify .tagify__input {
  margin: 0;
}
.tagify .tagify__input:before {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}
.tagify.form-control {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.tagify.form-control-sm {
  border-radius: 0.35rem;
  min-height: calc(1.5em + 1.1rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.tagify.form-control-sm .tagify__tag {
  padding: 0.3rem 0.3rem;
  border-radius: 0.35rem;
}
.tagify.form-control-sm .tagify__tag .tagify__tag-text {
  font-size: 0.95rem;
}
.tagify:not(.form-control-sm):not(.form-control-lg) {
  border-radius: 0.46rem;
  min-height: calc(1.5em + 1.55rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.tagify:not(.form-control-sm):not(.form-control-lg) .tagify__tag {
  padding: 0.4rem 0.4rem;
  border-radius: 0.46rem;
}
.tagify:not(.form-control-sm):not(.form-control-lg) .tagify__tag .tagify__tag-text {
  font-size: 1.1rem;
}
.tagify.form-control-lg {
  border-radius: 0.92rem;
  min-height: calc(1.5em + 1.65rem + 2px);
  padding-top: 0.325rem;
  padding-bottom: 0.325rem;
}
.tagify.form-control-lg .tagify__tag {
  padding: 0.5rem 0.5rem;
  border-radius: 0.92rem;
}
.tagify.form-control-lg .tagify__tag .tagify__tag-text {
  font-size: 1.15rem;
}

.tagify__dropdown {
  box-shadow: var(--bs-dropdown-box-shadow);
  border: 0 !important;
  outline: none !important;
  padding: 0.75rem 0;
  z-index: 1000;
  background-color: var(--bs-body-bg);
  border-radius: 0.46rem;
}
.tagify__dropdown ._wrapper {
  max-height: none;
  border-radius: 0.46rem;
}
.modal-open .tagify__dropdown {
  z-index: 1056;
}
.tagify__dropdown .tagify__dropdown__wrapper {
  background-color: var(--bs-body-bg);
  border: 0 !important;
  outline: none !important;
  box-shadow: none;
}
.tagify__dropdown .tagify__dropdown__item {
  color: var(--bs-gray-700);
  border-radius: 0;
  padding: 0.75rem 1.5rem;
  margin: 0;
  box-shadow: none;
  font-weight: 500;
}
.tagify__dropdown .tagify__dropdown__item:hover, .tagify__dropdown .tagify__dropdown__item.tagify__dropdown__item--active {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
}
.tagify__dropdown.tagify__inline__suggestions {
  padding: 0.775rem 1rem;
}
.tagify__dropdown.tagify__inline__suggestions .tagify__dropdown__item {
  display: inline-block;
  font-size: 0.95rem;
  padding: 0.35rem 0.5rem;
  margin: 0.25rem 0.5rem 0.25rem 0;
  background-color: var(--bs-gray-200);
  color: var(--bs-gray-700);
  border-radius: 0.46rem;
}
.tagify__dropdown.tagify__inline__suggestions .tagify__dropdown__item:hover, .tagify__dropdown.tagify__inline__suggestions .tagify__dropdown__item.tagify__dropdown__item--active {
  background-color: var(--bs-component-hover-bg);
  color: var(--bs-component-hover-color);
}

.bootstrap-maxlength {
  z-index: 1040 !important;
}
.modal-open .bootstrap-maxlength {
  z-index: 1060 !important;
}
.bootstrap-maxlength.badge {
  display: inline-flex !important;
}

.ck-target {
  display: none;
}

.ck-toolbar {
  border-radius: 0.46rem !important;
}

.ck-content {
  min-height: 200px;
  border-radius: 0.46rem !important;
}
.ck-content.ck-focused {
  border-color: var(--bs-primary) !important;
  box-shadow: none !important;
}

.ck-editor .ck-toolbar {
  border-top-left-radius: 0.46rem !important;
  border-top-right-radius: 0.46rem !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.ck-editor .ck-content {
  border-bottom-right-radius: 0.46rem !important;
  border-bottom-left-radius: 0.46rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ck-body .ck-balloon-panel .ck-content {
  min-height: 200px;
  border-color: transparent !important;
}
.ck-body .ck-balloon-panel .ck-content.ck-focused {
  border-color: var(--bs-primary) !important;
}
.ck-body .ck-balloon-panel.ck-toolbar-container,
.ck-body .ck-balloon-panel .ck-toolbar {
  border-radius: 0.46rem !important;
}

table.dataTable {
  width: 100% !important;
  margin: 0 !important;
}
table.dataTable th {
  border-bottom-color: var(--bs-table-border-color);
}

table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
  text-align: left;
}

table.dataTable > thead > tr > td:not(.sorting_disabled), table.dataTable > thead > tr > th:not(.sorting_disabled) {
  padding-right: 0;
}

table.dataTable > thead .dt-column-order {
  display: none;
}
table.dataTable > thead .dt-orderable-asc,
table.dataTable > thead .dt-orderable-desc {
  outline: 0 !important;
}
table.dataTable > thead .dt-orderable-asc:after, table.dataTable > thead .dt-orderable-asc:before,
table.dataTable > thead .dt-orderable-desc:after,
table.dataTable > thead .dt-orderable-desc:before {
  display: none !important;
}
table.dataTable > thead .dt-ordering-asc,
table.dataTable > thead .dt-ordering-desc {
  vertical-align: middle;
}
table.dataTable > thead .dt-ordering-asc:before, table.dataTable > thead .dt-ordering-asc:after,
table.dataTable > thead .dt-ordering-desc:before,
table.dataTable > thead .dt-ordering-desc:after {
  position: relative !important;
  opacity: 1 !important;
  display: inline-block !important;
  width: 0.65rem;
  height: 0.65rem;
  content: " " !important;
  bottom: auto;
  right: auto !important;
  left: auto;
  margin-left: 0.5rem;
}
table.dataTable > thead .dt-ordering-asc:before,
table.dataTable > thead .dt-ordering-desc:before {
  display: none !important;
}

table.dataTable > thead .dt-ordering-asc:after {
  opacity: 1;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-text-muted);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M3.23571 2.72011L4.97917 4.46358C5.15176 4.63618 5.43158 4.63617 5.60417 4.46358C5.77676 4.29099 5.77676 4.01118 5.60417 3.83861L3.29463 1.52904C3.13192 1.36629 2.86809 1.36629 2.70538 1.52904L0.395812 3.83861C0.22325 4.01117 0.22325 4.29099 0.395812 4.46358C0.568437 4.63617 0.84825 4.63617 1.02081 4.46358L2.76429 2.72011C2.89446 2.58994 3.10554 2.58994 3.23571 2.72011Z'/%3e%3c/svg%3e");
}

table.dataTable > thead .dt-ordering-desc:after {
  opacity: 1;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-text-muted);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 6' fill='var%28--bs-text-muted%29'%3e%3cpath d='M2.76429 3.27989L1.02083 1.53642C0.848244 1.36382 0.568419 1.36383 0.395831 1.53642C0.223244 1.70901 0.223244 1.98882 0.395831 2.16139L2.70537 4.47096C2.86808 4.63371 3.13191 4.63371 3.29462 4.47096L5.60419 2.16139C5.77675 1.98883 5.77675 1.70901 5.60419 1.53642C5.43156 1.36383 5.15175 1.36383 4.97919 1.53642L3.23571 3.27989C3.10554 3.41006 2.89446 3.41006 2.76429 3.27989Z'/%3e%3c/svg%3e");
}

.dt-container .table-responsive {
  position: relative;
}
.dt-container .dt-processing {
  border-radius: 0.46rem;
  box-shadow: var(--bs-dropdown-box-shadow);
  background-color: var(--bs-body-bg);
  color: var(--bs-gray-700);
  font-weight: 500;
  margin: 0 !important;
  width: auto;
  padding: 1rem 2rem !important;
  transform: translateX(-50%) translateY(-50%);
}
.dt-container .dt-processing > div {
  display: none;
}

.dataTable.collapsed .dtr-control:before {
  border: 0 !important;
  height: 1.35rem;
  width: 1.35rem;
  line-height: 1.5;
  text-indent: -999px !important;
  margin-bottom: -0.3375rem;
  margin-right: 0.675rem;
  display: inline-block;
  position: relative;
  font-size: 1.05rem;
  border: 0;
  box-shadow: none;
  mask-size: 85%;
  -webkit-mask-size: 85%;
  content: " ";
}
:root .dataTable.collapsed .dtr-control:before, [data-bs-theme=light] .dataTable.collapsed .dtr-control:before {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #78829D;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%2378829D'/%3e%3crect x='10.8891' y='17.8033' width='12' height='2' rx='1' transform='rotate%28-90 10.8891 17.8033%29' fill='%2378829D'/%3e%3crect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='%2378829D'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%2378829D'/%3e%3crect x='10.8891' y='17.8033' width='12' height='2' rx='1' transform='rotate%28-90 10.8891 17.8033%29' fill='%2378829D'/%3e%3crect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='%2378829D'/%3e%3c/svg%3e");
}
[data-bs-theme=dark] .dataTable.collapsed .dtr-control:before {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #d6d7db;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%23d6d7db'/%3e%3crect x='10.8891' y='17.8033' width='12' height='2' rx='1' transform='rotate%28-90 10.8891 17.8033%29' fill='%23d6d7db'/%3e%3crect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='%23d6d7db'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%23d6d7db'/%3e%3crect x='10.8891' y='17.8033' width='12' height='2' rx='1' transform='rotate%28-90 10.8891 17.8033%29' fill='%23d6d7db'/%3e%3crect x='6.01041' y='10.9247' width='12' height='2' rx='1' fill='%23d6d7db'/%3e%3c/svg%3e");
}

.dt-hasChild.dtr-expanded .dtr-control:before {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: #2b2f77;
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%232b2f77'/%3e%3crect x='6.0104' y='10.9247' width='12' height='2' rx='1' fill='%232b2f77'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3crect opacity='0.3' x='2' y='2' width='20' height='20' rx='5' fill='%232b2f77'/%3e%3crect x='6.0104' y='10.9247' width='12' height='2' rx='1' fill='%232b2f77'/%3e%3c/svg%3e");
}

thead .dtr-control:before {
  display: none;
}

table.dataTable > tbody > tr.child span.dtr-title {
  display: inline-block;
  min-width: 100px;
  font-weight: 500;
}

table.dataTable > tbody > tr.child span.dtr-data {
  font-weight: 400;
}

table.table.dataTable.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  box-shadow: inset 0 0 0 9999px var(--bs-table-striped-bg);
}

table.table.dataTable > tbody > tr.selected > * {
  box-shadow: inset 0 0 0 9999px var(--bs-primary);
  color: var(--bs-primary-inverse);
}
table.table.dataTable > tbody > tr.selected > * a:not([class=btn]) {
  color: var(--bs-primary-light);
  font-weight: 500;
  text-decoration: underline;
  text-decoration-style: dotted;
}
table.table.dataTable > tbody > tr.selected > * a:not([class=btn]):hover {
  color: var(--bs-primary-inverse);
}

.dt-search > div,
.dt-toolbar > div {
  display: flex;
  align-items: center;
}

.dt-toolbar .dt-info {
  margin-left: 0.75rem;
  padding-top: 0 !important;
  font-size: 1rem;
  font-weight: 500;
  color: var(--bs-text-gray-700);
}

.dt-scroll-body {
  border-left: 0 !important;
  border-bottom: 0 !important;
}
.dt-scroll-body .dt-orderable-none.dt-ordering-asc:after,
.dt-scroll-body .dt-orderable-none.dt-ordering-desc:after {
  display: none !important;
}

.dt-scroll-foot {
  border-top: 1px solid var(--bs-border-color);
}

.dt-scroll > .dt-scroll-body > .table > thead {
  line-height: 0;
}
.dt-scroll > .dt-scroll-body > .table > thead .dt-orderable-asc:after, .dt-scroll > .dt-scroll-body > .table > thead .dt-orderable-asc:before,
.dt-scroll > .dt-scroll-body > .table > thead .dt-orderable-desc:after,
.dt-scroll > .dt-scroll-body > .table > thead .dt-orderable-desc:before {
  display: none !important;
}

div.dtfc-right-top-blocker,
div.dtfc-left-top-blocker {
  background-color: var(--bs-body-bg);
}

table.dataTable thead tr > .dtfc-fixed-left,
table.dataTable thead tr > .dtfc-fixed-right {
  background-color: var(--bs-body-bg);
}
table.dataTable thead tr > .dtfc-fixed-left:after,
table.dataTable thead tr > .dtfc-fixed-right:after {
  box-shadow: none !important;
}

table.dataTable tbody tr > .dtfc-fixed-left,
table.dataTable tbody tr > .dtfc-fixed-right {
  background-color: var(--bs-body-bg);
}
table.dataTable tbody tr > .dtfc-fixed-left:after,
table.dataTable tbody tr > .dtfc-fixed-right:after {
  box-shadow: none !important;
}

.dtfh-floatingparent {
  box-shadow: var(--bs-box-shadow-sm);
}
.dtfh-floatingparent .table {
  background-color: var(--bs-body-bg) !important;
}
.dtfh-floatingparent,
.dtfh-floatingparent .table,
.dtfh-floatingparent .table th {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.dtr-details {
  display: table !important;
}
.dtr-details li {
  display: table-row !important;
}
.dtr-details li .dtr-title {
  padding-right: 0.75rem;
  color: var(--bs-gray-900);
}
.dtr-details li .dtr-data {
  color: var(--bs-gray-700);
}
.dtr-details li .dtr-title,
.dtr-details li .dtr-data {
  font-size: 1rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  display: table-cell !important;
  border-bottom: 1px solid var(--bs-border-color);
}

.dropzone {
  min-height: auto;
  padding: 1.5rem 1.75rem;
  text-align: center;
  cursor: pointer;
  border: 1px dashed var(--bs-primary);
  background-color: var(--bs-primary-light);
  border-radius: 0.46rem !important;
}
.dropzone .dz-message {
  margin: 0;
  display: flex;
  text-align: left;
}
.dropzone .dz-preview {
  border-radius: 0.46rem !important;
  margin: 0.75rem;
}
.dropzone .dz-preview .dz-image {
  border-radius: 0.46rem !important;
  z-index: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  background: var(--bs-gray-200);
}
.dropzone .dz-success-mark,
.dropzone .dz-error-mark {
  margin-left: -20px !important;
  margin-top: -20px !important;
}
.dropzone .dz-success-mark svg,
.dropzone .dz-error-mark svg {
  height: 40px !important;
  width: 40px !important;
}
.dropzone .dz-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.65rem;
  width: 1.65rem;
  font-size: 1rem;
  text-indent: -9999px;
  white-space: nowrap;
  position: absolute;
  z-index: 2;
  background-color: var(--bs-body-bg) !important;
  box-shadow: var(--bs-box-shadow);
  border-radius: 100%;
  top: -0.825rem;
  right: -0.825rem;
}
.dropzone .dz-remove:after {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: block;
  content: "";
  mask-size: 40%;
  -webkit-mask-size: 40%;
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-gray-600);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-600%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-gray-600%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.dropzone .dz-remove:hover:after {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-primary);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-primary%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.dropzone .dz-error-message {
  color: var(--bs-danger-inverse);
  background: var(--bs-danger);
}

.dropzone.dropzone-queue {
  border: 0;
  padding: 0;
  background-color: transparent;
  text-align: left;
}
.dropzone.dropzone-queue .dz-message {
  display: none;
}
.dropzone.dropzone-queue .dropzone-panel .dropzone-upload,
.dropzone.dropzone-queue .dropzone-panel .dropzone-remove-all {
  display: none;
}
.dropzone.dropzone-queue .dropzone-item {
  display: flex;
  align-items: center;
  margin-top: 0.75rem;
  border-radius: 0.46rem;
  padding: 0.5rem 1rem;
  background-color: var(--bs-gray-100);
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file {
  flex-grow: 1;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--bs-gray-600);
  text-overflow: ellipsis;
  margin-right: 0.5rem;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-filename:hover {
  color: var(--bs-primary);
}
.dropzone.dropzone-queue .dropzone-item .dropzone-file .dropzone-error {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--bs-danger);
  text-overflow: ellipsis;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-progress {
  width: 15%;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
  height: 5px;
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .dropzone.dropzone-queue .dropzone-item .dropzone-progress .progress {
    transition: none;
  }
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar {
  margin-left: 1rem;
  display: flex;
  flex-wrap: nowrap;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete {
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: color 0.2s ease;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete > i {
  transition: color 0.2s ease;
  font-size: 0.8rem;
  color: var(--bs-gray-600);
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover {
  transition: color 0.2s ease;
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start:hover > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-cancel:hover > i,
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-delete:hover > i {
  color: var(--bs-primary);
}
.dropzone.dropzone-queue .dropzone-item .dropzone-toolbar .dropzone-start {
  transition: color 0.2s ease;
}

.gmaps {
  /* important!  bootstrap sets max-width on img to 100% which conflicts with google map canvas*/
}
.gmaps img {
  max-width: none;
}
.gmaps.gmaps-static > div {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: block;
}

.noUi-target {
  border: 0;
  background: var(--bs-gray-100);
  box-shadow: none;
}
.noUi-target.noUi-horizontal {
  height: 15px;
}
.noUi-target.noUi-horizontal .noUi-handle {
  width: 24px;
  height: 24px;
  top: -4.5px;
  border-radius: 50%;
  outline: none;
}
.noUi-target.noUi-horizontal .noUi-handle::before {
  display: none;
}
.noUi-target.noUi-horizontal .noUi-handle::after {
  display: none;
}
.noUi-target.noUi-vertical {
  height: 150px;
  width: 15px;
}
.noUi-target.noUi-vertical .noUi-handle {
  width: 24px;
  height: 24px;
  right: -4.5px;
  border-radius: 50%;
  outline: none;
}
.noUi-target.noUi-vertical .noUi-handle::before {
  display: none;
}
.noUi-target.noUi-vertical .noUi-handle::after {
  display: none;
}
.noUi-target .noUi-connect {
  background: var(--bs-component-active-bg);
}
.noUi-target .noUi-handle {
  background-color: #ffffff;
  border: 1px solid var(--bs-gray-200);
  box-shadow: var(--bs-box-shadow-sm);
}
.noUi-target.noUi-sm {
  height: 6px;
}
.noUi-target.noUi-sm .noUi-handle {
  width: 20px;
  height: 20px;
  top: -7px;
}
.noUi-target.noUi-lg {
  height: 18px;
}
.noUi-target.noUi-lg .noUi-handle {
  width: 30px;
  height: 30px;
  top: -6px;
}

.noUi-target.noUi-target-light .noUi-connects {
  background-color: var(--bs-light-light);
}
.noUi-target.noUi-target-light .noUi-connects .noUi-connect {
  background-color: var(--bs-light);
}
.noUi-target.noUi-target-light .noUi-handle {
  border: 1px solid var(--bs-light);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-light), 0.7);
  background-color: var(--bs-light);
}

.noUi-target.noUi-target-primary .noUi-connects {
  background-color: var(--bs-primary-light);
}
.noUi-target.noUi-target-primary .noUi-connects .noUi-connect {
  background-color: var(--bs-primary);
}
.noUi-target.noUi-target-primary .noUi-handle {
  border: 1px solid var(--bs-primary);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-primary), 0.7);
  background-color: var(--bs-primary);
}

.noUi-target.noUi-target-secondary .noUi-connects {
  background-color: var(--bs-secondary-light);
}
.noUi-target.noUi-target-secondary .noUi-connects .noUi-connect {
  background-color: var(--bs-secondary);
}
.noUi-target.noUi-target-secondary .noUi-handle {
  border: 1px solid var(--bs-secondary);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-secondary), 0.7);
  background-color: var(--bs-secondary);
}

.noUi-target.noUi-target-success .noUi-connects {
  background-color: var(--bs-success-light);
}
.noUi-target.noUi-target-success .noUi-connects .noUi-connect {
  background-color: var(--bs-success);
}
.noUi-target.noUi-target-success .noUi-handle {
  border: 1px solid var(--bs-success);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-success), 0.7);
  background-color: var(--bs-success);
}

.noUi-target.noUi-target-info .noUi-connects {
  background-color: var(--bs-info-light);
}
.noUi-target.noUi-target-info .noUi-connects .noUi-connect {
  background-color: var(--bs-info);
}
.noUi-target.noUi-target-info .noUi-handle {
  border: 1px solid var(--bs-info);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-info), 0.7);
  background-color: var(--bs-info);
}

.noUi-target.noUi-target-warning .noUi-connects {
  background-color: var(--bs-warning-light);
}
.noUi-target.noUi-target-warning .noUi-connects .noUi-connect {
  background-color: var(--bs-warning);
}
.noUi-target.noUi-target-warning .noUi-handle {
  border: 1px solid var(--bs-warning);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-warning), 0.7);
  background-color: var(--bs-warning);
}

.noUi-target.noUi-target-danger .noUi-connects {
  background-color: var(--bs-danger-light);
}
.noUi-target.noUi-target-danger .noUi-connects .noUi-connect {
  background-color: var(--bs-danger);
}
.noUi-target.noUi-target-danger .noUi-handle {
  border: 1px solid var(--bs-danger);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-danger), 0.7);
  background-color: var(--bs-danger);
}

.noUi-target.noUi-target-dark .noUi-connects {
  background-color: var(--bs-dark-light);
}
.noUi-target.noUi-target-dark .noUi-connects .noUi-connect {
  background-color: var(--bs-dark);
}
.noUi-target.noUi-target-dark .noUi-handle {
  border: 1px solid var(--bs-dark);
  box-shadow: 0 3px 6px -3px rgba(var(--bs-dark), 0.7);
  background-color: var(--bs-dark);
}

.noUi-tooltip {
  box-shadow: var(--bs-tooltip-box-shadow);
  background: var(--bs-tooltip-bg);
  color: var(--bs-tooltip-color);
  font-size: 1rem;
  border: 0;
  padding: 0.5rem 0.75rem;
  border-radius: 0.46rem;
}

.ql-toolbar {
  font-family: Inter, Helvetica, "sans-serif";
}
.ql-toolbar.ql-snow {
  border: 1px solid var(--bs-border-color);
  border-top-left-radius: 0.46rem;
  border-top-right-radius: 0.46rem;
}
.ql-toolbar.ql-snow .ql-picker .ql-fill,
.ql-toolbar.ql-snow .ql-picker .ql-stroke,
.ql-toolbar.ql-snow button .ql-fill,
.ql-toolbar.ql-snow button .ql-stroke {
  stroke: var(--bs-gray-500);
}
.ql-toolbar.ql-snow .ql-picker .ql-fill,
.ql-toolbar.ql-snow button .ql-fill {
  fill: var(--bs-gray-500);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill,
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-stroke, .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill,
.ql-toolbar.ql-snow .ql-picker.ql-active .ql-stroke, .ql-toolbar.ql-snow .ql-picker:focus .ql-fill,
.ql-toolbar.ql-snow .ql-picker:focus .ql-stroke, .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
.ql-toolbar.ql-snow .ql-picker:hover .ql-stroke,
.ql-toolbar.ql-snow button.ql-expanded .ql-fill,
.ql-toolbar.ql-snow button.ql-expanded .ql-stroke,
.ql-toolbar.ql-snow button.ql-active .ql-fill,
.ql-toolbar.ql-snow button.ql-active .ql-stroke,
.ql-toolbar.ql-snow button:focus .ql-fill,
.ql-toolbar.ql-snow button:focus .ql-stroke,
.ql-toolbar.ql-snow button:hover .ql-fill,
.ql-toolbar.ql-snow button:hover .ql-stroke {
  stroke: var(--bs-primary);
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-fill, .ql-toolbar.ql-snow .ql-picker.ql-active .ql-fill, .ql-toolbar.ql-snow .ql-picker:focus .ql-fill, .ql-toolbar.ql-snow .ql-picker:hover .ql-fill,
.ql-toolbar.ql-snow button.ql-expanded .ql-fill,
.ql-toolbar.ql-snow button.ql-active .ql-fill,
.ql-toolbar.ql-snow button:focus .ql-fill,
.ql-toolbar.ql-snow button:hover .ql-fill {
  fill: var(--bs-primary);
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: 0;
}
[direction=rtl] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg, [dir=rtl] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  left: 0;
  right: auto;
}

.ql-editor {
  color: var(--bs-input-color);
  text-align: initial;
}
.ql-editor.ql-blank:before {
  left: auto !important;
  right: auto !important;
  color: var(--bs-text-muted) !important;
  font-style: normal !important;
}

.ql-container.ql-snow {
  background-color: var(--bs-input-bg);
  border: 1px solid var(--bs-gray-200);
  border-top: 0;
  border-bottom-right-radius: 0.46rem;
  border-bottom-left-radius: 0.46rem;
}

.ql-snow .ql-picker .ql-picker-label {
  color: var(--bs-gray-500);
}
.ql-snow .ql-picker .ql-picker-label.ql-active, .ql-snow .ql-picker .ql-picker-label:hover {
  color: var(--bs-primary);
}
.ql-snow .ql-picker.ql-expanded {
  outline: none !important;
  border-color: transparent !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  border-color: transparent !important;
  color: var(--bs-primary);
  outline: none !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-label.ql-active, .ql-snow .ql-picker.ql-expanded .ql-picker-label:hover {
  color: var(--bs-primary);
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  border: 0;
  padding: 0.5rem 1rem;
  box-shadow: var(--bs-dropdown-box-shadow);
  background-color: var(--bs-body-bg);
  border-radius: 0.46rem;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: var(--bs-gray-600);
  outline: none;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-selected, .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item.ql-active, .ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: var(--bs-primary);
}
.ql-snow .ql-tooltip {
  border: 0;
  padding: 0.5rem 1rem;
  box-shadow: var(--bs-dropdown-box-shadow);
  border-radius: 0.46rem;
}
.ql-snow .ql-tooltip input[type=text] {
  border: 0;
  background-color: transparent;
  outline: none !important;
  box-shadow: none;
  border-radius: 0;
  border: 1px solid var(--bs-border-color);
  color: var(--bs-gray-700);
  outline: none !important;
  border-radius: 0.46rem;
}
.ql-snow .ql-tooltip input[type=text]:active, .ql-snow .ql-tooltip input[type=text]:focus {
  border-color: var(--bs-input-focus-border-color) !important;
}
.ql-snow .ql-tooltip .ql-preview {
  color: var(--bs-gray-600);
}
.ql-snow .ql-tooltip .ql-action {
  transition: color 0.3s ease;
  color: var(--bs-gray-600);
}
.ql-snow .ql-tooltip .ql-action:hover {
  transition: color 0.3s ease;
  color: var(--bs-primary);
}
.modal .ql-snow .ql-tooltip.ql-editing {
  left: 20px !important;
}
.ql-snow .ql-editor pre.ql-syntax {
  background-color: var(--bs-gray-900);
  color: var(--bs-text-muted);
  overflow: visible;
  border-radius: 0.46rem;
}

.ql-quil.ql-quil-plain .ql-toolbar {
  padding: 0;
  margin: 0;
  border: 0;
}
.ql-quil.ql-quil-plain .ql-toolbar:after {
  display: none;
}
.ql-quil.ql-quil-plain .ql-toolbar .ql-picker-label {
  padding-left: 0;
}
.ql-quil.ql-quil-plain .ql-container {
  border: 0;
}
.ql-quil.ql-quil-plain .ql-editor {
  border: 0;
  padding: 0;
}

.recaptcha {
  padding: 15px;
  border: 1px solid var(--bs-gray-200);
  border-radius: 0.46rem;
}
.recaptcha .recaptcha-img {
  margin-bottom: 10px;
}
.recaptcha .recaptcha_only_if_incorrect_sol {
  color: var(--bs-danger);
}
.recaptcha .input-group .btn i {
  padding-right: 0;
}
.recaptcha .input-group .form-control {
  border-top-left-radius: 0.46rem !important;
  border-bottom-left-radius: 0.46rem !important;
}

html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open) {
  overflow-y: initial !important;
}
html.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.sweetalert2-nopadding),
body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown):not(.modal-open):not(.sweetalert2-nopadding) {
  padding-right: 0 !important;
}

.swal2-popup {
  background-color: var(--bs-body-bg);
  padding: 2rem;
  border-radius: 0.46rem;
}
.swal2-popup .swal2-title {
  font-weight: 500;
  font-size: 1.3rem;
  color: var(--bs-dark);
}
.swal2-popup .swal2-html-container,
.swal2-popup .swal2-content {
  font-weight: normal;
  font-size: 1.1rem;
  margin-top: 1.5rem;
  color: var(--bs-gray-800);
}
.swal2-popup .btn {
  margin: 15px 5px 0;
}
.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}
.swal2-popup .swal2-actions {
  margin: 1.5rem auto 1rem auto;
}

.swal2-container {
  overflow-y: hidden !important;
}
.swal2-container.swal2-shown {
  background-color: rgba(0, 0, 0, 0.2);
}
.swal2-container .swal2-html-container {
  max-height: 200px;
  overflow: auto;
}

body.swal2-height-auto {
  height: 100% !important;
}

.swal2-icon.swal2-warning {
  border-color: var(--bs-warning);
  color: var(--bs-warning);
}
.swal2-icon.swal2-error {
  border-color: var(--bs-danger);
  color: var(--bs-danger);
}
.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: rgba(var(--bs-danger-rgb), 0.75);
}
.swal2-icon.swal2-success {
  border-color: var(--bs-success);
  color: var(--bs-success);
}
.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: var(--bs-success);
}
.swal2-icon.swal2-success .swal2-success-ring {
  border-color: rgba(var(--bs-success-rgb), 0.3);
}
.swal2-icon.swal2-info {
  border-color: var(--bs-info);
  color: var(--bs-info);
}
.swal2-icon.swal2-question {
  border-color: var(--bs-primary);
  color: var(--bs-primary);
}

.tox-target {
  display: none;
}

.tox-tinymce {
  border-radius: 0.46rem !important;
}

.toastr {
  background-position: calc(100% - 1.5rem) center !important;
  /*rtl:ignore*/
  background-position: 1.5rem center !important;
  box-shadow: var(--bs-dropdown-box-shadow) !important;
  border-radius: 0.46rem !important;
  border: 0 !important;
  background-color: var(--bs-gray-100);
  color: var(--bs-gray-700);
  padding: 1.25rem 1.25rem 1.25rem 4.5rem !important;
}
.toastr .toastr-close-button {
  outline: none !important;
  font-size: 0;
  width: 0.85rem;
  height: 0.85rem;
}
.toastr .toastr-title {
  font-size: 1.15rem;
  font-weight: 500;
}
.toastr .toastr-title + .toastr-message {
  margin-top: 0.25rem;
}
.toastr .toastr-message {
  font-size: 1rem;
  font-weight: 400;
}
.toastr.toastr-success {
  background-color: var(--bs-success);
  color: var(--bs-success-inverse);
}
.toastr.toastr-success .toastr-close-button {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-success-inverse);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-success-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-success-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-info {
  background-color: var(--bs-info);
  color: var(--bs-info-inverse);
}
.toastr.toastr-info .toastr-close-button {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-info-inverse);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-info-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-info-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-warning {
  background-color: var(--bs-warning);
  color: var(--bs-warning-inverse);
}
.toastr.toastr-warning .toastr-close-button {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-warning-inverse);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-warning-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-warning-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}
.toastr.toastr-error {
  background-color: var(--bs-danger);
  color: var(--bs-danger-inverse);
}
.toastr.toastr-error .toastr-close-button {
  mask-repeat: no-repeat;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center;
  background-color: var(--bs-danger-inverse);
  -webkit-mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-danger-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
  mask-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='var%28--bs-danger-inverse%29'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.toastr-top-center {
  top: 12px;
}

.toastr-bottom-center {
  bottom: 12px;
}

.draggable {
  transition: opacity 0.3s ease;
  outline: none !important;
}
.draggable.draggable-mirror {
  opacity: 0.8;
  transition: opacity 0.3s ease;
  border: 1px dashed var(--bs-gray-300) !important;
  border-radius: 0.46rem;
}
.draggable.draggable--original {
  opacity: 0 !important;
}
.draggable.draggable-source--is-dragging.draggable--over {
  opacity: 0 !important;
}
.draggable .draggable-handle {
  cursor: move;
}

.apexcharts-text,
.apexcharts-title-text,
.apexcharts-legend-text {
  font-family: Inter, Helvetica, "sans-serif" !important;
}

.apexcharts-title-text {
  font-weight: 400;
}

.apexcharts-pie-label {
  font-weight: 400;
  font-size: 0.95rem;
}

.apexcharts-toolbar {
  text-align: left !important;
}

.apexcharts-menu {
  background: var(--bs-body-bg) !important;
  border: 0 !important;
  padding: 0.5rem 0 !important;
  box-shadow: var(--bs-dropdown-box-shadow);
  border-radius: 0.46rem !important;
  overflow: hidden;
  min-width: 10rem !important;
}
.apexcharts-menu .apexcharts-menu-item {
  padding: 0.65rem 0.85rem;
  transition: all 0.2s ease-in-out;
}
.apexcharts-menu .apexcharts-menu-item:hover {
  background-color: var(--bs-component-hover-bg) !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border-radius: 0.46rem;
  box-shadow: var(--bs-dropdown-box-shadow);
  border: 0 !important;
  background: var(--bs-body-bg) !important;
  color: var(--bs-gray-800);
}
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--bs-body-bg) !important;
  font-weight: 500;
  color: var(--bs-gray-800);
  border-bottom: 1px solid var(--bs-gray-100) !important;
}
.apexcharts-tooltip .apexcharts-tooltip-title {
  padding: 0.5rem 1rem;
}
.apexcharts-xaxistooltip.apexcharts-theme-light {
  border-radius: 0.46rem !important;
  box-shadow: var(--bs-dropdown-box-shadow) !important;
  border: 0 !important;
  background: var(--bs-dropdown-box-shadow) !important;
  color: var(--bs-gray-800);
}
.apexcharts-xaxistooltip.apexcharts-theme-light:before {
  border-bottom: 0 !important;
}
.apexcharts-xaxistooltip.apexcharts-theme-light:after {
  border-bottom-color: var(--bs-dropdown-box-shadow) !important;
}

.card-rounded-bottom .apexcharts-canvas svg {
  border-bottom-left-radius: 0.92rem;
  border-bottom-right-radius: 0.92rem;
}

.rounded .apexcharts-canvas svg {
  border-radius: 0.46rem !important;
}

.rounded-sm .apexcharts-canvas svg {
  border-radius: 0.35rem !important;
}

.rounded-lg .apexcharts-canvas svg {
  border-radius: 0.92rem !important;
}

.rounded-xl .apexcharts-canvas svg {
  border-radius: 1.25rem !important;
}

.leaflet-container .leaflet-pane,
.leaflet-container .leaflet-top,
.leaflet-container .leaflet-bottom,
.leaflet-container .leaflet-control {
  z-index: 1 !important;
}
.leaflet-container .leaflet-popup-content-wrapper {
  border-radius: 0.46rem !important;
  text-align: center;
  box-shadow: var(--bs-box-shadow) !important;
}
.leaflet-container .leaflet-popup-content-wrapper .leaflet-popup-content {
  font-family: Inter, Helvetica, "sans-serif";
  font-size: 1rem;
}

.tns {
  position: relative;
  overflow: hidden;
}
.tns [data-tns=true] {
  display: none;
}
.tns .tns-item {
  opacity: 0;
  transition: all 0.3s ease;
}
.tns .tns-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tns .tns-controls button {
  outline: none;
  border: 0;
  margin: 0 0.25rem;
  border-radius: 0.46rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--bs-primary);
  color: var(--bs-primary-inverse);
}
.tns .tns-controls button:hover {
  background-color: var(--bs-primary-active);
}
.tns .tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.tns .tns-nav button {
  display: block;
  outline: none;
  width: 1.25rem;
  height: 0.75rem;
  background-color: var(--bs-gray-200);
  margin: 0 0.25rem;
  border: 0;
  border-radius: 0.35rem;
}
.tns .tns-nav button.tns-nav-active {
  background-color: var(--bs-primary);
}
.tns.tns-initiazlied [data-tns=true] {
  display: flex;
}
.tns.tns-initiazlied .tns-item {
  opacity: 1;
  transition: all 0.3s ease;
}
.tns.tns-default {
  position: relative;
}
.tns.tns-default [data-controls=prev],
.tns.tns-default [data-controls=next] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.tns.tns-default [data-controls=prev] {
  left: 0;
}
.tns.tns-default [data-controls=next] {
  right: 0;
}
.tns.tns-default .tns-outer {
  margin: 0 4rem;
}
@media (max-width: 767.98px) {
  .tns.tns-default .tns-outer {
    margin: 0 2rem;
  }
}
.tns.tns-flush .tns-outer {
  margin: 0;
}
.tns.tns-circle-nav .tns-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.tns.tns-circle-nav .tns-nav button {
  display: block;
  outline: none;
  width: 1.15rem;
  height: 1.15rem;
  background-color: var(--bs-gray-200);
  margin: 0 0.55rem;
  border: 0;
  border-radius: 50%;
}
.tns.tns-circle-nav .tns-nav button.tns-nav-active {
  background-color: var(--bs-gray-400);
}

.tns-hide-disabled-nav [disabled] {
  display: none !important;
}

body {
  --fc-event-border-color: var(--bs-primary);
  --fc-event-bg-color: var(--bs-primary);
  --fc-event-text-color: var(--bs-primary-inverse);
}

.fc {
  --fc-border-color: var(--bs-gray-200);
  --fc-page-bg-color: #ffffff;
  --fc-small-font-size: 0.95rem;
  --fc-highlight-color: var(--bs-light);
  --fc-bg-event-opacity: 0.3;
  --fc-neutral-bg-color: var(--bs-light);
  --fc-today-bg-color: var(--bs-success-light);
  --fc-now-indicator-color: var(--bs-danger);
  --fc-list-event-hover-bg-color: var(--bs-light);
  --fc-button-text-color: var(--bs-gray-600);
  --fc-button-bg-color: var(--bs-gray-100);
  --fc-button-border-color: var(--bs-gray-100);
  --fc-button-hover-bg-color: var(--bs-gray-100);
  --fc-button-hover-border-color: var(--bs-gray-100);
  --fc-button-active-bg-color: var(--bs-gray-200);
  --fc-button-active-border-color: var(--bs-gray-200);
}
.fc table {
  font-size: 1rem;
}
.fc .fc-button {
  padding: 0.75rem 1.25rem;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0.46rem;
  vertical-align: middle;
  font-weight: 500;
  text-transform: capitalize;
}
.fc .fc-button-primary {
  margin: 0;
}
.fc .fc-button-primary .fc-icon {
  font-size: 1.35rem;
  margin-bottom: 0.15rem;
}
.fc .fc-button-primary:not(:disabled):not(.fc-button-active):focus, .fc .fc-button-primary:not(:disabled):not(.fc-button-active):hover, .fc .fc-button-primary:not(:disabled):not(.fc-button-active):active {
  color: var(--bs-gray-900);
}
.fc .fc-button-primary:not(:disabled):not(.fc-button-active):focus .fc-icon, .fc .fc-button-primary:not(:disabled):not(.fc-button-active):hover .fc-icon, .fc .fc-button-primary:not(:disabled):not(.fc-button-active):active .fc-icon {
  color: var(--bs-gray-900);
}
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: var(--bs-gray-900);
}
.fc .fc-button-primary:not(:disabled).fc-button-active .fc-icon {
  color: var(--bs-gray-900);
}
.fc .fc-button-group .fc-button {
  margin: 0 !important;
}
.fc .fc-toolbar-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--bs-gray-800);
}
.fc .fc-col-header-cell {
  padding: 0.75rem 0.5rem;
}
.fc .fc-col-header-cell .fc-col-header-cell-cushion {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--bs-gray-800);
}
.fc .fc-scrollgrid {
  border-radius: 0.46rem;
}
.fc .fc-scrollgrid thead > tr td:first-child {
  border-top-left-radius: 0.46rem;
}
.fc .fc-scrollgrid thead > tr td:last-child {
  border-top-right-radius: 0.46rem;
}
.fc .fc-scrollgrid tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 0.46rem;
}
.fc .fc-scrollgrid tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 0.46rem;
}
.fc .fc-daygrid-event {
  margin-top: 3px;
}
.fc .fc-daygrid-dot-event .fc-event-title,
.fc .fc-daygrid-dot-event .fc-event-time,
.fc .fc-daygrid-block-event .fc-event-title,
.fc .fc-daygrid-block-event .fc-event-time {
  padding: 0.25rem 0.25rem;
}
.fc .fc-daygrid-day-number {
  color: var(--bs-gray-800);
}
.fc .fc-daygrid-dot-event {
  background-color: var(--bs-light);
  color: var(--bs-gray-600);
}
.fc .fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}
.fc .fc-daygrid-dot-event:hover, .fc .fc-daygrid-dot-event.fc-event-mirror {
  background-color: var(--bs-light);
  color: var(--bs-primary);
}
.fc .fc-daygrid-event-dot {
  margin-left: 0.5rem;
  margin-right: 0.1rem;
}
.fc .fc-popover {
  border: 0 !important;
  background-color: var(--bs-body-bg);
  box-shadow: var(--bs-dropdown-box-shadow);
  border-radius: 0.46rem;
}
.modal-open .fc .fc-popover {
  z-index: 1054 !important;
}
.fc .fc-popover .fc-popover-header {
  border-top-left-radius: 0.46rem;
  border-top-right-radius: 0.46rem;
  padding: 0.65rem 0.75rem;
  background-color: var(--bs-tooltip-bg);
}
.fc .fc-popover .fc-popover-header .fc-popover-title {
  color: var(--bs-gray-800);
  font-size: 1rem;
  font-weight: 500;
}
.fc .fc-popover .fc-popover-header .fc-popover-close {
  font-size: 1rem;
  color: var(--bs-gray-600);
}
.fc .fc-popover .fc-popover-header .fc-popover-close:hover {
  color: var(--bs-primary);
}
.fc .fc-popover .fc-popover-body {
  padding: 0.5rem 0.75rem 0.75rem 0.75rem;
}
.fc .fc-daygrid-more-link {
  font-weight: 500;
}
.fc .fc-timegrid-slot {
  height: 2rem;
  font-size: 0.95rem;
}
.fc .fc-list-day-cushion,
.fc .fc-list-table td {
  padding: 0.85rem 1.15rem;
}
.fc .fc-list-day-text,
.fc .fc-list-day-side-text {
  font-size: 1.1rem;
  color: var(--bs-gray-900);
  font-weight: 600;
}
.fc .fc-list,
.fc .fc-list-table {
  border-radius: 0.46rem;
}
.fc .fc-list {
  overflow: hidden;
  position: relative;
}
.fc .fc-timegrid-axis {
  padding-left: 0rem;
  padding-right: 0;
}
.fc .fc-timegrid-event .fc-event-main {
  padding: 0.25rem 0.25rem;
}
.fc .fc-timegrid-now-indicator-arrow {
  margin-top: -1px;
}

.fc-h-event {
  font-weight: 400;
}

@media (max-width: 767.98px) {
  .fc .fc-header-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1), .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(3) {
    order: 2;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
    order: 1;
  }
  .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(2), .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
    margin-bottom: 1rem;
  }
}
.kanban-container {
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}
.kanban-container .kanban-board {
  float: none;
  flex-shrink: 0;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem !important;
  background-color: var(--bs-gray-100);
  border-radius: 0.46rem;
}
.kanban-container .kanban-board:last-child {
  margin-right: 0 !important;
}
.kanban-container .kanban-board .kanban-board-header {
  border-top-left-radius: 0.46rem;
  border-top-right-radius: 0.46rem;
}
.kanban-container .kanban-board .kanban-board-header .kanban-title-board {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--bs-gray-900);
}
.kanban-container .kanban-board .kanban-board-header.light {
  background-color: var(--bs-light);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light .kanban-title-board {
  color: var(--bs-light-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-light {
  color: rgba(var(--bs-light), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-light .kanban-title-board {
  color: var(--bs-light);
}
.kanban-container .kanban-board .kanban-board-header.primary {
  background-color: var(--bs-primary);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.primary .kanban-title-board {
  color: var(--bs-primary-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-primary {
  color: rgba(var(--bs-primary), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-primary .kanban-title-board {
  color: var(--bs-primary);
}
.kanban-container .kanban-board .kanban-board-header.secondary {
  background-color: var(--bs-secondary);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.secondary .kanban-title-board {
  color: var(--bs-secondary-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-secondary {
  color: rgba(var(--bs-secondary), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-secondary .kanban-title-board {
  color: var(--bs-secondary);
}
.kanban-container .kanban-board .kanban-board-header.success {
  background-color: var(--bs-success);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.success .kanban-title-board {
  color: var(--bs-success-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-success {
  color: rgba(var(--bs-success), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-success .kanban-title-board {
  color: var(--bs-success);
}
.kanban-container .kanban-board .kanban-board-header.info {
  background-color: var(--bs-info);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.info .kanban-title-board {
  color: var(--bs-info-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-info {
  color: rgba(var(--bs-info), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-info .kanban-title-board {
  color: var(--bs-info);
}
.kanban-container .kanban-board .kanban-board-header.warning {
  background-color: var(--bs-warning);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.warning .kanban-title-board {
  color: var(--bs-warning-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-warning {
  color: rgba(var(--bs-warning), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-warning .kanban-title-board {
  color: var(--bs-warning);
}
.kanban-container .kanban-board .kanban-board-header.danger {
  background-color: var(--bs-danger);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.danger .kanban-title-board {
  color: var(--bs-danger-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-danger {
  color: rgba(var(--bs-danger), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-danger .kanban-title-board {
  color: var(--bs-danger);
}
.kanban-container .kanban-board .kanban-board-header.dark {
  background-color: var(--bs-dark);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.dark .kanban-title-board {
  color: var(--bs-dark-inverse);
}
.kanban-container .kanban-board .kanban-board-header.light-dark {
  color: rgba(var(--bs-dark), 0.1);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-board-header.light-dark .kanban-title-board {
  color: var(--bs-dark);
}
.kanban-container .kanban-board .kanban-drag .kanban-item {
  border-radius: 0.46rem;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.05);
  background: var(--bs-body-bg);
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light] {
  background-color: var(--bs-light);
  color: var(--bs-light-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-light] {
  background-color: var(--bs-light-light);
  color: var(--bs-light);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=primary] {
  background-color: var(--bs-primary);
  color: var(--bs-primary-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-primary] {
  background-color: var(--bs-primary-light);
  color: var(--bs-primary);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=secondary] {
  background-color: var(--bs-secondary);
  color: var(--bs-secondary-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-secondary] {
  background-color: var(--bs-secondary-light);
  color: var(--bs-secondary);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=success] {
  background-color: var(--bs-success);
  color: var(--bs-success-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-success] {
  background-color: var(--bs-success-light);
  color: var(--bs-success);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=info] {
  background-color: var(--bs-info);
  color: var(--bs-info-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-info] {
  background-color: var(--bs-info-light);
  color: var(--bs-info);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=warning] {
  background-color: var(--bs-warning);
  color: var(--bs-warning-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-warning] {
  background-color: var(--bs-warning-light);
  color: var(--bs-warning);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=danger] {
  background-color: var(--bs-danger);
  color: var(--bs-danger-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-danger] {
  background-color: var(--bs-danger-light);
  color: var(--bs-danger);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=dark] {
  background-color: var(--bs-dark);
  color: var(--bs-dark-inverse);
  box-shadow: none;
}
.kanban-container .kanban-board .kanban-drag .kanban-item[data-class=light-dark] {
  background-color: var(--bs-dark-light);
  color: var(--bs-dark);
  box-shadow: none;
}
.kanban-fixed-height .kanban-container .kanban-board .kanban-drag {
  position: relative;
  overflow-y: auto;
}

.jstree-default .jstree-anchor {
  color: var(--bs-gray-700);
  padding: 0 8px 0 4px;
}
.jstree-default .jstree-icon {
  color: var(--bs-gray-700);
  font-size: 1.3rem;
}
.jstree-default .jstree-icon.la {
  font-size: 1.5rem;
}
.jstree-default .jstree-icon.fa {
  font-size: 1.2rem;
}
.jstree-default .jstree-disabled {
  cursor: not-allowed;
  line-height: auto;
  height: auto;
  opacity: 0.7;
}
.jstree-default .jstree-disabled .jstree-icon {
  color: var(--bs-gray-700);
}
.jstree-default .jstree-clicked {
  border: 0;
  background: var(--bs-gray-100);
  box-shadow: none;
}
.jstree-default .jstree-hovered {
  border: 0;
  background-color: var(--bs-gray-100);
  box-shadow: none;
}
.jstree-default .jstree-wholerow-clicked,
.jstree-default .jstree-wholerow-clicked {
  background: var(--bs-gray-200);
  box-shadow: none;
}
.jstree-default .jstree-wholerow-hovered, .jstree-default.jstree-wholerow .jstree-wholerow-hovered {
  border: 0;
  background-color: var(--bs-gray-100);
  box-shadow: none;
}

.jstree-open > .jstree-anchor > .fa-folder:before {
  margin-left: 2px;
  content: "\f07c";
}

.jstree-open > .jstree-anchor > .la-folder:before {
  margin-left: 2px;
  content: "\f200";
}

.jstree-default.jstree-rtl .jstree-node {
  background-position: 100% 1px /*rtl:ignore*/ !important;
}

.jstree-default.jstree-rtl .jstree-last {
  background: transparent /*rtl:ignore*/;
  background-repeat: no-repeat;
}

.jstree-rtl .jstree-anchor {
  padding: 0 4px 0 8px /*rtl:ignore*/;
}

.vakata-context,
.vakata-context ul {
  padding: 0.5rem 0;
  min-width: 150px;
  font-size: 1rem;
  font-family: var(--bs-font-sans-serif);
  background: var(--bs-body-bg);
  box-shadow: var(--bs-dropdown-box-shadow);
  border: 0;
  border-radius: 0.46rem;
}
.vakata-context li,
.vakata-context ul li {
  padding: 0;
  border: 0;
}
.vakata-context li a,
.vakata-context ul li a {
  padding: 0rem 1.2rem;
  border: 0;
}
.vakata-context li a i,
.vakata-context ul li a i {
  display: none;
}
.vakata-context li a .vakata-contextmenu-sep,
.vakata-context ul li a .vakata-contextmenu-sep {
  display: none;
}
.vakata-context li a span,
.vakata-context li a ins,
.vakata-context ul li a span,
.vakata-context ul li a ins {
  display: none;
  border: 0 !important;
}
.vakata-context .vakata-context-hover > a,
.vakata-context li a:hover,
.vakata-context ul .vakata-context-hover > a,
.vakata-context ul li a:hover {
  margin: 0;
  background-color: var(--bs-gray-100);
  color: var(--bs-primary);
  box-shadow: none;
}
.vakata-context .vakata-context-hover > a .span,
.vakata-context .vakata-context-hover > a .ins,
.vakata-context li a:hover .span,
.vakata-context li a:hover .ins,
.vakata-context ul .vakata-context-hover > a .span,
.vakata-context ul .vakata-context-hover > a .ins,
.vakata-context ul li a:hover .span,
.vakata-context ul li a:hover .ins {
  border: 0 !important;
}

.vakata-context .vakata-context-separator a,
.vakata-context-rtl .vakata-context-separator a {
  margin: 0;
  border: 0;
  height: 2px;
  background-color: var(--bs-gray-200);
}

.jstree-rename-input {
  outline: none !important;
  padding: 2px 6px !important;
  margin-right: -4px !important;
  background-color: var(--bs-gray-100) !important;
  border: 1px solid var(--bs-gray-100) !important;
  border-radius: 0.46rem;
}

.vis-timeline {
  border: 1px solid var(--bs-border-color) !important;
  border-radius: 0.46rem !important;
}
.vis-timeline .vis-labelset .vis-label {
  display: flex;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: none;
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--bs-gray-900);
}
.vis-timeline .vis-foreground .vis-group {
  border-bottom: none;
}
.vis-timeline .vis-item {
  position: absolute;
  color: var(--bs-gray-700);
  border-color: var(--bs-primary);
  border-width: 1px;
  background-color: var(--bs-gray-100);
  border-radius: 0.46rem !important;
}
.vis-timeline .vis-item.vis-selected {
  background-color: var(--bs-warning-light);
  color: var(--bs-gray-700);
  border-color: var(--bs-warning);
}
.vis-timeline .vis-item .vis-item-content {
  padding: 0.75rem 1rem;
  width: 100%;
  transform: none !important;
}
.vis-timeline .vis-time-axis {
  font-size: 0.95rem;
  text-transform: uppercase;
  font-weight: 500;
}
.vis-timeline .vis-time-axis .vis-text {
  color: var(--bs-gray-400);
}
.vis-timeline .vis-time-axis .vis-grid.vis-minor {
  border-left-color: var(--bs-border-dashed-color) !important;
}
.vis-timeline .vis-time-axis .vis-grid.vis-vertical {
  border-left-style: dashed !important;
}
.vis-timeline .vis-panel .vis-shadow {
  box-shadow: none !important;
}
.vis-timeline .vis-panel.vis-bottom, .vis-timeline .vis-panel.vis-center, .vis-timeline .vis-panel.vis-left, .vis-timeline .vis-panel.vis-right, .vis-timeline .vis-panel.vis-top {
  border-color: var(--bs-border-color) !important;
}
.vis-timeline .vis-current-time {
  background-color: var(--bs-success);
}

.vis-timeline-custom .vis-timeline {
  border: 0 !important;
}
.vis-timeline-custom .vis-timeline .vis-label {
  padding-left: 0 !important;
}
.vis-timeline-custom .vis-panel.vis-bottom, .vis-timeline-custom .vis-panel.vis-center, .vis-timeline-custom .vis-panel.vis-left, .vis-timeline-custom .vis-panel.vis-right, .vis-timeline-custom .vis-panel.vis-top {
  border: 0 !important;
}
.vis-timeline-custom .vis-item {
  background-color: transparent;
  border: 0 !important;
  border-radius: 0 !important;
}
.vis-timeline-custom .vis-item .vis-item-content {
  padding: 0 !important;
}

.tempus-dominus-widget {
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  width: 280px !important;
  box-shadow: var(--bs-dropdown-box-shadow) !important;
  background-color: var(--bs-body-bg) !important;
  border-radius: 0.46rem;
}
.tempus-dominus-widget i:not(.ki-outline):not(.ki-solid):not(.ki-duotone) {
  font-size: 0.9rem !important;
}
.tempus-dominus-widget .picker-switch {
  font-size: 1.05rem;
  font-weight: 600;
}
.tempus-dominus-widget .date-container-days {
  grid-auto-rows: 36px;
}
.tempus-dominus-widget .date-container-days .day {
  border-radius: 0.46rem !important;
}
.tempus-dominus-widget .date-container-months {
  grid-auto-rows: 36px;
}
.tempus-dominus-widget .date-container-months .month {
  border-radius: 0.46rem !important;
}
.tempus-dominus-widget .date-container-years {
  grid-auto-rows: 36px;
}
.tempus-dominus-widget .date-container-years .year {
  border-radius: 0.46rem !important;
}
.tempus-dominus-widget .time-container .separator {
  border: 0px !important;
}
.tempus-dominus-widget .time-container .time-container-clock div {
  border-radius: 0.46rem !important;
}
.tempus-dominus-widget .toolbar div {
  border-radius: 0.46rem;
}
.tempus-dominus-widget .toolbar div i:not(.ki-outline):not(.ki-solid):not(.ki-duotone) {
  font-size: 1.1rem !important;
}
.tempus-dominus-widget.light, .tempus-dominus-widget.dark {
  color: var(--bs-gray-900);
}
.tempus-dominus-widget.light [data-action].disabled, .tempus-dominus-widget.light [data-action].disabled:hover, .tempus-dominus-widget.dark [data-action].disabled, .tempus-dominus-widget.dark [data-action].disabled:hover {
  color: var(--bs-gray-400);
}
.tempus-dominus-widget.light .toolbar div:hover, .tempus-dominus-widget.dark .toolbar div:hover {
  background: var(--bs-gray-200);
}
.tempus-dominus-widget.light .date-container-days .dow, .tempus-dominus-widget.dark .date-container-days .dow {
  color: var(--bs-gray-700) !important;
}
.tempus-dominus-widget.light .date-container-days .cw, .tempus-dominus-widget.dark .date-container-days .cw {
  color: rgba(var(--bs-gray-900-rgb), 0.38);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight):hover,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight):hover,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight):hover, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight):hover,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight):hover {
  background: var(--bs-gray-200);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).active,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).active,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).active,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).active,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).active,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).active,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).active,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).active {
  background-color: var(--bs-primary);
  color: var(--bs-body-bg);
  text-shadow: 0 -1px 0 rgba(var(--bs-gray-900-rgb), 0.25);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active.old, .tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active.new,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active.old,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active.new, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).active.old, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).active.new,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).active.old,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).active.new {
  color: var(--bs-body-bg);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).active.today:before, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).active.today:before,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).active.today:before {
  border-bottom-color: var(--bs-body-bg);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).old, .tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).new,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).old,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).new,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).old,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).new,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).old,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).new,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).old,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).new,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).old,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).new,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).old,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).new,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).old,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).new, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).old, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).new,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).old,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).new,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).old,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).new,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).old,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).new,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).old,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).new,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).old,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).new,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).old,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).new,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).old,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).new {
  color: rgba(var(--bs-gray-900-rgb), 0.38);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).disabled, .tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).disabled,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).disabled:hover, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).disabled, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).disabled:hover,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).disabled,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).disabled:hover {
  color: var(--bs-gray-400);
}
.tempus-dominus-widget.light .date-container-decades div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .date-container-years div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .date-container-months div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .date-container-days div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .time-container-clock div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .time-container-hour div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .time-container-minute div:not(.no-highlight).today:before,
.tempus-dominus-widget.light .time-container-second div:not(.no-highlight).today:before, .tempus-dominus-widget.dark .date-container-decades div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .date-container-years div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .date-container-months div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .date-container-days div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .time-container-clock div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .time-container-hour div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .time-container-minute div:not(.no-highlight).today:before,
.tempus-dominus-widget.dark .time-container-second div:not(.no-highlight).today:before {
  border-bottom-color: var(--bs-primary);
  border-top-color: rgba(var(--bs-gray-900-rgb), 0.2);
}
.tempus-dominus-widget.light button, .tempus-dominus-widget.dark button {
  color: var(--bs-body-bg);
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.ki-duotone,
.ki-outline,
.ki-solid {
  line-height: 1;
  font-size: 1rem;
  color: var(--bs-text-muted);
}

@font-face {
  font-family: "keenicons-duotone";
  src: url("keenicons-duotone.eot?eut7fk");
  src: url("keenicons-duotone.eot?eut7fk") format("embedded-opentype"), url("keenicons-duotone.ttf?eut7fk#iefix") format("truetype"), url("keenicons-duotone.woff?eut7fk") format("woff"), url("keenicons-duotone.svg?eut7fk#keenicons-duotone") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ki-duotone {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "keenicons-duotone" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  display: inline-flex;
  direction: ltr;
  position: relative;
  display: inline-flex;
  direction: ltr;
  position: relative;
  display: inline-flex;
  direction: ltr;
  position: relative;
  display: inline-flex;
  direction: ltr;
  position: relative;
  display: inline-flex;
  direction: ltr;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ki-abstract-1 .path1:before {
  content: "\e900";
  opacity: 0.3;
}

.ki-abstract-1 .path2:before {
  content: "\e901";
  position: absolute;
  left: 0;
}

.ki-abstract-2 .path1:before {
  content: "\e902";
  opacity: 0.3;
}

.ki-abstract-2 .path2:before {
  content: "\e903";
  position: absolute;
  left: 0;
}

.ki-abstract-3 .path1:before {
  content: "\e904";
  opacity: 0.3;
}

.ki-abstract-3 .path2:before {
  content: "\e905";
  position: absolute;
  left: 0;
}

.ki-abstract-4 .path1:before {
  content: "\e906";
  opacity: 0.3;
}

.ki-abstract-4 .path2:before {
  content: "\e907";
  position: absolute;
  left: 0;
}

.ki-abstract-5 .path1:before {
  content: "\e908";
  opacity: 0.3;
}

.ki-abstract-5 .path2:before {
  content: "\e909";
  position: absolute;
  left: 0;
}

.ki-abstract-6:before {
  content: "\e90a";
}

.ki-abstract-7 .path1:before {
  content: "\e90b";
  opacity: 0.3;
}

.ki-abstract-7 .path2:before {
  content: "\e90c";
  position: absolute;
  left: 0;
}

.ki-abstract-8 .path1:before {
  content: "\e90d";
  opacity: 0.3;
}

.ki-abstract-8 .path2:before {
  content: "\e90e";
  position: absolute;
  left: 0;
}

.ki-abstract-9 .path1:before {
  content: "\e90f";
  opacity: 0.3;
}

.ki-abstract-9 .path2:before {
  content: "\e910";
  position: absolute;
  left: 0;
}

.ki-abstract-10 .path1:before {
  content: "\e911";
  opacity: 0.3;
}

.ki-abstract-10 .path2:before {
  content: "\e912";
  position: absolute;
  left: 0;
}

.ki-abstract-11 .path1:before {
  content: "\e913";
  opacity: 0.3;
}

.ki-abstract-11 .path2:before {
  content: "\e914";
  position: absolute;
  left: 0;
}

.ki-abstract-12 .path1:before {
  content: "\e915";
  opacity: 0.3;
}

.ki-abstract-12 .path2:before {
  content: "\e916";
  position: absolute;
  left: 0;
}

.ki-abstract-13 .path1:before {
  content: "\e917";
  opacity: 0.3;
}

.ki-abstract-13 .path2:before {
  content: "\e918";
  position: absolute;
  left: 0;
}

.ki-abstract-14 .path1:before {
  content: "\e919";
}

.ki-abstract-14 .path2:before {
  content: "\e91a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-15 .path1:before {
  content: "\e91b";
  opacity: 0.3;
}

.ki-abstract-15 .path2:before {
  content: "\e91c";
  position: absolute;
  left: 0;
}

.ki-abstract-16 .path1:before {
  content: "\e91d";
  opacity: 0.3;
}

.ki-abstract-16 .path2:before {
  content: "\e91e";
  position: absolute;
  left: 0;
}

.ki-abstract-17 .path1:before {
  content: "\e91f";
}

.ki-abstract-17 .path2:before {
  content: "\e920";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-18 .path1:before {
  content: "\e921";
}

.ki-abstract-18 .path2:before {
  content: "\e922";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-19 .path1:before {
  content: "\e923";
}

.ki-abstract-19 .path2:before {
  content: "\e924";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-20 .path1:before {
  content: "\e925";
}

.ki-abstract-20 .path2:before {
  content: "\e926";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-21 .path1:before {
  content: "\e927";
  opacity: 0.3;
}

.ki-abstract-21 .path2:before {
  content: "\e928";
  position: absolute;
  left: 0;
}

.ki-abstract-22 .path1:before {
  content: "\e929";
  opacity: 0.3;
}

.ki-abstract-22 .path2:before {
  content: "\e92a";
  position: absolute;
  left: 0;
}

.ki-abstract-23 .path1:before {
  content: "\e92b";
  opacity: 0.3;
}

.ki-abstract-23 .path2:before {
  content: "\e92c";
  position: absolute;
  left: 0;
}

.ki-abstract-24 .path1:before {
  content: "\e92d";
}

.ki-abstract-24 .path2:before {
  content: "\e92e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-25 .path1:before {
  content: "\e92f";
  opacity: 0.3;
}

.ki-abstract-25 .path2:before {
  content: "\e930";
  position: absolute;
  left: 0;
}

.ki-abstract-26 .path1:before {
  content: "\e931";
  opacity: 0.3;
}

.ki-abstract-26 .path2:before {
  content: "\e932";
  position: absolute;
  left: 0;
}

.ki-abstract-27 .path1:before {
  content: "\e933";
}

.ki-abstract-27 .path2:before {
  content: "\e934";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-28 .path1:before {
  content: "\e935";
}

.ki-abstract-28 .path2:before {
  content: "\e936";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-29 .path1:before {
  content: "\e937";
}

.ki-abstract-29 .path2:before {
  content: "\e938";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-30 .path1:before {
  content: "\e939";
}

.ki-abstract-30 .path2:before {
  content: "\e93a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-31 .path1:before {
  content: "\e93b";
}

.ki-abstract-31 .path2:before {
  content: "\e93c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-32 .path1:before {
  content: "\e93d";
}

.ki-abstract-32 .path2:before {
  content: "\e93e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-33 .path1:before {
  content: "\e93f";
  opacity: 0.3;
}

.ki-abstract-33 .path2:before {
  content: "\e940";
  position: absolute;
  left: 0;
}

.ki-abstract-34 .path1:before {
  content: "\e941";
  opacity: 0.3;
}

.ki-abstract-34 .path2:before {
  content: "\e942";
  position: absolute;
  left: 0;
}

.ki-abstract-35 .path1:before {
  content: "\e943";
}

.ki-abstract-35 .path2:before {
  content: "\e944";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-36 .path1:before {
  content: "\e945";
}

.ki-abstract-36 .path2:before {
  content: "\e946";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-37 .path1:before {
  content: "\e947";
  opacity: 0.3;
}

.ki-abstract-37 .path2:before {
  content: "\e948";
  position: absolute;
  left: 0;
}

.ki-abstract-38 .path1:before {
  content: "\e949";
}

.ki-abstract-38 .path2:before {
  content: "\e94a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-39 .path1:before {
  content: "\e94b";
  opacity: 0.3;
}

.ki-abstract-39 .path2:before {
  content: "\e94c";
  position: absolute;
  left: 0;
}

.ki-abstract-40 .path1:before {
  content: "\e94d";
}

.ki-abstract-40 .path2:before {
  content: "\e94e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-41 .path1:before {
  content: "\e94f";
}

.ki-abstract-41 .path2:before {
  content: "\e950";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-42 .path1:before {
  content: "\e951";
  opacity: 0.3;
}

.ki-abstract-42 .path2:before {
  content: "\e952";
  position: absolute;
  left: 0;
}

.ki-abstract-43 .path1:before {
  content: "\e953";
  opacity: 0.3;
}

.ki-abstract-43 .path2:before {
  content: "\e954";
  position: absolute;
  left: 0;
}

.ki-abstract-44 .path1:before {
  content: "\e955";
}

.ki-abstract-44 .path2:before {
  content: "\e956";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-45 .path1:before {
  content: "\e957";
}

.ki-abstract-45 .path2:before {
  content: "\e958";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-46 .path1:before {
  content: "\e959";
  opacity: 0.3;
}

.ki-abstract-46 .path2:before {
  content: "\e95a";
  position: absolute;
  left: 0;
}

.ki-abstract-47 .path1:before {
  content: "\e95b";
  opacity: 0.3;
}

.ki-abstract-47 .path2:before {
  content: "\e95c";
  position: absolute;
  left: 0;
}

.ki-abstract-48 .path1:before {
  content: "\e95d";
  opacity: 0.3;
}

.ki-abstract-48 .path2:before {
  content: "\e95e";
  position: absolute;
  left: 0;
}

.ki-abstract-48 .path3:before {
  content: "\e95f";
  position: absolute;
  left: 0;
}

.ki-abstract-49 .path1:before {
  content: "\e960";
  opacity: 0.3;
}

.ki-abstract-49 .path2:before {
  content: "\e961";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-abstract-49 .path3:before {
  content: "\e962";
  position: absolute;
  left: 0;
}

.ki-abstract .path1:before {
  content: "\e963";
  opacity: 0.3;
}

.ki-abstract .path2:before {
  content: "\e964";
  position: absolute;
  left: 0;
}

.ki-add-files .path1:before {
  content: "\e965";
}

.ki-add-files .path2:before {
  content: "\e966";
  position: absolute;
  left: 0;
}

.ki-add-files .path3:before {
  content: "\e967";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-add-folder .path1:before {
  content: "\e968";
  opacity: 0.3;
}

.ki-add-folder .path2:before {
  content: "\e969";
  position: absolute;
  left: 0;
}

.ki-add-item .path1:before {
  content: "\e96a";
  opacity: 0.3;
}

.ki-add-item .path2:before {
  content: "\e96b";
  position: absolute;
  left: 0;
}

.ki-add-item .path3:before {
  content: "\e96c";
  position: absolute;
  left: 0;
}

.ki-add-notepad .path1:before {
  content: "\e96d";
  opacity: 0.3;
}

.ki-add-notepad .path2:before {
  content: "\e96e";
  position: absolute;
  left: 0;
}

.ki-add-notepad .path3:before {
  content: "\e96f";
  position: absolute;
  left: 0;
}

.ki-add-notepad .path4:before {
  content: "\e970";
  position: absolute;
  left: 0;
}

.ki-address-book .path1:before {
  content: "\e971";
}

.ki-address-book .path2:before {
  content: "\e972";
  position: absolute;
  left: 0;
}

.ki-address-book .path3:before {
  content: "\e973";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-airplane-square .path1:before {
  content: "\e974";
  opacity: 0.3;
}

.ki-airplane-square .path2:before {
  content: "\e975";
  position: absolute;
  left: 0;
}

.ki-airplane .path1:before {
  content: "\e976";
  opacity: 0.3;
}

.ki-airplane .path2:before {
  content: "\e977";
  position: absolute;
  left: 0;
}

.ki-airpod .path1:before {
  content: "\e978";
  opacity: 0.3;
}

.ki-airpod .path2:before {
  content: "\e979";
  position: absolute;
  left: 0;
}

.ki-airpod .path3:before {
  content: "\e97a";
  position: absolute;
  left: 0;
}

.ki-android .path1:before {
  content: "\e97b";
  opacity: 0.3;
}

.ki-android .path2:before {
  content: "\e97c";
  position: absolute;
  left: 0;
}

.ki-android .path3:before {
  content: "\e97d";
  position: absolute;
  left: 0;
}

.ki-android .path4:before {
  content: "\e97e";
  position: absolute;
  left: 0;
}

.ki-android .path5:before {
  content: "\e97f";
  position: absolute;
  left: 0;
}

.ki-android .path6:before {
  content: "\e980";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-angular .path1:before {
  content: "\e981";
  opacity: 0.3;
}

.ki-angular .path2:before {
  content: "\e982";
  position: absolute;
  left: 0;
}

.ki-angular .path3:before {
  content: "\e983";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-apple .path1:before {
  content: "\e984";
}

.ki-apple .path2:before {
  content: "\e985";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-archive-tick .path1:before {
  content: "\e986";
  opacity: 0.3;
}

.ki-archive-tick .path2:before {
  content: "\e987";
  position: absolute;
  left: 0;
}

.ki-archive .path1:before {
  content: "\e988";
  opacity: 0.3;
}

.ki-archive .path2:before {
  content: "\e989";
  position: absolute;
  left: 0;
}

.ki-archive .path3:before {
  content: "\e98a";
  position: absolute;
  left: 0;
}

.ki-arrow-circle-left .path1:before {
  content: "\e98b";
  opacity: 0.3;
}

.ki-arrow-circle-left .path2:before {
  content: "\e98c";
  position: absolute;
  left: 0;
}

.ki-arrow-circle-right .path1:before {
  content: "\e98d";
  opacity: 0.3;
}

.ki-arrow-circle-right .path2:before {
  content: "\e98e";
  position: absolute;
  left: 0;
}

.ki-arrow-diagonal .path1:before {
  content: "\e98f";
  opacity: 0.3;
}

.ki-arrow-diagonal .path2:before {
  content: "\e990";
  position: absolute;
  left: 0;
}

.ki-arrow-diagonal .path3:before {
  content: "\e991";
  position: absolute;
  left: 0;
}

.ki-arrow-down-left .path1:before {
  content: "\e992";
}

.ki-arrow-down-left .path2:before {
  content: "\e993";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-down-refraction .path1:before {
  content: "\e994";
  opacity: 0.3;
}

.ki-arrow-down-refraction .path2:before {
  content: "\e995";
  position: absolute;
  left: 0;
}

.ki-arrow-down-right .path1:before {
  content: "\e996";
}

.ki-arrow-down-right .path2:before {
  content: "\e997";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-down .path1:before {
  content: "\e998";
}

.ki-arrow-down .path2:before {
  content: "\e999";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-left .path1:before {
  content: "\e99a";
}

.ki-arrow-left .path2:before {
  content: "\e99b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-mix .path1:before {
  content: "\e99c";
}

.ki-arrow-mix .path2:before {
  content: "\e99d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-right-left .path1:before {
  content: "\e99e";
  opacity: 0.3;
}

.ki-arrow-right-left .path2:before {
  content: "\e99f";
  position: absolute;
  left: 0;
}

.ki-arrow-right .path1:before {
  content: "\e9a0";
}

.ki-arrow-right .path2:before {
  content: "\e9a1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-two-diagonals .path1:before {
  content: "\e9a2";
  opacity: 0.3;
}

.ki-arrow-two-diagonals .path2:before {
  content: "\e9a3";
  position: absolute;
  left: 0;
}

.ki-arrow-two-diagonals .path3:before {
  content: "\e9a4";
  position: absolute;
  left: 0;
}

.ki-arrow-two-diagonals .path4:before {
  content: "\e9a5";
  position: absolute;
  left: 0;
}

.ki-arrow-two-diagonals .path5:before {
  content: "\e9a6";
  position: absolute;
  left: 0;
}

.ki-arrow-up-down .path1:before {
  content: "\e9a7";
  opacity: 0.3;
}

.ki-arrow-up-down .path2:before {
  content: "\e9a8";
  position: absolute;
  left: 0;
}

.ki-arrow-up-left .path1:before {
  content: "\e9a9";
}

.ki-arrow-up-left .path2:before {
  content: "\e9aa";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-up-refraction .path1:before {
  content: "\e9ab";
  opacity: 0.3;
}

.ki-arrow-up-refraction .path2:before {
  content: "\e9ac";
  position: absolute;
  left: 0;
}

.ki-arrow-up-right .path1:before {
  content: "\e9ad";
}

.ki-arrow-up-right .path2:before {
  content: "\e9ae";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-up .path1:before {
  content: "\e9af";
}

.ki-arrow-up .path2:before {
  content: "\e9b0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrow-zigzag .path1:before {
  content: "\e9b1";
}

.ki-arrow-zigzag .path2:before {
  content: "\e9b2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-arrows-circle .path1:before {
  content: "\e9b3";
  opacity: 0.3;
}

.ki-arrows-circle .path2:before {
  content: "\e9b4";
  position: absolute;
  left: 0;
}

.ki-arrows-loop .path1:before {
  content: "\e9b5";
  opacity: 0.3;
}

.ki-arrows-loop .path2:before {
  content: "\e9b6";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path1:before {
  content: "\e9b7";
  opacity: 0.3;
}

.ki-artificial-intelligence .path2:before {
  content: "\e9b8";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path3:before {
  content: "\e9b9";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path4:before {
  content: "\e9ba";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path5:before {
  content: "\e9bb";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path6:before {
  content: "\e9bc";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path7:before {
  content: "\e9bd";
  position: absolute;
  left: 0;
}

.ki-artificial-intelligence .path8:before {
  content: "\e9be";
  position: absolute;
  left: 0;
}

.ki-auto-brightness .path1:before {
  content: "\e9bf";
  opacity: 0.3;
}

.ki-auto-brightness .path2:before {
  content: "\e9c0";
  position: absolute;
  left: 0;
}

.ki-auto-brightness .path3:before {
  content: "\e9c1";
  position: absolute;
  left: 0;
}

.ki-avalanche .path1:before {
  content: "\e9c2";
  opacity: 0.3;
}

.ki-avalanche .path2:before {
  content: "\e9c3";
  position: absolute;
  left: 0;
}

.ki-award .path1:before {
  content: "\e9c4";
  opacity: 0.3;
}

.ki-award .path2:before {
  content: "\e9c5";
  position: absolute;
  left: 0;
}

.ki-award .path3:before {
  content: "\e9c6";
  position: absolute;
  left: 0;
}

.ki-badge .path1:before {
  content: "\e9c7";
  opacity: 0.3;
}

.ki-badge .path2:before {
  content: "\e9c8";
  position: absolute;
  left: 0;
}

.ki-badge .path3:before {
  content: "\e9c9";
  position: absolute;
  left: 0;
}

.ki-badge .path4:before {
  content: "\e9ca";
  position: absolute;
  left: 0;
}

.ki-badge .path5:before {
  content: "\e9cb";
  position: absolute;
  left: 0;
}

.ki-bandage .path1:before {
  content: "\e9cc";
  opacity: 0.3;
}

.ki-bandage .path2:before {
  content: "\e9cd";
  position: absolute;
  left: 0;
}

.ki-bank .path1:before {
  content: "\e9ce";
  opacity: 0.3;
}

.ki-bank .path2:before {
  content: "\e9cf";
  position: absolute;
  left: 0;
}

.ki-barcode .path1:before {
  content: "\e9d0";
}

.ki-barcode .path2:before {
  content: "\e9d1";
  position: absolute;
  left: 0;
}

.ki-barcode .path3:before {
  content: "\e9d2";
  position: absolute;
  left: 0;
}

.ki-barcode .path4:before {
  content: "\e9d3";
  position: absolute;
  left: 0;
}

.ki-barcode .path5:before {
  content: "\e9d4";
  position: absolute;
  left: 0;
}

.ki-barcode .path6:before {
  content: "\e9d5";
  position: absolute;
  left: 0;
}

.ki-barcode .path7:before {
  content: "\e9d6";
  position: absolute;
  left: 0;
}

.ki-barcode .path8:before {
  content: "\e9d7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-basket-ok .path1:before {
  content: "\e9d8";
  opacity: 0.3;
}

.ki-basket-ok .path2:before {
  content: "\e9d9";
  position: absolute;
  left: 0;
}

.ki-basket-ok .path3:before {
  content: "\e9da";
  position: absolute;
  left: 0;
}

.ki-basket-ok .path4:before {
  content: "\e9db";
  position: absolute;
  left: 0;
}

.ki-basket .path1:before {
  content: "\e9dc";
  opacity: 0.3;
}

.ki-basket .path2:before {
  content: "\e9dd";
  position: absolute;
  left: 0;
}

.ki-basket .path3:before {
  content: "\e9de";
  position: absolute;
  left: 0;
}

.ki-basket .path4:before {
  content: "\e9df";
  position: absolute;
  left: 0;
}

.ki-behance .path1:before {
  content: "\e9e0";
}

.ki-behance .path2:before {
  content: "\e9e1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-bill .path1:before {
  content: "\e9e2";
  opacity: 0.3;
}

.ki-bill .path2:before {
  content: "\e9e3";
  position: absolute;
  left: 0;
}

.ki-bill .path3:before {
  content: "\e9e4";
  position: absolute;
  left: 0;
}

.ki-bill .path4:before {
  content: "\e9e5";
  position: absolute;
  left: 0;
}

.ki-bill .path5:before {
  content: "\e9e6";
  position: absolute;
  left: 0;
}

.ki-bill .path6:before {
  content: "\e9e7";
  position: absolute;
  left: 0;
}

.ki-binance-usd .path1:before {
  content: "\e9e8";
}

.ki-binance-usd .path2:before {
  content: "\e9e9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-binance-usd .path3:before {
  content: "\e9ea";
  position: absolute;
  left: 0;
}

.ki-binance-usd .path4:before {
  content: "\e9eb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-binance .path1:before {
  content: "\e9ec";
  opacity: 0.3;
}

.ki-binance .path2:before {
  content: "\e9ed";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-binance .path3:before {
  content: "\e9ee";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-binance .path4:before {
  content: "\e9ef";
  position: absolute;
  left: 0;
}

.ki-binance .path5:before {
  content: "\e9f0";
  position: absolute;
  left: 0;
}

.ki-bitcoin .path1:before {
  content: "\e9f1";
  opacity: 0.3;
}

.ki-bitcoin .path2:before {
  content: "\e9f2";
  position: absolute;
  left: 0;
}

.ki-black-down:before {
  content: "\e9f3";
}

.ki-black-left-line .path1:before {
  content: "\e9f4";
}

.ki-black-left-line .path2:before {
  content: "\e9f5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-black-left:before {
  content: "\e9f6";
}

.ki-black-right-line .path1:before {
  content: "\e9f7";
}

.ki-black-right-line .path2:before {
  content: "\e9f8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-black-right:before {
  content: "\e9f9";
}

.ki-black-up:before {
  content: "\e9fa";
}

.ki-bluetooth .path1:before {
  content: "\e9fb";
}

.ki-bluetooth .path2:before {
  content: "\e9fc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-book-open .path1:before {
  content: "\e9fd";
  opacity: 0.3;
}

.ki-book-open .path2:before {
  content: "\e9fe";
  position: absolute;
  left: 0;
}

.ki-book-open .path3:before {
  content: "\e9ff";
  position: absolute;
  left: 0;
}

.ki-book-open .path4:before {
  content: "\ea00";
  position: absolute;
  left: 0;
}

.ki-book-square .path1:before {
  content: "\ea01";
  opacity: 0.3;
}

.ki-book-square .path2:before {
  content: "\ea02";
  position: absolute;
  left: 0;
}

.ki-book-square .path3:before {
  content: "\ea03";
  position: absolute;
  left: 0;
}

.ki-book .path1:before {
  content: "\ea04";
  opacity: 0.3;
}

.ki-book .path2:before {
  content: "\ea05";
  position: absolute;
  left: 0;
}

.ki-book .path3:before {
  content: "\ea06";
  position: absolute;
  left: 0;
}

.ki-book .path4:before {
  content: "\ea07";
  position: absolute;
  left: 0;
}

.ki-bookmark-2 .path1:before {
  content: "\ea08";
  opacity: 0.3;
}

.ki-bookmark-2 .path2:before {
  content: "\ea09";
  position: absolute;
  left: 0;
}

.ki-bookmark .path1:before {
  content: "\ea0a";
  opacity: 0.3;
}

.ki-bookmark .path2:before {
  content: "\ea0b";
  position: absolute;
  left: 0;
}

.ki-bootstrap .path1:before {
  content: "\ea0c";
  opacity: 0.3;
}

.ki-bootstrap .path2:before {
  content: "\ea0d";
  position: absolute;
  left: 0;
}

.ki-bootstrap .path3:before {
  content: "\ea0e";
  position: absolute;
  left: 0;
}

.ki-briefcase .path1:before {
  content: "\ea0f";
  opacity: 0.3;
}

.ki-briefcase .path2:before {
  content: "\ea10";
  position: absolute;
  left: 0;
}

.ki-brifecase-cros .path1:before {
  content: "\ea11";
  opacity: 0.3;
}

.ki-brifecase-cros .path2:before {
  content: "\ea12";
  position: absolute;
  left: 0;
}

.ki-brifecase-cros .path3:before {
  content: "\ea13";
  position: absolute;
  left: 0;
}

.ki-brifecase-tick .path1:before {
  content: "\ea14";
  opacity: 0.3;
}

.ki-brifecase-tick .path2:before {
  content: "\ea15";
  position: absolute;
  left: 0;
}

.ki-brifecase-tick .path3:before {
  content: "\ea16";
  position: absolute;
  left: 0;
}

.ki-brifecase-timer .path1:before {
  content: "\ea17";
  opacity: 0.3;
}

.ki-brifecase-timer .path2:before {
  content: "\ea18";
  position: absolute;
  left: 0;
}

.ki-brifecase-timer .path3:before {
  content: "\ea19";
  position: absolute;
  left: 0;
}

.ki-brush .path1:before {
  content: "\ea1a";
  opacity: 0.3;
}

.ki-brush .path2:before {
  content: "\ea1b";
  position: absolute;
  left: 0;
}

.ki-bucket-square .path1:before {
  content: "\ea1c";
  opacity: 0.3;
}

.ki-bucket-square .path2:before {
  content: "\ea1d";
  position: absolute;
  left: 0;
}

.ki-bucket-square .path3:before {
  content: "\ea1e";
  position: absolute;
  left: 0;
}

.ki-bucket .path1:before {
  content: "\ea1f";
  opacity: 0.3;
}

.ki-bucket .path2:before {
  content: "\ea20";
  position: absolute;
  left: 0;
}

.ki-bucket .path3:before {
  content: "\ea21";
  position: absolute;
  left: 0;
}

.ki-bucket .path4:before {
  content: "\ea22";
  position: absolute;
  left: 0;
}

.ki-burger-menu-1 .path1:before {
  content: "\ea23";
  opacity: 0.3;
}

.ki-burger-menu-1 .path2:before {
  content: "\ea24";
  position: absolute;
  left: 0;
}

.ki-burger-menu-1 .path3:before {
  content: "\ea25";
  position: absolute;
  left: 0;
}

.ki-burger-menu-1 .path4:before {
  content: "\ea26";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path1:before {
  content: "\ea27";
  opacity: 0.3;
}

.ki-burger-menu-2 .path2:before {
  content: "\ea28";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path3:before {
  content: "\ea29";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path4:before {
  content: "\ea2a";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path5:before {
  content: "\ea2b";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path6:before {
  content: "\ea2c";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path7:before {
  content: "\ea2d";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path8:before {
  content: "\ea2e";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path9:before {
  content: "\ea2f";
  position: absolute;
  left: 0;
}

.ki-burger-menu-2 .path10:before {
  content: "\ea30";
  position: absolute;
  left: 0;
}

.ki-burger-menu-3 .path1:before {
  content: "\ea31";
  opacity: 0.3;
}

.ki-burger-menu-3 .path2:before {
  content: "\ea32";
  position: absolute;
  left: 0;
}

.ki-burger-menu-3 .path3:before {
  content: "\ea33";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-burger-menu-3 .path4:before {
  content: "\ea34";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-burger-menu-3 .path5:before {
  content: "\ea35";
  position: absolute;
  left: 0;
}

.ki-burger-menu-3 .path6:before {
  content: "\ea36";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-burger-menu-3 .path7:before {
  content: "\ea37";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-burger-menu-3 .path8:before {
  content: "\ea38";
  position: absolute;
  left: 0;
}

.ki-burger-menu-3 .path9:before {
  content: "\ea39";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-burger-menu-4:before {
  content: "\ea3a";
}

.ki-burger-menu-5:before {
  content: "\ea3b";
}

.ki-burger-menu-6:before {
  content: "\ea3c";
}

.ki-burger-menu .path1:before {
  content: "\ea3d";
  opacity: 0.3;
}

.ki-burger-menu .path2:before {
  content: "\ea3e";
  position: absolute;
  left: 0;
}

.ki-burger-menu .path3:before {
  content: "\ea3f";
  position: absolute;
  left: 0;
}

.ki-burger-menu .path4:before {
  content: "\ea40";
  position: absolute;
  left: 0;
}

.ki-bus .path1:before {
  content: "\ea41";
  opacity: 0.3;
}

.ki-bus .path2:before {
  content: "\ea42";
  position: absolute;
  left: 0;
}

.ki-bus .path3:before {
  content: "\ea43";
  position: absolute;
  left: 0;
}

.ki-bus .path4:before {
  content: "\ea44";
  position: absolute;
  left: 0;
}

.ki-bus .path5:before {
  content: "\ea45";
  position: absolute;
  left: 0;
}

.ki-calculator .path1:before {
  content: "\ea46";
  opacity: 0.3;
}

.ki-calculator .path2:before {
  content: "\ea47";
  position: absolute;
  left: 0;
}

.ki-calculator .path3:before {
  content: "\ea48";
  position: absolute;
  left: 0;
}

.ki-calculator .path4:before {
  content: "\ea49";
  position: absolute;
  left: 0;
}

.ki-calculator .path5:before {
  content: "\ea4a";
  position: absolute;
  left: 0;
}

.ki-calculator .path6:before {
  content: "\ea4b";
  position: absolute;
  left: 0;
}

.ki-calendar-2 .path1:before {
  content: "\ea4c";
  opacity: 0.3;
}

.ki-calendar-2 .path2:before {
  content: "\ea4d";
  position: absolute;
  left: 0;
}

.ki-calendar-2 .path3:before {
  content: "\ea4e";
  position: absolute;
  left: 0;
}

.ki-calendar-2 .path4:before {
  content: "\ea4f";
  position: absolute;
  left: 0;
}

.ki-calendar-2 .path5:before {
  content: "\ea50";
  position: absolute;
  left: 0;
}

.ki-calendar-8 .path1:before {
  content: "\ea51";
  opacity: 0.3;
}

.ki-calendar-8 .path2:before {
  content: "\ea52";
  position: absolute;
  left: 0;
}

.ki-calendar-8 .path3:before {
  content: "\ea53";
  position: absolute;
  left: 0;
}

.ki-calendar-8 .path4:before {
  content: "\ea54";
  position: absolute;
  left: 0;
}

.ki-calendar-8 .path5:before {
  content: "\ea55";
  position: absolute;
  left: 0;
}

.ki-calendar-8 .path6:before {
  content: "\ea56";
  position: absolute;
  left: 0;
}

.ki-calendar-add .path1:before {
  content: "\ea57";
  opacity: 0.3;
}

.ki-calendar-add .path2:before {
  content: "\ea58";
  position: absolute;
  left: 0;
}

.ki-calendar-add .path3:before {
  content: "\ea59";
  position: absolute;
  left: 0;
}

.ki-calendar-add .path4:before {
  content: "\ea5a";
  position: absolute;
  left: 0;
}

.ki-calendar-add .path5:before {
  content: "\ea5b";
  position: absolute;
  left: 0;
}

.ki-calendar-add .path6:before {
  content: "\ea5c";
  position: absolute;
  left: 0;
}

.ki-calendar-edit .path1:before {
  content: "\ea5d";
  opacity: 0.3;
}

.ki-calendar-edit .path2:before {
  content: "\ea5e";
  position: absolute;
  left: 0;
}

.ki-calendar-edit .path3:before {
  content: "\ea5f";
  position: absolute;
  left: 0;
}

.ki-calendar-remove .path1:before {
  content: "\ea60";
  opacity: 0.3;
}

.ki-calendar-remove .path2:before {
  content: "\ea61";
  position: absolute;
  left: 0;
}

.ki-calendar-remove .path3:before {
  content: "\ea62";
  position: absolute;
  left: 0;
}

.ki-calendar-remove .path4:before {
  content: "\ea63";
  position: absolute;
  left: 0;
}

.ki-calendar-remove .path5:before {
  content: "\ea64";
  position: absolute;
  left: 0;
}

.ki-calendar-remove .path6:before {
  content: "\ea65";
  position: absolute;
  left: 0;
}

.ki-calendar-search .path1:before {
  content: "\ea66";
  opacity: 0.3;
}

.ki-calendar-search .path2:before {
  content: "\ea67";
  position: absolute;
  left: 0;
}

.ki-calendar-search .path3:before {
  content: "\ea68";
  position: absolute;
  left: 0;
}

.ki-calendar-search .path4:before {
  content: "\ea69";
  position: absolute;
  left: 0;
}

.ki-calendar-tick .path1:before {
  content: "\ea6a";
  opacity: 0.3;
}

.ki-calendar-tick .path2:before {
  content: "\ea6b";
  position: absolute;
  left: 0;
}

.ki-calendar-tick .path3:before {
  content: "\ea6c";
  position: absolute;
  left: 0;
}

.ki-calendar-tick .path4:before {
  content: "\ea6d";
  position: absolute;
  left: 0;
}

.ki-calendar-tick .path5:before {
  content: "\ea6e";
  position: absolute;
  left: 0;
}

.ki-calendar-tick .path6:before {
  content: "\ea6f";
  position: absolute;
  left: 0;
}

.ki-calendar .path1:before {
  content: "\ea70";
  opacity: 0.3;
}

.ki-calendar .path2:before {
  content: "\ea71";
  position: absolute;
  left: 0;
}

.ki-call .path1:before {
  content: "\ea72";
  opacity: 0.3;
}

.ki-call .path2:before {
  content: "\ea73";
  position: absolute;
  left: 0;
}

.ki-call .path3:before {
  content: "\ea74";
  position: absolute;
  left: 0;
}

.ki-call .path4:before {
  content: "\ea75";
  position: absolute;
  left: 0;
}

.ki-call .path5:before {
  content: "\ea76";
  position: absolute;
  left: 0;
}

.ki-call .path6:before {
  content: "\ea77";
  position: absolute;
  left: 0;
}

.ki-call .path7:before {
  content: "\ea78";
  position: absolute;
  left: 0;
}

.ki-call .path8:before {
  content: "\ea79";
  position: absolute;
  left: 0;
}

.ki-capsule .path1:before {
  content: "\ea7a";
  opacity: 0.3;
}

.ki-capsule .path2:before {
  content: "\ea7b";
  position: absolute;
  left: 0;
}

.ki-car-2 .path1:before {
  content: "\ea7c";
}

.ki-car-2 .path2:before {
  content: "\ea7d";
  position: absolute;
  left: 0;
}

.ki-car-2 .path3:before {
  content: "\ea7e";
  position: absolute;
  left: 0;
}

.ki-car-2 .path4:before {
  content: "\ea7f";
  position: absolute;
  left: 0;
}

.ki-car-2 .path5:before {
  content: "\ea80";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-car-2 .path6:before {
  content: "\ea81";
  position: absolute;
  left: 0;
}

.ki-car-3 .path1:before {
  content: "\ea82";
}

.ki-car-3 .path2:before {
  content: "\ea83";
  position: absolute;
  left: 0;
}

.ki-car-3 .path3:before {
  content: "\ea84";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-car .path1:before {
  content: "\ea85";
  opacity: 0.3;
}

.ki-car .path2:before {
  content: "\ea86";
  position: absolute;
  left: 0;
}

.ki-car .path3:before {
  content: "\ea87";
  position: absolute;
  left: 0;
}

.ki-car .path4:before {
  content: "\ea88";
  position: absolute;
  left: 0;
}

.ki-car .path5:before {
  content: "\ea89";
  position: absolute;
  left: 0;
}

.ki-category .path1:before {
  content: "\ea8a";
}

.ki-category .path2:before {
  content: "\ea8b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-category .path3:before {
  content: "\ea8c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-category .path4:before {
  content: "\ea8d";
  position: absolute;
  left: 0;
}

.ki-cd .path1:before {
  content: "\ea8e";
}

.ki-cd .path2:before {
  content: "\ea8f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-celsius .path1:before {
  content: "\ea90";
}

.ki-celsius .path2:before {
  content: "\ea91";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-line-down-2 .path1:before {
  content: "\ea92";
  opacity: 0.3;
}

.ki-chart-line-down-2 .path2:before {
  content: "\ea93";
  position: absolute;
  left: 0;
}

.ki-chart-line-down-2 .path3:before {
  content: "\ea94";
  position: absolute;
  left: 0;
}

.ki-chart-line-down .path1:before {
  content: "\ea95";
  opacity: 0.3;
}

.ki-chart-line-down .path2:before {
  content: "\ea96";
  position: absolute;
  left: 0;
}

.ki-chart-line-star .path1:before {
  content: "\ea97";
}

.ki-chart-line-star .path2:before {
  content: "\ea98";
  position: absolute;
  left: 0;
}

.ki-chart-line-star .path3:before {
  content: "\ea99";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-line-up-2 .path1:before {
  content: "\ea9a";
}

.ki-chart-line-up-2 .path2:before {
  content: "\ea9b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-line-up .path1:before {
  content: "\ea9c";
}

.ki-chart-line-up .path2:before {
  content: "\ea9d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-line .path1:before {
  content: "\ea9e";
  opacity: 0.3;
}

.ki-chart-line .path2:before {
  content: "\ea9f";
  position: absolute;
  left: 0;
}

.ki-chart-pie-3 .path1:before {
  content: "\eaa0";
}

.ki-chart-pie-3 .path2:before {
  content: "\eaa1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-pie-3 .path3:before {
  content: "\eaa2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-pie-4 .path1:before {
  content: "\eaa3";
}

.ki-chart-pie-4 .path2:before {
  content: "\eaa4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-pie-4 .path3:before {
  content: "\eaa5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-pie-simple .path1:before {
  content: "\eaa6";
}

.ki-chart-pie-simple .path2:before {
  content: "\eaa7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-pie-too .path1:before {
  content: "\eaa8";
  opacity: 0.3;
}

.ki-chart-pie-too .path2:before {
  content: "\eaa9";
  position: absolute;
  left: 0;
}

.ki-chart-simple-2 .path1:before {
  content: "\eaaa";
  opacity: 0.3;
}

.ki-chart-simple-2 .path2:before {
  content: "\eaab";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-simple-2 .path3:before {
  content: "\eaac";
  position: absolute;
  left: 0;
}

.ki-chart-simple-2 .path4:before {
  content: "\eaad";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-simple-3 .path1:before {
  content: "\eaae";
  opacity: 0.3;
}

.ki-chart-simple-3 .path2:before {
  content: "\eaaf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-simple-3 .path3:before {
  content: "\eab0";
  position: absolute;
  left: 0;
}

.ki-chart-simple-3 .path4:before {
  content: "\eab1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-simple .path1:before {
  content: "\eab2";
  opacity: 0.3;
}

.ki-chart-simple .path2:before {
  content: "\eab3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart-simple .path3:before {
  content: "\eab4";
  position: absolute;
  left: 0;
}

.ki-chart-simple .path4:before {
  content: "\eab5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-chart .path1:before {
  content: "\eab6";
}

.ki-chart .path2:before {
  content: "\eab7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-check-circle .path1:before {
  content: "\eab8";
  opacity: 0.3;
}

.ki-check-circle .path2:before {
  content: "\eab9";
  position: absolute;
  left: 0;
}

.ki-check-square .path1:before {
  content: "\eaba";
  opacity: 0.3;
}

.ki-check-square .path2:before {
  content: "\eabb";
  position: absolute;
  left: 0;
}

.ki-check:before {
  content: "\eabc";
}

.ki-cheque .path1:before {
  content: "\eabd";
}

.ki-cheque .path2:before {
  content: "\eabe";
  position: absolute;
  left: 0;
}

.ki-cheque .path3:before {
  content: "\eabf";
  position: absolute;
  left: 0;
}

.ki-cheque .path4:before {
  content: "\eac0";
  position: absolute;
  left: 0;
}

.ki-cheque .path5:before {
  content: "\eac1";
  position: absolute;
  left: 0;
}

.ki-cheque .path6:before {
  content: "\eac2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-cheque .path7:before {
  content: "\eac3";
  position: absolute;
  left: 0;
}

.ki-chrome .path1:before {
  content: "\eac4";
  opacity: 0.3;
}

.ki-chrome .path2:before {
  content: "\eac5";
  position: absolute;
  left: 0;
}

.ki-classmates .path1:before {
  content: "\eac6";
}

.ki-classmates .path2:before {
  content: "\eac7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-click .path1:before {
  content: "\eac8";
}

.ki-click .path2:before {
  content: "\eac9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-click .path3:before {
  content: "\eaca";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-click .path4:before {
  content: "\eacb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-click .path5:before {
  content: "\eacc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-clipboard .path1:before {
  content: "\eacd";
  opacity: 0.3;
}

.ki-clipboard .path2:before {
  content: "\eace";
  position: absolute;
  left: 0;
}

.ki-clipboard .path3:before {
  content: "\eacf";
  position: absolute;
  left: 0;
}

.ki-cloud-add .path1:before {
  content: "\ead0";
}

.ki-cloud-add .path2:before {
  content: "\ead1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-cloud-change .path1:before {
  content: "\ead2";
}

.ki-cloud-change .path2:before {
  content: "\ead3";
  position: absolute;
  left: 0;
}

.ki-cloud-change .path3:before {
  content: "\ead4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-cloud-download .path1:before {
  content: "\ead5";
  opacity: 0.3;
}

.ki-cloud-download .path2:before {
  content: "\ead6";
  position: absolute;
  left: 0;
}

.ki-cloud:before {
  content: "\ead7";
}

.ki-code .path1:before {
  content: "\ead8";
  opacity: 0.3;
}

.ki-code .path2:before {
  content: "\ead9";
  position: absolute;
  left: 0;
}

.ki-code .path3:before {
  content: "\eada";
  position: absolute;
  left: 0;
}

.ki-code .path4:before {
  content: "\eadb";
  position: absolute;
  left: 0;
}

.ki-coffee .path1:before {
  content: "\eadc";
  opacity: 0.3;
}

.ki-coffee .path2:before {
  content: "\eadd";
  position: absolute;
  left: 0;
}

.ki-coffee .path3:before {
  content: "\eade";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-coffee .path4:before {
  content: "\eadf";
  position: absolute;
  left: 0;
}

.ki-coffee .path5:before {
  content: "\eae0";
  position: absolute;
  left: 0;
}

.ki-coffee .path6:before {
  content: "\eae1";
  position: absolute;
  left: 0;
}

.ki-color-swatch .path1:before {
  content: "\eae2";
  opacity: 0.3;
}

.ki-color-swatch .path2:before {
  content: "\eae3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path3:before {
  content: "\eae4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path4:before {
  content: "\eae5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path5:before {
  content: "\eae6";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path6:before {
  content: "\eae7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path7:before {
  content: "\eae8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path8:before {
  content: "\eae9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path9:before {
  content: "\eaea";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path10:before {
  content: "\eaeb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path11:before {
  content: "\eaec";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path12:before {
  content: "\eaed";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path13:before {
  content: "\eaee";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path14:before {
  content: "\eaef";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path15:before {
  content: "\eaf0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path16:before {
  content: "\eaf1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path17:before {
  content: "\eaf2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path18:before {
  content: "\eaf3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path19:before {
  content: "\eaf4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path20:before {
  content: "\eaf5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-color-swatch .path21:before {
  content: "\eaf6";
  position: absolute;
  left: 0;
}

.ki-colors-square .path1:before {
  content: "\eaf7";
  opacity: 0.3;
}

.ki-colors-square .path2:before {
  content: "\eaf8";
  position: absolute;
  left: 0;
}

.ki-colors-square .path3:before {
  content: "\eaf9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-colors-square .path4:before {
  content: "\eafa";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-compass .path1:before {
  content: "\eafb";
}

.ki-compass .path2:before {
  content: "\eafc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-copy-success .path1:before {
  content: "\eafd";
}

.ki-copy-success .path2:before {
  content: "\eafe";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-copy:before {
  content: "\eaff";
}

.ki-courier-express .path1:before {
  content: "\eb00";
}

.ki-courier-express .path2:before {
  content: "\eb01";
  position: absolute;
  left: 0;
}

.ki-courier-express .path3:before {
  content: "\eb02";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-courier-express .path4:before {
  content: "\eb03";
  position: absolute;
  left: 0;
}

.ki-courier-express .path5:before {
  content: "\eb04";
  position: absolute;
  left: 0;
}

.ki-courier-express .path6:before {
  content: "\eb05";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-courier-express .path7:before {
  content: "\eb06";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-courier .path1:before {
  content: "\eb07";
}

.ki-courier .path2:before {
  content: "\eb08";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-courier .path3:before {
  content: "\eb09";
  position: absolute;
  left: 0;
}

.ki-credit-cart .path1:before {
  content: "\eb0a";
  opacity: 0.3;
}

.ki-credit-cart .path2:before {
  content: "\eb0b";
  position: absolute;
  left: 0;
}

.ki-cross-circle .path1:before {
  content: "\eb0c";
  opacity: 0.3;
}

.ki-cross-circle .path2:before {
  content: "\eb0d";
  position: absolute;
  left: 0;
}

.ki-cross-square .path1:before {
  content: "\eb0e";
  opacity: 0.3;
}

.ki-cross-square .path2:before {
  content: "\eb0f";
  position: absolute;
  left: 0;
}

.ki-cross .path1:before {
  content: "\eb10";
}

.ki-cross .path2:before {
  content: "\eb11";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-crown-2 .path1:before {
  content: "\eb12";
  opacity: 0.3;
}

.ki-crown-2 .path2:before {
  content: "\eb13";
  position: absolute;
  left: 0;
}

.ki-crown-2 .path3:before {
  content: "\eb14";
  position: absolute;
  left: 0;
}

.ki-crown .path1:before {
  content: "\eb15";
  opacity: 0.3;
}

.ki-crown .path2:before {
  content: "\eb16";
  position: absolute;
  left: 0;
}

.ki-css .path1:before {
  content: "\eb17";
  opacity: 0.3;
}

.ki-css .path2:before {
  content: "\eb18";
  position: absolute;
  left: 0;
}

.ki-cube-2 .path1:before {
  content: "\eb19";
  opacity: 0.3;
}

.ki-cube-2 .path2:before {
  content: "\eb1a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-cube-2 .path3:before {
  content: "\eb1b";
  position: absolute;
  left: 0;
}

.ki-cube-3 .path1:before {
  content: "\eb1c";
  opacity: 0.3;
}

.ki-cube-3 .path2:before {
  content: "\eb1d";
  position: absolute;
  left: 0;
}

.ki-cup .path1:before {
  content: "\eb1e";
  opacity: 0.3;
}

.ki-cup .path2:before {
  content: "\eb1f";
  position: absolute;
  left: 0;
}

.ki-dash .path1:before {
  content: "\eb20";
  opacity: 0.3;
}

.ki-dash .path2:before {
  content: "\eb21";
  position: absolute;
  left: 0;
}

.ki-data .path1:before {
  content: "\eb22";
}

.ki-data .path2:before {
  content: "\eb23";
  position: absolute;
  left: 0;
}

.ki-data .path3:before {
  content: "\eb24";
  position: absolute;
  left: 0;
}

.ki-data .path4:before {
  content: "\eb25";
  position: absolute;
  left: 0;
}

.ki-data .path5:before {
  content: "\eb26";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delete-files .path1:before {
  content: "\eb27";
}

.ki-delete-files .path2:before {
  content: "\eb28";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delete-folder .path1:before {
  content: "\eb29";
  opacity: 0.3;
}

.ki-delete-folder .path2:before {
  content: "\eb2a";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path1:before {
  content: "\eb2b";
  opacity: 0.3;
}

.ki-delivery-2 .path2:before {
  content: "\eb2c";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path3:before {
  content: "\eb2d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery-2 .path4:before {
  content: "\eb2e";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path5:before {
  content: "\eb2f";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path6:before {
  content: "\eb30";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path7:before {
  content: "\eb31";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery-2 .path8:before {
  content: "\eb32";
  position: absolute;
  left: 0;
}

.ki-delivery-2 .path9:before {
  content: "\eb33";
  position: absolute;
  left: 0;
}

.ki-delivery-3 .path1:before {
  content: "\eb34";
  opacity: 0.3;
}

.ki-delivery-3 .path2:before {
  content: "\eb35";
  position: absolute;
  left: 0;
}

.ki-delivery-3 .path3:before {
  content: "\eb36";
  position: absolute;
  left: 0;
}

.ki-delivery-24 .path1:before {
  content: "\eb37";
}

.ki-delivery-24 .path2:before {
  content: "\eb38";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery-24 .path3:before {
  content: "\eb39";
  position: absolute;
  left: 0;
}

.ki-delivery-24 .path4:before {
  content: "\eb3a";
  position: absolute;
  left: 0;
}

.ki-delivery-door .path1:before {
  content: "\eb3b";
  opacity: 0.3;
}

.ki-delivery-door .path2:before {
  content: "\eb3c";
  position: absolute;
  left: 0;
}

.ki-delivery-door .path3:before {
  content: "\eb3d";
  position: absolute;
  left: 0;
}

.ki-delivery-door .path4:before {
  content: "\eb3e";
  position: absolute;
  left: 0;
}

.ki-delivery-geolocation .path1:before {
  content: "\eb3f";
}

.ki-delivery-geolocation .path2:before {
  content: "\eb40";
  position: absolute;
  left: 0;
}

.ki-delivery-geolocation .path3:before {
  content: "\eb41";
  position: absolute;
  left: 0;
}

.ki-delivery-geolocation .path4:before {
  content: "\eb42";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery-geolocation .path5:before {
  content: "\eb43";
  position: absolute;
  left: 0;
}

.ki-delivery-time .path1:before {
  content: "\eb44";
}

.ki-delivery-time .path2:before {
  content: "\eb45";
  position: absolute;
  left: 0;
}

.ki-delivery-time .path3:before {
  content: "\eb46";
  position: absolute;
  left: 0;
}

.ki-delivery-time .path4:before {
  content: "\eb47";
  position: absolute;
  left: 0;
}

.ki-delivery-time .path5:before {
  content: "\eb48";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery .path1:before {
  content: "\eb49";
}

.ki-delivery .path2:before {
  content: "\eb4a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-delivery .path3:before {
  content: "\eb4b";
  position: absolute;
  left: 0;
}

.ki-delivery .path4:before {
  content: "\eb4c";
  position: absolute;
  left: 0;
}

.ki-delivery .path5:before {
  content: "\eb4d";
  position: absolute;
  left: 0;
}

.ki-design-2 .path1:before {
  content: "\eb4e";
  opacity: 0.3;
}

.ki-design-2 .path2:before {
  content: "\eb4f";
  position: absolute;
  left: 0;
}

.ki-design-frame .path1:before {
  content: "\eb50";
  opacity: 0.3;
}

.ki-design-frame .path2:before {
  content: "\eb51";
  position: absolute;
  left: 0;
}

.ki-design-mask .path1:before {
  content: "\eb52";
  opacity: 0.3;
}

.ki-design-mask .path2:before {
  content: "\eb53";
  position: absolute;
  left: 0;
}

.ki-design .path1:before {
  content: "\eb54";
  opacity: 0.3;
}

.ki-design .path2:before {
  content: "\eb55";
  position: absolute;
  left: 0;
}

.ki-devices-2 .path1:before {
  content: "\eb56";
  opacity: 0.3;
}

.ki-devices-2 .path2:before {
  content: "\eb57";
  position: absolute;
  left: 0;
}

.ki-devices-2 .path3:before {
  content: "\eb58";
  position: absolute;
  left: 0;
}

.ki-devices .path1:before {
  content: "\eb59";
  opacity: 0.3;
}

.ki-devices .path2:before {
  content: "\eb5a";
  position: absolute;
  left: 0;
}

.ki-devices .path3:before {
  content: "\eb5b";
  position: absolute;
  left: 0;
}

.ki-devices .path4:before {
  content: "\eb5c";
  position: absolute;
  left: 0;
}

.ki-devices .path5:before {
  content: "\eb5d";
  position: absolute;
  left: 0;
}

.ki-diamonds .path1:before {
  content: "\eb5e";
}

.ki-diamonds .path2:before {
  content: "\eb5f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-directbox-default .path1:before {
  content: "\eb60";
}

.ki-directbox-default .path2:before {
  content: "\eb61";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-directbox-default .path3:before {
  content: "\eb62";
  position: absolute;
  left: 0;
}

.ki-directbox-default .path4:before {
  content: "\eb63";
  position: absolute;
  left: 0;
}

.ki-disconnect .path1:before {
  content: "\eb64";
}

.ki-disconnect .path2:before {
  content: "\eb65";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-disconnect .path3:before {
  content: "\eb66";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-disconnect .path4:before {
  content: "\eb67";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-disconnect .path5:before {
  content: "\eb68";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-discount .path1:before {
  content: "\eb69";
  opacity: 0.3;
}

.ki-discount .path2:before {
  content: "\eb6a";
  position: absolute;
  left: 0;
}

.ki-disk .path1:before {
  content: "\eb6b";
  opacity: 0.3;
}

.ki-disk .path2:before {
  content: "\eb6c";
  position: absolute;
  left: 0;
}

.ki-dislike .path1:before {
  content: "\eb6d";
}

.ki-dislike .path2:before {
  content: "\eb6e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dj:before {
  content: "\eb6f";
}

.ki-document .path1:before {
  content: "\eb70";
  opacity: 0.3;
}

.ki-document .path2:before {
  content: "\eb71";
  position: absolute;
  left: 0;
}

.ki-dollar .path1:before {
  content: "\eb72";
  opacity: 0.3;
}

.ki-dollar .path2:before {
  content: "\eb73";
  position: absolute;
  left: 0;
}

.ki-dollar .path3:before {
  content: "\eb74";
  position: absolute;
  left: 0;
}

.ki-dots-circle-vertical .path1:before {
  content: "\eb75";
  opacity: 0.3;
}

.ki-dots-circle-vertical .path2:before {
  content: "\eb76";
  position: absolute;
  left: 0;
}

.ki-dots-circle-vertical .path3:before {
  content: "\eb77";
  position: absolute;
  left: 0;
}

.ki-dots-circle-vertical .path4:before {
  content: "\eb78";
  position: absolute;
  left: 0;
}

.ki-dots-circle .path1:before {
  content: "\eb79";
  opacity: 0.3;
}

.ki-dots-circle .path2:before {
  content: "\eb7a";
  position: absolute;
  left: 0;
}

.ki-dots-circle .path3:before {
  content: "\eb7b";
  position: absolute;
  left: 0;
}

.ki-dots-circle .path4:before {
  content: "\eb7c";
  position: absolute;
  left: 0;
}

.ki-dots-horizontal .path1:before {
  content: "\eb7d";
}

.ki-dots-horizontal .path2:before {
  content: "\eb7e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dots-horizontal .path3:before {
  content: "\eb7f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dots-square-vertical .path1:before {
  content: "\eb80";
  opacity: 0.3;
}

.ki-dots-square-vertical .path2:before {
  content: "\eb81";
  position: absolute;
  left: 0;
}

.ki-dots-square-vertical .path3:before {
  content: "\eb82";
  position: absolute;
  left: 0;
}

.ki-dots-square-vertical .path4:before {
  content: "\eb83";
  position: absolute;
  left: 0;
}

.ki-dots-square .path1:before {
  content: "\eb84";
  opacity: 0.3;
}

.ki-dots-square .path2:before {
  content: "\eb85";
  position: absolute;
  left: 0;
}

.ki-dots-square .path3:before {
  content: "\eb86";
  position: absolute;
  left: 0;
}

.ki-dots-square .path4:before {
  content: "\eb87";
  position: absolute;
  left: 0;
}

.ki-dots-vertical .path1:before {
  content: "\eb88";
}

.ki-dots-vertical .path2:before {
  content: "\eb89";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dots-vertical .path3:before {
  content: "\eb8a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-check-circle .path1:before {
  content: "\eb8b";
  opacity: 0.3;
}

.ki-double-check-circle .path2:before {
  content: "\eb8c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-check-circle .path3:before {
  content: "\eb8d";
  position: absolute;
  left: 0;
}

.ki-double-check .path1:before {
  content: "\eb8e";
}

.ki-double-check .path2:before {
  content: "\eb8f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-down .path1:before {
  content: "\eb90";
}

.ki-double-down .path2:before {
  content: "\eb91";
  position: absolute;
  left: 0;
}

.ki-double-down .path3:before {
  content: "\eb92";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-left-arrow .path1:before {
  content: "\eb93";
  opacity: 0.3;
}

.ki-double-left-arrow .path2:before {
  content: "\eb94";
  position: absolute;
  left: 0;
}

.ki-double-left .path1:before {
  content: "\eb95";
}

.ki-double-left .path2:before {
  content: "\eb96";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-right-arrow .path1:before {
  content: "\eb97";
  opacity: 0.3;
}

.ki-double-right-arrow .path2:before {
  content: "\eb98";
  position: absolute;
  left: 0;
}

.ki-double-right .path1:before {
  content: "\eb99";
}

.ki-double-right .path2:before {
  content: "\eb9a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-double-up .path1:before {
  content: "\eb9b";
}

.ki-double-up .path2:before {
  content: "\eb9c";
  position: absolute;
  left: 0;
}

.ki-double-up .path3:before {
  content: "\eb9d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-down-square .path1:before {
  content: "\eb9e";
  opacity: 0.3;
}

.ki-down-square .path2:before {
  content: "\eb9f";
  position: absolute;
  left: 0;
}

.ki-down:before {
  content: "\eba0";
}

.ki-dribbble .path1:before {
  content: "\eba1";
}

.ki-dribbble .path2:before {
  content: "\eba2";
  position: absolute;
  left: 0;
}

.ki-dribbble .path3:before {
  content: "\eba3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dribbble .path4:before {
  content: "\eba4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dribbble .path5:before {
  content: "\eba5";
  position: absolute;
  left: 0;
}

.ki-dribbble .path6:before {
  content: "\eba6";
  position: absolute;
  left: 0;
}

.ki-drop .path1:before {
  content: "\eba7";
}

.ki-drop .path2:before {
  content: "\eba8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-dropbox .path1:before {
  content: "\eba9";
  opacity: 0.4;
}

.ki-dropbox .path2:before {
  content: "\ebaa";
  position: absolute;
  left: 0;
  opacity: 0.4;
}

.ki-dropbox .path3:before {
  content: "\ebab";
  position: absolute;
  left: 0;
  opacity: 0.4;
}

.ki-dropbox .path4:before {
  content: "\ebac";
  position: absolute;
  left: 0;
  opacity: 0.4;
}

.ki-dropbox .path5:before {
  content: "\ebad";
  position: absolute;
  left: 0;
}

.ki-educare .path1:before {
  content: "\ebae";
  opacity: 0.3;
}

.ki-educare .path2:before {
  content: "\ebaf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-educare .path3:before {
  content: "\ebb0";
  position: absolute;
  left: 0;
}

.ki-educare .path4:before {
  content: "\ebb1";
  position: absolute;
  left: 0;
}

.ki-electricity .path1:before {
  content: "\ebb2";
}

.ki-electricity .path2:before {
  content: "\ebb3";
  position: absolute;
  left: 0;
}

.ki-electricity .path3:before {
  content: "\ebb4";
  position: absolute;
  left: 0;
}

.ki-electricity .path4:before {
  content: "\ebb5";
  position: absolute;
  left: 0;
}

.ki-electricity .path5:before {
  content: "\ebb6";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-electricity .path6:before {
  content: "\ebb7";
  position: absolute;
  left: 0;
}

.ki-electricity .path7:before {
  content: "\ebb8";
  position: absolute;
  left: 0;
}

.ki-electricity .path8:before {
  content: "\ebb9";
  position: absolute;
  left: 0;
}

.ki-electricity .path9:before {
  content: "\ebba";
  position: absolute;
  left: 0;
}

.ki-electricity .path10:before {
  content: "\ebbb";
  position: absolute;
  left: 0;
}

.ki-electronic-clock .path1:before {
  content: "\ebbc";
  opacity: 0.3;
}

.ki-electronic-clock .path2:before {
  content: "\ebbd";
  position: absolute;
  left: 0;
}

.ki-electronic-clock .path3:before {
  content: "\ebbe";
  position: absolute;
  left: 0;
}

.ki-electronic-clock .path4:before {
  content: "\ebbf";
  position: absolute;
  left: 0;
}

.ki-element-1 .path1:before {
  content: "\ebc0";
}

.ki-element-1 .path2:before {
  content: "\ebc1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-1 .path3:before {
  content: "\ebc2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-1 .path4:before {
  content: "\ebc3";
  position: absolute;
  left: 0;
}

.ki-element-2 .path1:before {
  content: "\ebc4";
}

.ki-element-2 .path2:before {
  content: "\ebc5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-3 .path1:before {
  content: "\ebc6";
  opacity: 0.3;
}

.ki-element-3 .path2:before {
  content: "\ebc7";
  position: absolute;
  left: 0;
}

.ki-element-4 .path1:before {
  content: "\ebc8";
}

.ki-element-4 .path2:before {
  content: "\ebc9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-5 .path1:before {
  content: "\ebca";
}

.ki-element-5 .path2:before {
  content: "\ebcb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-6 .path1:before {
  content: "\ebcc";
  opacity: 0.3;
}

.ki-element-6 .path2:before {
  content: "\ebcd";
  position: absolute;
  left: 0;
}

.ki-element-7 .path1:before {
  content: "\ebce";
}

.ki-element-7 .path2:before {
  content: "\ebcf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-8 .path1:before {
  content: "\ebd0";
  opacity: 0.3;
}

.ki-element-8 .path2:before {
  content: "\ebd1";
  position: absolute;
  left: 0;
}

.ki-element-9 .path1:before {
  content: "\ebd2";
  opacity: 0.3;
}

.ki-element-9 .path2:before {
  content: "\ebd3";
  position: absolute;
  left: 0;
}

.ki-element-10 .path1:before {
  content: "\ebd4";
}

.ki-element-10 .path2:before {
  content: "\ebd5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-10 .path3:before {
  content: "\ebd6";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-11 .path1:before {
  content: "\ebd7";
}

.ki-element-11 .path2:before {
  content: "\ebd8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-11 .path3:before {
  content: "\ebd9";
  position: absolute;
  left: 0;
}

.ki-element-11 .path4:before {
  content: "\ebda";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-12 .path1:before {
  content: "\ebdb";
  opacity: 0.3;
}

.ki-element-12 .path2:before {
  content: "\ebdc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-12 .path3:before {
  content: "\ebdd";
  position: absolute;
  left: 0;
}

.ki-element-equal .path1:before {
  content: "\ebde";
}

.ki-element-equal .path2:before {
  content: "\ebdf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-equal .path3:before {
  content: "\ebe0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-equal .path4:before {
  content: "\ebe1";
  position: absolute;
  left: 0;
}

.ki-element-equal .path5:before {
  content: "\ebe2";
  position: absolute;
  left: 0;
}

.ki-element-plus .path1:before {
  content: "\ebe3";
}

.ki-element-plus .path2:before {
  content: "\ebe4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-plus .path3:before {
  content: "\ebe5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-element-plus .path4:before {
  content: "\ebe6";
  position: absolute;
  left: 0;
}

.ki-element-plus .path5:before {
  content: "\ebe7";
  position: absolute;
  left: 0;
}

.ki-emoji-happy .path1:before {
  content: "\ebe8";
  opacity: 0.3;
}

.ki-emoji-happy .path2:before {
  content: "\ebe9";
  position: absolute;
  left: 0;
}

.ki-emoji-happy .path3:before {
  content: "\ebea";
  position: absolute;
  left: 0;
}

.ki-emoji-happy .path4:before {
  content: "\ebeb";
  position: absolute;
  left: 0;
}

.ki-enjin-coin .path1:before {
  content: "\ebec";
}

.ki-enjin-coin .path2:before {
  content: "\ebed";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-entrance-left .path1:before {
  content: "\ebee";
}

.ki-entrance-left .path2:before {
  content: "\ebef";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-entrance-right .path1:before {
  content: "\ebf0";
  opacity: 0.3;
}

.ki-entrance-right .path2:before {
  content: "\ebf1";
  position: absolute;
  left: 0;
}

.ki-eraser .path1:before {
  content: "\ebf2";
  opacity: 0.3;
}

.ki-eraser .path2:before {
  content: "\ebf3";
  position: absolute;
  left: 0;
}

.ki-eraser .path3:before {
  content: "\ebf4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-euro .path1:before {
  content: "\ebf5";
  opacity: 0.3;
}

.ki-euro .path2:before {
  content: "\ebf6";
  position: absolute;
  left: 0;
}

.ki-euro .path3:before {
  content: "\ebf7";
  position: absolute;
  left: 0;
}

.ki-exit-down .path1:before {
  content: "\ebf8";
  opacity: 0.3;
}

.ki-exit-down .path2:before {
  content: "\ebf9";
  position: absolute;
  left: 0;
}

.ki-exit-left .path1:before {
  content: "\ebfa";
  opacity: 0.3;
}

.ki-exit-left .path2:before {
  content: "\ebfb";
  position: absolute;
  left: 0;
}

.ki-exit-right-corner .path1:before {
  content: "\ebfc";
  opacity: 0.3;
}

.ki-exit-right-corner .path2:before {
  content: "\ebfd";
  position: absolute;
  left: 0;
}

.ki-exit-right .path1:before {
  content: "\ebfe";
  opacity: 0.3;
}

.ki-exit-right .path2:before {
  content: "\ebff";
  position: absolute;
  left: 0;
}

.ki-exit-up .path1:before {
  content: "\ec00";
  opacity: 0.3;
}

.ki-exit-up .path2:before {
  content: "\ec01";
  position: absolute;
  left: 0;
}

.ki-external-drive .path1:before {
  content: "\ec02";
}

.ki-external-drive .path2:before {
  content: "\ec03";
  position: absolute;
  left: 0;
}

.ki-external-drive .path3:before {
  content: "\ec04";
  position: absolute;
  left: 0;
}

.ki-external-drive .path4:before {
  content: "\ec05";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-external-drive .path5:before {
  content: "\ec06";
  position: absolute;
  left: 0;
}

.ki-eye-slash .path1:before {
  content: "\ec07";
}

.ki-eye-slash .path2:before {
  content: "\ec08";
  position: absolute;
  left: 0;
}

.ki-eye-slash .path3:before {
  content: "\ec09";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-eye-slash .path4:before {
  content: "\ec0a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-eye .path1:before {
  content: "\ec0b";
}

.ki-eye .path2:before {
  content: "\ec0c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-eye .path3:before {
  content: "\ec0d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-facebook .path1:before {
  content: "\ec0e";
  opacity: 0.3;
}

.ki-facebook .path2:before {
  content: "\ec0f";
  position: absolute;
  left: 0;
}

.ki-faceid .path1:before {
  content: "\ec10";
  opacity: 0.3;
}

.ki-faceid .path2:before {
  content: "\ec11";
  position: absolute;
  left: 0;
}

.ki-faceid .path3:before {
  content: "\ec12";
  position: absolute;
  left: 0;
}

.ki-faceid .path4:before {
  content: "\ec13";
  position: absolute;
  left: 0;
}

.ki-faceid .path5:before {
  content: "\ec14";
  position: absolute;
  left: 0;
}

.ki-faceid .path6:before {
  content: "\ec15";
  position: absolute;
  left: 0;
}

.ki-fasten .path1:before {
  content: "\ec16";
  opacity: 0.3;
}

.ki-fasten .path2:before {
  content: "\ec17";
  position: absolute;
  left: 0;
}

.ki-fat-rows .path1:before {
  content: "\ec18";
}

.ki-fat-rows .path2:before {
  content: "\ec19";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-feather .path1:before {
  content: "\ec1a";
  opacity: 0.3;
}

.ki-feather .path2:before {
  content: "\ec1b";
  position: absolute;
  left: 0;
}

.ki-figma .path1:before {
  content: "\ec1c";
  opacity: 0.4;
}

.ki-figma .path2:before {
  content: "\ec1d";
  position: absolute;
  left: 0;
  opacity: 0.4;
}

.ki-figma .path3:before {
  content: "\ec1e";
  position: absolute;
  left: 0;
}

.ki-figma .path4:before {
  content: "\ec1f";
  position: absolute;
  left: 0;
}

.ki-figma .path5:before {
  content: "\ec20";
  position: absolute;
  left: 0;
}

.ki-file-added .path1:before {
  content: "\ec21";
}

.ki-file-added .path2:before {
  content: "\ec22";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-file-deleted .path1:before {
  content: "\ec23";
}

.ki-file-deleted .path2:before {
  content: "\ec24";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-file-down .path1:before {
  content: "\ec25";
  opacity: 0.3;
}

.ki-file-down .path2:before {
  content: "\ec26";
  position: absolute;
  left: 0;
}

.ki-file-left .path1:before {
  content: "\ec27";
  opacity: 0.3;
}

.ki-file-left .path2:before {
  content: "\ec28";
  position: absolute;
  left: 0;
}

.ki-file-right .path1:before {
  content: "\ec29";
  opacity: 0.3;
}

.ki-file-right .path2:before {
  content: "\ec2a";
  position: absolute;
  left: 0;
}

.ki-file-sheet .path1:before {
  content: "\ec2b";
}

.ki-file-sheet .path2:before {
  content: "\ec2c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-file-up .path1:before {
  content: "\ec2d";
  opacity: 0.3;
}

.ki-file-up .path2:before {
  content: "\ec2e";
  position: absolute;
  left: 0;
}

.ki-file .path1:before {
  content: "\ec2f";
}

.ki-file .path2:before {
  content: "\ec30";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-files-tablet .path1:before {
  content: "\ec31";
}

.ki-files-tablet .path2:before {
  content: "\ec32";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-filter-edit .path1:before {
  content: "\ec33";
  opacity: 0.3;
}

.ki-filter-edit .path2:before {
  content: "\ec34";
  position: absolute;
  left: 0;
}

.ki-filter-search .path1:before {
  content: "\ec35";
  opacity: 0.3;
}

.ki-filter-search .path2:before {
  content: "\ec36";
  position: absolute;
  left: 0;
}

.ki-filter-search .path3:before {
  content: "\ec37";
  position: absolute;
  left: 0;
}

.ki-filter-square .path1:before {
  content: "\ec38";
}

.ki-filter-square .path2:before {
  content: "\ec39";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-filter-tablet .path1:before {
  content: "\ec3a";
  opacity: 0.3;
}

.ki-filter-tablet .path2:before {
  content: "\ec3b";
  position: absolute;
  left: 0;
}

.ki-filter-tick .path1:before {
  content: "\ec3c";
  opacity: 0.3;
}

.ki-filter-tick .path2:before {
  content: "\ec3d";
  position: absolute;
  left: 0;
}

.ki-filter .path1:before {
  content: "\ec3e";
}

.ki-filter .path2:before {
  content: "\ec3f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-finance-calculator .path1:before {
  content: "\ec40";
  opacity: 0.3;
}

.ki-finance-calculator .path2:before {
  content: "\ec41";
  position: absolute;
  left: 0;
}

.ki-finance-calculator .path3:before {
  content: "\ec42";
  position: absolute;
  left: 0;
}

.ki-finance-calculator .path4:before {
  content: "\ec43";
  position: absolute;
  left: 0;
}

.ki-finance-calculator .path5:before {
  content: "\ec44";
  position: absolute;
  left: 0;
}

.ki-finance-calculator .path6:before {
  content: "\ec45";
  position: absolute;
  left: 0;
}

.ki-finance-calculator .path7:before {
  content: "\ec46";
  position: absolute;
  left: 0;
}

.ki-financial-schedule .path1:before {
  content: "\ec47";
  opacity: 0.3;
}

.ki-financial-schedule .path2:before {
  content: "\ec48";
  position: absolute;
  left: 0;
}

.ki-financial-schedule .path3:before {
  content: "\ec49";
  position: absolute;
  left: 0;
}

.ki-financial-schedule .path4:before {
  content: "\ec4a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-fingerprint-scanning .path1:before {
  content: "\ec4b";
}

.ki-fingerprint-scanning .path2:before {
  content: "\ec4c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-fingerprint-scanning .path3:before {
  content: "\ec4d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-fingerprint-scanning .path4:before {
  content: "\ec4e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-fingerprint-scanning .path5:before {
  content: "\ec4f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-flag .path1:before {
  content: "\ec50";
  opacity: 0.3;
}

.ki-flag .path2:before {
  content: "\ec51";
  position: absolute;
  left: 0;
}

.ki-flash-circle .path1:before {
  content: "\ec52";
  opacity: 0.3;
}

.ki-flash-circle .path2:before {
  content: "\ec53";
  position: absolute;
  left: 0;
}

.ki-flask .path1:before {
  content: "\ec54";
  opacity: 0.3;
}

.ki-flask .path2:before {
  content: "\ec55";
  position: absolute;
  left: 0;
}

.ki-focus .path1:before {
  content: "\ec56";
  opacity: 0.3;
}

.ki-focus .path2:before {
  content: "\ec57";
  position: absolute;
  left: 0;
}

.ki-folder-added .path1:before {
  content: "\ec58";
  opacity: 0.3;
}

.ki-folder-added .path2:before {
  content: "\ec59";
  position: absolute;
  left: 0;
}

.ki-folder-down .path1:before {
  content: "\ec5a";
  opacity: 0.3;
}

.ki-folder-down .path2:before {
  content: "\ec5b";
  position: absolute;
  left: 0;
}

.ki-folder-up .path1:before {
  content: "\ec5c";
  opacity: 0.3;
}

.ki-folder-up .path2:before {
  content: "\ec5d";
  position: absolute;
  left: 0;
}

.ki-folder .path1:before {
  content: "\ec5e";
  opacity: 0.3;
}

.ki-folder .path2:before {
  content: "\ec5f";
  position: absolute;
  left: 0;
}

.ki-frame .path1:before {
  content: "\ec60";
}

.ki-frame .path2:before {
  content: "\ec61";
  position: absolute;
  left: 0;
}

.ki-frame .path3:before {
  content: "\ec62";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-frame .path4:before {
  content: "\ec63";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-gear .path1:before {
  content: "\ec64";
  opacity: 0.3;
}

.ki-gear .path2:before {
  content: "\ec65";
  position: absolute;
  left: 0;
}

.ki-general-mouse .path1:before {
  content: "\ec66";
  opacity: 0.3;
}

.ki-general-mouse .path2:before {
  content: "\ec67";
  position: absolute;
  left: 0;
}

.ki-geolocation-home .path1:before {
  content: "\ec68";
  opacity: 0.3;
}

.ki-geolocation-home .path2:before {
  content: "\ec69";
  position: absolute;
  left: 0;
}

.ki-geolocation .path1:before {
  content: "\ec6a";
  opacity: 0.3;
}

.ki-geolocation .path2:before {
  content: "\ec6b";
  position: absolute;
  left: 0;
}

.ki-ghost .path1:before {
  content: "\ec6c";
}

.ki-ghost .path2:before {
  content: "\ec6d";
  position: absolute;
  left: 0;
}

.ki-ghost .path3:before {
  content: "\ec6e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-gift .path1:before {
  content: "\ec6f";
  opacity: 0.3;
}

.ki-gift .path2:before {
  content: "\ec70";
  position: absolute;
  left: 0;
}

.ki-gift .path3:before {
  content: "\ec71";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-gift .path4:before {
  content: "\ec72";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-github .path1:before {
  content: "\ec73";
  opacity: 0.3;
}

.ki-github .path2:before {
  content: "\ec74";
  position: absolute;
  left: 0;
}

.ki-glass .path1:before {
  content: "\ec75";
}

.ki-glass .path2:before {
  content: "\ec76";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-glass .path3:before {
  content: "\ec77";
  position: absolute;
  left: 0;
}

.ki-google-play .path1:before {
  content: "\ec78";
  opacity: 0.3;
}

.ki-google-play .path2:before {
  content: "\ec79";
  position: absolute;
  left: 0;
}

.ki-google .path1:before {
  content: "\ec7a";
  opacity: 0.3;
}

.ki-google .path2:before {
  content: "\ec7b";
  position: absolute;
  left: 0;
}

.ki-graph-2 .path1:before {
  content: "\ec7c";
  opacity: 0.3;
}

.ki-graph-2 .path2:before {
  content: "\ec7d";
  position: absolute;
  left: 0;
}

.ki-graph-2 .path3:before {
  content: "\ec7e";
  position: absolute;
  left: 0;
}

.ki-graph-3 .path1:before {
  content: "\ec7f";
  opacity: 0.3;
}

.ki-graph-3 .path2:before {
  content: "\ec80";
  position: absolute;
  left: 0;
}

.ki-graph-4 .path1:before {
  content: "\ec81";
}

.ki-graph-4 .path2:before {
  content: "\ec82";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-graph-up .path1:before {
  content: "\ec83";
  opacity: 0.3;
}

.ki-graph-up .path2:before {
  content: "\ec84";
  position: absolute;
  left: 0;
}

.ki-graph-up .path3:before {
  content: "\ec85";
  position: absolute;
  left: 0;
}

.ki-graph-up .path4:before {
  content: "\ec86";
  position: absolute;
  left: 0;
}

.ki-graph-up .path5:before {
  content: "\ec87";
  position: absolute;
  left: 0;
}

.ki-graph-up .path6:before {
  content: "\ec88";
  position: absolute;
  left: 0;
}

.ki-graph .path1:before {
  content: "\ec89";
  opacity: 0.3;
}

.ki-graph .path2:before {
  content: "\ec8a";
  position: absolute;
  left: 0;
}

.ki-graph .path3:before {
  content: "\ec8b";
  position: absolute;
  left: 0;
}

.ki-graph .path4:before {
  content: "\ec8c";
  position: absolute;
  left: 0;
}

.ki-grid-2 .path1:before {
  content: "\ec8d";
  opacity: 0.3;
}

.ki-grid-2 .path2:before {
  content: "\ec8e";
  position: absolute;
  left: 0;
}

.ki-grid-frame .path1:before {
  content: "\ec8f";
}

.ki-grid-frame .path2:before {
  content: "\ec90";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-grid-frame .path3:before {
  content: "\ec91";
  position: absolute;
  left: 0;
}

.ki-grid .path1:before {
  content: "\ec92";
  opacity: 0.3;
}

.ki-grid .path2:before {
  content: "\ec93";
  position: absolute;
  left: 0;
}

.ki-handcart:before {
  content: "\ec94";
}

.ki-happy-emoji .path1:before {
  content: "\ec95";
  opacity: 0.3;
}

.ki-happy-emoji .path2:before {
  content: "\ec96";
  position: absolute;
  left: 0;
}

.ki-heart-circle .path1:before {
  content: "\ec97";
  opacity: 0.3;
}

.ki-heart-circle .path2:before {
  content: "\ec98";
  position: absolute;
  left: 0;
}

.ki-heart .path1:before {
  content: "\ec99";
  opacity: 0.3;
}

.ki-heart .path2:before {
  content: "\ec9a";
  position: absolute;
  left: 0;
}

.ki-home-1 .path1:before {
  content: "\ec9b";
  opacity: 0.3;
}

.ki-home-1 .path2:before {
  content: "\ec9c";
  position: absolute;
  left: 0;
}

.ki-home-2 .path1:before {
  content: "\ec9d";
  opacity: 0.3;
}

.ki-home-2 .path2:before {
  content: "\ec9e";
  position: absolute;
  left: 0;
}

.ki-home-3 .path1:before {
  content: "\ec9f";
  opacity: 0.3;
}

.ki-home-3 .path2:before {
  content: "\eca0";
  position: absolute;
  left: 0;
}

.ki-home:before {
  content: "\eca1";
}

.ki-html .path1:before {
  content: "\eca2";
  opacity: 0.3;
}

.ki-html .path2:before {
  content: "\eca3";
  position: absolute;
  left: 0;
}

.ki-icon .path1:before {
  content: "\eca4";
  opacity: 0.3;
}

.ki-icon .path2:before {
  content: "\eca5";
  position: absolute;
  left: 0;
}

.ki-icon .path3:before {
  content: "\eca6";
  position: absolute;
  left: 0;
}

.ki-illustrator .path1:before {
  content: "\eca7";
  opacity: 0.3;
}

.ki-illustrator .path2:before {
  content: "\eca8";
  position: absolute;
  left: 0;
}

.ki-illustrator .path3:before {
  content: "\eca9";
  position: absolute;
  left: 0;
}

.ki-illustrator .path4:before {
  content: "\ecaa";
  position: absolute;
  left: 0;
}

.ki-information-2 .path1:before {
  content: "\ecab";
  opacity: 0.3;
}

.ki-information-2 .path2:before {
  content: "\ecac";
  position: absolute;
  left: 0;
}

.ki-information-2 .path3:before {
  content: "\ecad";
  position: absolute;
  left: 0;
}

.ki-information-3 .path1:before {
  content: "\ecae";
  opacity: 0.3;
}

.ki-information-3 .path2:before {
  content: "\ecaf";
  position: absolute;
  left: 0;
}

.ki-information-3 .path3:before {
  content: "\ecb0";
  position: absolute;
  left: 0;
}

.ki-information-4 .path1:before {
  content: "\ecb1";
  opacity: 0.3;
}

.ki-information-4 .path2:before {
  content: "\ecb2";
  position: absolute;
  left: 0;
}

.ki-information-4 .path3:before {
  content: "\ecb3";
  position: absolute;
  left: 0;
}

.ki-information-5 .path1:before {
  content: "\ecb4";
  opacity: 0.3;
}

.ki-information-5 .path2:before {
  content: "\ecb5";
  position: absolute;
  left: 0;
}

.ki-information-5 .path3:before {
  content: "\ecb6";
  position: absolute;
  left: 0;
}

.ki-information .path1:before {
  content: "\ecb7";
  opacity: 0.3;
}

.ki-information .path2:before {
  content: "\ecb8";
  position: absolute;
  left: 0;
}

.ki-information .path3:before {
  content: "\ecb9";
  position: absolute;
  left: 0;
}

.ki-instagram .path1:before {
  content: "\ecba";
  opacity: 0.3;
}

.ki-instagram .path2:before {
  content: "\ecbb";
  position: absolute;
  left: 0;
}

.ki-joystick .path1:before {
  content: "\ecbc";
}

.ki-joystick .path2:before {
  content: "\ecbd";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-joystick .path3:before {
  content: "\ecbe";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-joystick .path4:before {
  content: "\ecbf";
  position: absolute;
  left: 0;
}

.ki-js-2 .path1:before {
  content: "\ecc0";
}

.ki-js-2 .path2:before {
  content: "\ecc1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-js .path1:before {
  content: "\ecc2";
}

.ki-js .path2:before {
  content: "\ecc3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-kanban .path1:before {
  content: "\ecc4";
}

.ki-kanban .path2:before {
  content: "\ecc5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-key-square .path1:before {
  content: "\ecc6";
  opacity: 0.3;
}

.ki-key-square .path2:before {
  content: "\ecc7";
  position: absolute;
  left: 0;
}

.ki-key .path1:before {
  content: "\ecc8";
  opacity: 0.3;
}

.ki-key .path2:before {
  content: "\ecc9";
  position: absolute;
  left: 0;
}

.ki-keyboard .path1:before {
  content: "\ecca";
}

.ki-keyboard .path2:before {
  content: "\eccb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-laptop .path1:before {
  content: "\eccc";
  opacity: 0.3;
}

.ki-laptop .path2:before {
  content: "\eccd";
  position: absolute;
  left: 0;
}

.ki-laravel .path1:before {
  content: "\ecce";
  opacity: 0.3;
}

.ki-laravel .path2:before {
  content: "\eccf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-laravel .path3:before {
  content: "\ecd0";
  position: absolute;
  left: 0;
}

.ki-laravel .path4:before {
  content: "\ecd1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-laravel .path5:before {
  content: "\ecd2";
  position: absolute;
  left: 0;
}

.ki-laravel .path6:before {
  content: "\ecd3";
  position: absolute;
  left: 0;
}

.ki-laravel .path7:before {
  content: "\ecd4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-left-square .path1:before {
  content: "\ecd5";
  opacity: 0.3;
}

.ki-left-square .path2:before {
  content: "\ecd6";
  position: absolute;
  left: 0;
}

.ki-left:before {
  content: "\ecd7";
}

.ki-like-2 .path1:before {
  content: "\ecd8";
  opacity: 0.3;
}

.ki-like-2 .path2:before {
  content: "\ecd9";
  position: absolute;
  left: 0;
}

.ki-like-folder .path1:before {
  content: "\ecda";
  opacity: 0.3;
}

.ki-like-folder .path2:before {
  content: "\ecdb";
  position: absolute;
  left: 0;
}

.ki-like-shapes .path1:before {
  content: "\ecdc";
  opacity: 0.3;
}

.ki-like-shapes .path2:before {
  content: "\ecdd";
  position: absolute;
  left: 0;
}

.ki-like-tag .path1:before {
  content: "\ecde";
  opacity: 0.3;
}

.ki-like-tag .path2:before {
  content: "\ecdf";
  position: absolute;
  left: 0;
}

.ki-like .path1:before {
  content: "\ece0";
}

.ki-like .path2:before {
  content: "\ece1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-loading .path1:before {
  content: "\ece2";
}

.ki-loading .path2:before {
  content: "\ece3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-lock-2 .path1:before {
  content: "\ece4";
}

.ki-lock-2 .path2:before {
  content: "\ece5";
  position: absolute;
  left: 0;
}

.ki-lock-2 .path3:before {
  content: "\ece6";
  position: absolute;
  left: 0;
}

.ki-lock-2 .path4:before {
  content: "\ece7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-lock-2 .path5:before {
  content: "\ece8";
  position: absolute;
  left: 0;
}

.ki-lock-3 .path1:before {
  content: "\ece9";
  opacity: 0.3;
}

.ki-lock-3 .path2:before {
  content: "\ecea";
  position: absolute;
  left: 0;
}

.ki-lock-3 .path3:before {
  content: "\eceb";
  position: absolute;
  left: 0;
}

.ki-lock .path1:before {
  content: "\ecec";
  opacity: 0.3;
}

.ki-lock .path2:before {
  content: "\eced";
  position: absolute;
  left: 0;
}

.ki-lock .path3:before {
  content: "\ecee";
  position: absolute;
  left: 0;
}

.ki-logistic .path1:before {
  content: "\ecef";
  opacity: 0.3;
}

.ki-logistic .path2:before {
  content: "\ecf0";
  position: absolute;
  left: 0;
}

.ki-logistic .path3:before {
  content: "\ecf1";
  position: absolute;
  left: 0;
}

.ki-logistic .path4:before {
  content: "\ecf2";
  position: absolute;
  left: 0;
}

.ki-logistic .path5:before {
  content: "\ecf3";
  position: absolute;
  left: 0;
}

.ki-logistic .path6:before {
  content: "\ecf4";
  position: absolute;
  left: 0;
}

.ki-logistic .path7:before {
  content: "\ecf5";
  position: absolute;
  left: 0;
}

.ki-lots-shopping .path1:before {
  content: "\ecf6";
  opacity: 0.3;
}

.ki-lots-shopping .path2:before {
  content: "\ecf7";
  position: absolute;
  left: 0;
}

.ki-lots-shopping .path3:before {
  content: "\ecf8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-lots-shopping .path4:before {
  content: "\ecf9";
  position: absolute;
  left: 0;
}

.ki-lots-shopping .path5:before {
  content: "\ecfa";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-lots-shopping .path6:before {
  content: "\ecfb";
  position: absolute;
  left: 0;
}

.ki-lots-shopping .path7:before {
  content: "\ecfc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-lots-shopping .path8:before {
  content: "\ecfd";
  position: absolute;
  left: 0;
}

.ki-lovely .path1:before {
  content: "\ecfe";
  opacity: 0.3;
}

.ki-lovely .path2:before {
  content: "\ecff";
  position: absolute;
  left: 0;
}

.ki-lts .path1:before {
  content: "\ed00";
}

.ki-lts .path2:before {
  content: "\ed01";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-magnifier .path1:before {
  content: "\ed02";
  opacity: 0.3;
}

.ki-magnifier .path2:before {
  content: "\ed03";
  position: absolute;
  left: 0;
}

.ki-map .path1:before {
  content: "\ed04";
  opacity: 0.3;
}

.ki-map .path2:before {
  content: "\ed05";
  position: absolute;
  left: 0;
}

.ki-map .path3:before {
  content: "\ed06";
  position: absolute;
  left: 0;
}

.ki-mask .path1:before {
  content: "\ed07";
  opacity: 0.3;
}

.ki-mask .path2:before {
  content: "\ed08";
  position: absolute;
  left: 0;
}

.ki-mask .path3:before {
  content: "\ed09";
  position: absolute;
  left: 0;
}

.ki-maximize .path1:before {
  content: "\ed0a";
  opacity: 0.3;
}

.ki-maximize .path2:before {
  content: "\ed0b";
  position: absolute;
  left: 0;
}

.ki-maximize .path3:before {
  content: "\ed0c";
  position: absolute;
  left: 0;
}

.ki-maximize .path4:before {
  content: "\ed0d";
  position: absolute;
  left: 0;
}

.ki-maximize .path5:before {
  content: "\ed0e";
  position: absolute;
  left: 0;
}

.ki-medal-star .path1:before {
  content: "\ed0f";
}

.ki-medal-star .path2:before {
  content: "\ed10";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-medal-star .path3:before {
  content: "\ed11";
  position: absolute;
  left: 0;
}

.ki-medal-star .path4:before {
  content: "\ed12";
  position: absolute;
  left: 0;
}

.ki-menu .path1:before {
  content: "\ed13";
  opacity: 0.3;
}

.ki-menu .path2:before {
  content: "\ed14";
  position: absolute;
  left: 0;
}

.ki-menu .path3:before {
  content: "\ed15";
  position: absolute;
  left: 0;
}

.ki-menu .path4:before {
  content: "\ed16";
  position: absolute;
  left: 0;
}

.ki-message-add .path1:before {
  content: "\ed17";
  opacity: 0.3;
}

.ki-message-add .path2:before {
  content: "\ed18";
  position: absolute;
  left: 0;
}

.ki-message-add .path3:before {
  content: "\ed19";
  position: absolute;
  left: 0;
}

.ki-message-edit .path1:before {
  content: "\ed1a";
  opacity: 0.3;
}

.ki-message-edit .path2:before {
  content: "\ed1b";
  position: absolute;
  left: 0;
}

.ki-message-minus .path1:before {
  content: "\ed1c";
  opacity: 0.3;
}

.ki-message-minus .path2:before {
  content: "\ed1d";
  position: absolute;
  left: 0;
}

.ki-message-notif .path1:before {
  content: "\ed1e";
}

.ki-message-notif .path2:before {
  content: "\ed1f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-message-notif .path3:before {
  content: "\ed20";
  position: absolute;
  left: 0;
}

.ki-message-notif .path4:before {
  content: "\ed21";
  position: absolute;
  left: 0;
}

.ki-message-notif .path5:before {
  content: "\ed22";
  position: absolute;
  left: 0;
}

.ki-message-programming .path1:before {
  content: "\ed23";
  opacity: 0.3;
}

.ki-message-programming .path2:before {
  content: "\ed24";
  position: absolute;
  left: 0;
}

.ki-message-programming .path3:before {
  content: "\ed25";
  position: absolute;
  left: 0;
}

.ki-message-programming .path4:before {
  content: "\ed26";
  position: absolute;
  left: 0;
}

.ki-message-question .path1:before {
  content: "\ed27";
  opacity: 0.3;
}

.ki-message-question .path2:before {
  content: "\ed28";
  position: absolute;
  left: 0;
}

.ki-message-question .path3:before {
  content: "\ed29";
  position: absolute;
  left: 0;
}

.ki-message-text-2 .path1:before {
  content: "\ed2a";
  opacity: 0.3;
}

.ki-message-text-2 .path2:before {
  content: "\ed2b";
  position: absolute;
  left: 0;
}

.ki-message-text-2 .path3:before {
  content: "\ed2c";
  position: absolute;
  left: 0;
}

.ki-message-text .path1:before {
  content: "\ed2d";
  opacity: 0.3;
}

.ki-message-text .path2:before {
  content: "\ed2e";
  position: absolute;
  left: 0;
}

.ki-message-text .path3:before {
  content: "\ed2f";
  position: absolute;
  left: 0;
}

.ki-messages .path1:before {
  content: "\ed30";
}

.ki-messages .path2:before {
  content: "\ed31";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-messages .path3:before {
  content: "\ed32";
  position: absolute;
  left: 0;
}

.ki-messages .path4:before {
  content: "\ed33";
  position: absolute;
  left: 0;
}

.ki-messages .path5:before {
  content: "\ed34";
  position: absolute;
  left: 0;
}

.ki-microsoft .path1:before {
  content: "\ed35";
  opacity: 0.3;
}

.ki-microsoft .path2:before {
  content: "\ed36";
  position: absolute;
  left: 0;
}

.ki-microsoft .path3:before {
  content: "\ed37";
  position: absolute;
  left: 0;
}

.ki-microsoft .path4:before {
  content: "\ed38";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-milk .path1:before {
  content: "\ed39";
  opacity: 0.3;
}

.ki-milk .path2:before {
  content: "\ed3a";
  position: absolute;
  left: 0;
}

.ki-milk .path3:before {
  content: "\ed3b";
  position: absolute;
  left: 0;
}

.ki-minus-circle .path1:before {
  content: "\ed3c";
  opacity: 0.3;
}

.ki-minus-circle .path2:before {
  content: "\ed3d";
  position: absolute;
  left: 0;
}

.ki-minus-folder .path1:before {
  content: "\ed3e";
  opacity: 0.3;
}

.ki-minus-folder .path2:before {
  content: "\ed3f";
  position: absolute;
  left: 0;
}

.ki-minus-square .path1:before {
  content: "\ed40";
  opacity: 0.3;
}

.ki-minus-square .path2:before {
  content: "\ed41";
  position: absolute;
  left: 0;
}

.ki-minus:before {
  content: "\ed42";
}

.ki-monitor-mobile .path1:before {
  content: "\ed43";
  opacity: 0.3;
}

.ki-monitor-mobile .path2:before {
  content: "\ed44";
  position: absolute;
  left: 0;
}

.ki-moon .path1:before {
  content: "\ed45";
}

.ki-moon .path2:before {
  content: "\ed46";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-more-2 .path1:before {
  content: "\ed47";
  opacity: 0.3;
}

.ki-more-2 .path2:before {
  content: "\ed48";
  position: absolute;
  left: 0;
}

.ki-more-2 .path3:before {
  content: "\ed49";
  position: absolute;
  left: 0;
}

.ki-more-2 .path4:before {
  content: "\ed4a";
  position: absolute;
  left: 0;
}

.ki-mouse-circle .path1:before {
  content: "\ed4b";
}

.ki-mouse-circle .path2:before {
  content: "\ed4c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-mouse-square .path1:before {
  content: "\ed4d";
  opacity: 0.3;
}

.ki-mouse-square .path2:before {
  content: "\ed4e";
  position: absolute;
  left: 0;
}

.ki-mouse .path1:before {
  content: "\ed4f";
  opacity: 0.3;
}

.ki-mouse .path2:before {
  content: "\ed50";
  position: absolute;
  left: 0;
}

.ki-nexo .path1:before {
  content: "\ed51";
  opacity: 0.3;
}

.ki-nexo .path2:before {
  content: "\ed52";
  position: absolute;
  left: 0;
}

.ki-night-day .path1:before {
  content: "\ed53";
}

.ki-night-day .path2:before {
  content: "\ed54";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-night-day .path3:before {
  content: "\ed55";
  position: absolute;
  left: 0;
}

.ki-night-day .path4:before {
  content: "\ed56";
  position: absolute;
  left: 0;
}

.ki-night-day .path5:before {
  content: "\ed57";
  position: absolute;
  left: 0;
}

.ki-night-day .path6:before {
  content: "\ed58";
  position: absolute;
  left: 0;
}

.ki-night-day .path7:before {
  content: "\ed59";
  position: absolute;
  left: 0;
}

.ki-night-day .path8:before {
  content: "\ed5a";
  position: absolute;
  left: 0;
}

.ki-night-day .path9:before {
  content: "\ed5b";
  position: absolute;
  left: 0;
}

.ki-night-day .path10:before {
  content: "\ed5c";
  position: absolute;
  left: 0;
}

.ki-note-2 .path1:before {
  content: "\ed5d";
  opacity: 0.3;
}

.ki-note-2 .path2:before {
  content: "\ed5e";
  position: absolute;
  left: 0;
}

.ki-note-2 .path3:before {
  content: "\ed5f";
  position: absolute;
  left: 0;
}

.ki-note-2 .path4:before {
  content: "\ed60";
  position: absolute;
  left: 0;
}

.ki-note .path1:before {
  content: "\ed61";
  opacity: 0.3;
}

.ki-note .path2:before {
  content: "\ed62";
  position: absolute;
  left: 0;
}

.ki-notepad-bookmark .path1:before {
  content: "\ed63";
}

.ki-notepad-bookmark .path2:before {
  content: "\ed64";
  position: absolute;
  left: 0;
}

.ki-notepad-bookmark .path3:before {
  content: "\ed65";
  position: absolute;
  left: 0;
}

.ki-notepad-bookmark .path4:before {
  content: "\ed66";
  position: absolute;
  left: 0;
}

.ki-notepad-bookmark .path5:before {
  content: "\ed67";
  position: absolute;
  left: 0;
}

.ki-notepad-bookmark .path6:before {
  content: "\ed68";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notepad-edit .path1:before {
  content: "\ed69";
  opacity: 0.3;
}

.ki-notepad-edit .path2:before {
  content: "\ed6a";
  position: absolute;
  left: 0;
}

.ki-notepad .path1:before {
  content: "\ed6b";
}

.ki-notepad .path2:before {
  content: "\ed6c";
  position: absolute;
  left: 0;
}

.ki-notepad .path3:before {
  content: "\ed6d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notepad .path4:before {
  content: "\ed6e";
  position: absolute;
  left: 0;
}

.ki-notepad .path5:before {
  content: "\ed6f";
  position: absolute;
  left: 0;
}

.ki-notification-2 .path1:before {
  content: "\ed70";
}

.ki-notification-2 .path2:before {
  content: "\ed71";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notification-bing .path1:before {
  content: "\ed72";
}

.ki-notification-bing .path2:before {
  content: "\ed73";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notification-bing .path3:before {
  content: "\ed74";
  position: absolute;
  left: 0;
}

.ki-notification-circle .path1:before {
  content: "\ed75";
}

.ki-notification-circle .path2:before {
  content: "\ed76";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notification-favorite .path1:before {
  content: "\ed77";
}

.ki-notification-favorite .path2:before {
  content: "\ed78";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notification-favorite .path3:before {
  content: "\ed79";
  position: absolute;
  left: 0;
}

.ki-notification-on .path1:before {
  content: "\ed7a";
  opacity: 0.3;
}

.ki-notification-on .path2:before {
  content: "\ed7b";
  position: absolute;
  left: 0;
}

.ki-notification-on .path3:before {
  content: "\ed7c";
  position: absolute;
  left: 0;
}

.ki-notification-on .path4:before {
  content: "\ed7d";
  position: absolute;
  left: 0;
}

.ki-notification-on .path5:before {
  content: "\ed7e";
  position: absolute;
  left: 0;
}

.ki-notification-status .path1:before {
  content: "\ed7f";
}

.ki-notification-status .path2:before {
  content: "\ed80";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-notification-status .path3:before {
  content: "\ed81";
  position: absolute;
  left: 0;
}

.ki-notification-status .path4:before {
  content: "\ed82";
  position: absolute;
  left: 0;
}

.ki-notification .path1:before {
  content: "\ed83";
  opacity: 0.3;
}

.ki-notification .path2:before {
  content: "\ed84";
  position: absolute;
  left: 0;
}

.ki-notification .path3:before {
  content: "\ed85";
  position: absolute;
  left: 0;
}

.ki-ocean .path1:before {
  content: "\ed86";
}

.ki-ocean .path2:before {
  content: "\ed87";
  position: absolute;
  left: 0;
}

.ki-ocean .path3:before {
  content: "\ed88";
  position: absolute;
  left: 0;
}

.ki-ocean .path4:before {
  content: "\ed89";
  position: absolute;
  left: 0;
}

.ki-ocean .path5:before {
  content: "\ed8a";
  position: absolute;
  left: 0;
}

.ki-ocean .path6:before {
  content: "\ed8b";
  position: absolute;
  left: 0;
}

.ki-ocean .path7:before {
  content: "\ed8c";
  position: absolute;
  left: 0;
}

.ki-ocean .path8:before {
  content: "\ed8d";
  position: absolute;
  left: 0;
}

.ki-ocean .path9:before {
  content: "\ed8e";
  position: absolute;
  left: 0;
}

.ki-ocean .path10:before {
  content: "\ed8f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path11:before {
  content: "\ed90";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path12:before {
  content: "\ed91";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path13:before {
  content: "\ed92";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path14:before {
  content: "\ed93";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path15:before {
  content: "\ed94";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path16:before {
  content: "\ed95";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path17:before {
  content: "\ed96";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path18:before {
  content: "\ed97";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ocean .path19:before {
  content: "\ed98";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-office-bag .path1:before {
  content: "\ed99";
  opacity: 0.3;
}

.ki-office-bag .path2:before {
  content: "\ed9a";
  position: absolute;
  left: 0;
}

.ki-office-bag .path3:before {
  content: "\ed9b";
  position: absolute;
  left: 0;
}

.ki-office-bag .path4:before {
  content: "\ed9c";
  position: absolute;
  left: 0;
}

.ki-package .path1:before {
  content: "\ed9d";
  opacity: 0.3;
}

.ki-package .path2:before {
  content: "\ed9e";
  position: absolute;
  left: 0;
}

.ki-package .path3:before {
  content: "\ed9f";
  position: absolute;
  left: 0;
}

.ki-pails .path1:before {
  content: "\eda0";
  opacity: 0.3;
}

.ki-pails .path2:before {
  content: "\eda1";
  position: absolute;
  left: 0;
}

.ki-pails .path3:before {
  content: "\eda2";
  position: absolute;
  left: 0;
}

.ki-pails .path4:before {
  content: "\eda3";
  position: absolute;
  left: 0;
}

.ki-pails .path5:before {
  content: "\eda4";
  position: absolute;
  left: 0;
}

.ki-pails .path6:before {
  content: "\eda5";
  position: absolute;
  left: 0;
}

.ki-pails .path7:before {
  content: "\eda6";
  position: absolute;
  left: 0;
}

.ki-pails .path8:before {
  content: "\eda7";
  position: absolute;
  left: 0;
}

.ki-pails .path9:before {
  content: "\eda8";
  position: absolute;
  left: 0;
}

.ki-paintbucket .path1:before {
  content: "\eda9";
  opacity: 0.3;
}

.ki-paintbucket .path2:before {
  content: "\edaa";
  position: absolute;
  left: 0;
}

.ki-paintbucket .path3:before {
  content: "\edab";
  position: absolute;
  left: 0;
}

.ki-paper-clip:before {
  content: "\edac";
}

.ki-parcel-tracking .path1:before {
  content: "\edad";
}

.ki-parcel-tracking .path2:before {
  content: "\edae";
  position: absolute;
  left: 0;
}

.ki-parcel-tracking .path3:before {
  content: "\edaf";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-parcel .path1:before {
  content: "\edb0";
  opacity: 0.3;
}

.ki-parcel .path2:before {
  content: "\edb1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-parcel .path3:before {
  content: "\edb2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-parcel .path4:before {
  content: "\edb3";
  position: absolute;
  left: 0;
}

.ki-parcel .path5:before {
  content: "\edb4";
  position: absolute;
  left: 0;
}

.ki-password-check .path1:before {
  content: "\edb5";
}

.ki-password-check .path2:before {
  content: "\edb6";
  position: absolute;
  left: 0;
}

.ki-password-check .path3:before {
  content: "\edb7";
  position: absolute;
  left: 0;
}

.ki-password-check .path4:before {
  content: "\edb8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-password-check .path5:before {
  content: "\edb9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-paypal .path1:before {
  content: "\edba";
}

.ki-paypal .path2:before {
  content: "\edbb";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-pencil .path1:before {
  content: "\edbc";
  opacity: 0.3;
}

.ki-pencil .path2:before {
  content: "\edbd";
  position: absolute;
  left: 0;
}

.ki-people .path1:before {
  content: "\edbe";
}

.ki-people .path2:before {
  content: "\edbf";
  position: absolute;
  left: 0;
}

.ki-people .path3:before {
  content: "\edc0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-people .path4:before {
  content: "\edc1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-people .path5:before {
  content: "\edc2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-percentage .path1:before {
  content: "\edc3";
}

.ki-percentage .path2:before {
  content: "\edc4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-phone .path1:before {
  content: "\edc5";
  opacity: 0.3;
}

.ki-phone .path2:before {
  content: "\edc6";
  position: absolute;
  left: 0;
}

.ki-photoshop .path1:before {
  content: "\edc7";
  opacity: 0.3;
}

.ki-photoshop .path2:before {
  content: "\edc8";
  position: absolute;
  left: 0;
}

.ki-picture .path1:before {
  content: "\edc9";
  opacity: 0.3;
}

.ki-picture .path2:before {
  content: "\edca";
  position: absolute;
  left: 0;
}

.ki-pill:before {
  content: "\edcb";
}

.ki-pin .path1:before {
  content: "\edcc";
  opacity: 0.3;
}

.ki-pin .path2:before {
  content: "\edcd";
  position: absolute;
  left: 0;
}

.ki-plus-circle .path1:before {
  content: "\edce";
  opacity: 0.3;
}

.ki-plus-circle .path2:before {
  content: "\edcf";
  position: absolute;
  left: 0;
}

.ki-plus-square .path1:before {
  content: "\edd0";
  opacity: 0.3;
}

.ki-plus-square .path2:before {
  content: "\edd1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-plus-square .path3:before {
  content: "\edd2";
  position: absolute;
  left: 0;
}

.ki-plus:before {
  content: "\edd3";
}

.ki-pointers .path1:before {
  content: "\edd4";
  opacity: 0.3;
}

.ki-pointers .path2:before {
  content: "\edd5";
  position: absolute;
  left: 0;
}

.ki-pointers .path3:before {
  content: "\edd6";
  position: absolute;
  left: 0;
}

.ki-price-tag .path1:before {
  content: "\edd7";
  opacity: 0.3;
}

.ki-price-tag .path2:before {
  content: "\edd8";
  position: absolute;
  left: 0;
}

.ki-price-tag .path3:before {
  content: "\edd9";
  position: absolute;
  left: 0;
}

.ki-printer .path1:before {
  content: "\edda";
  opacity: 0.3;
}

.ki-printer .path2:before {
  content: "\eddb";
  position: absolute;
  left: 0;
}

.ki-printer .path3:before {
  content: "\eddc";
  position: absolute;
  left: 0;
}

.ki-printer .path4:before {
  content: "\eddd";
  position: absolute;
  left: 0;
}

.ki-printer .path5:before {
  content: "\edde";
  position: absolute;
  left: 0;
}

.ki-profile-circle .path1:before {
  content: "\eddf";
  opacity: 0.3;
}

.ki-profile-circle .path2:before {
  content: "\ede0";
  position: absolute;
  left: 0;
}

.ki-profile-circle .path3:before {
  content: "\ede1";
  position: absolute;
  left: 0;
}

.ki-profile-user .path1:before {
  content: "\ede2";
  opacity: 0.3;
}

.ki-profile-user .path2:before {
  content: "\ede3";
  position: absolute;
  left: 0;
}

.ki-profile-user .path3:before {
  content: "\ede4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-profile-user .path4:before {
  content: "\ede5";
  position: absolute;
  left: 0;
}

.ki-pulse .path1:before {
  content: "\ede6";
  opacity: 0.3;
}

.ki-pulse .path2:before {
  content: "\ede7";
  position: absolute;
  left: 0;
}

.ki-purchase .path1:before {
  content: "\ede8";
  opacity: 0.3;
}

.ki-purchase .path2:before {
  content: "\ede9";
  position: absolute;
  left: 0;
}

.ki-python .path1:before {
  content: "\edea";
  opacity: 0.3;
}

.ki-python .path2:before {
  content: "\edeb";
  position: absolute;
  left: 0;
}

.ki-question-2 .path1:before {
  content: "\edec";
  opacity: 0.3;
}

.ki-question-2 .path2:before {
  content: "\eded";
  position: absolute;
  left: 0;
}

.ki-question-2 .path3:before {
  content: "\edee";
  position: absolute;
  left: 0;
}

.ki-question .path1:before {
  content: "\edef";
  opacity: 0.3;
}

.ki-question .path2:before {
  content: "\edf0";
  position: absolute;
  left: 0;
}

.ki-question .path3:before {
  content: "\edf1";
  position: absolute;
  left: 0;
}

.ki-questionnaire-tablet .path1:before {
  content: "\edf2";
  opacity: 0.3;
}

.ki-questionnaire-tablet .path2:before {
  content: "\edf3";
  position: absolute;
  left: 0;
}

.ki-ranking .path1:before {
  content: "\edf4";
  opacity: 0.3;
}

.ki-ranking .path2:before {
  content: "\edf5";
  position: absolute;
  left: 0;
}

.ki-ranking .path3:before {
  content: "\edf6";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ranking .path4:before {
  content: "\edf7";
  position: absolute;
  left: 0;
}

.ki-react .path1:before {
  content: "\edf8";
  opacity: 0.3;
}

.ki-react .path2:before {
  content: "\edf9";
  position: absolute;
  left: 0;
}

.ki-receipt-square .path1:before {
  content: "\edfa";
  opacity: 0.3;
}

.ki-receipt-square .path2:before {
  content: "\edfb";
  position: absolute;
  left: 0;
}

.ki-rescue .path1:before {
  content: "\edfc";
  opacity: 0.3;
}

.ki-rescue .path2:before {
  content: "\edfd";
  position: absolute;
  left: 0;
}

.ki-right-left .path1:before {
  content: "\edfe";
}

.ki-right-left .path2:before {
  content: "\edff";
  position: absolute;
  left: 0;
}

.ki-right-left .path3:before {
  content: "\ee00";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-right-square .path1:before {
  content: "\ee01";
  opacity: 0.3;
}

.ki-right-square .path2:before {
  content: "\ee02";
  position: absolute;
  left: 0;
}

.ki-right:before {
  content: "\ee03";
}

.ki-rocket .path1:before {
  content: "\ee04";
  opacity: 0.3;
}

.ki-rocket .path2:before {
  content: "\ee05";
  position: absolute;
  left: 0;
}

.ki-route .path1:before {
  content: "\ee06";
}

.ki-route .path2:before {
  content: "\ee07";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-route .path3:before {
  content: "\ee08";
  position: absolute;
  left: 0;
}

.ki-route .path4:before {
  content: "\ee09";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-router .path1:before {
  content: "\ee0a";
  opacity: 0.3;
}

.ki-router .path2:before {
  content: "\ee0b";
  position: absolute;
  left: 0;
}

.ki-row-horizontal .path1:before {
  content: "\ee0c";
}

.ki-row-horizontal .path2:before {
  content: "\ee0d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-row-vertical .path1:before {
  content: "\ee0e";
}

.ki-row-vertical .path2:before {
  content: "\ee0f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-safe-home .path1:before {
  content: "\ee10";
  opacity: 0.3;
}

.ki-safe-home .path2:before {
  content: "\ee11";
  position: absolute;
  left: 0;
}

.ki-satellite .path1:before {
  content: "\ee12";
  opacity: 0.3;
}

.ki-satellite .path2:before {
  content: "\ee13";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-satellite .path3:before {
  content: "\ee14";
  position: absolute;
  left: 0;
}

.ki-satellite .path4:before {
  content: "\ee15";
  position: absolute;
  left: 0;
}

.ki-satellite .path5:before {
  content: "\ee16";
  position: absolute;
  left: 0;
}

.ki-satellite .path6:before {
  content: "\ee17";
  position: absolute;
  left: 0;
}

.ki-save-2 .path1:before {
  content: "\ee18";
  opacity: 0.3;
}

.ki-save-2 .path2:before {
  content: "\ee19";
  position: absolute;
  left: 0;
}

.ki-save-deposit .path1:before {
  content: "\ee1a";
}

.ki-save-deposit .path2:before {
  content: "\ee1b";
  position: absolute;
  left: 0;
}

.ki-save-deposit .path3:before {
  content: "\ee1c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-save-deposit .path4:before {
  content: "\ee1d";
  position: absolute;
  left: 0;
}

.ki-scan-barcode .path1:before {
  content: "\ee1e";
}

.ki-scan-barcode .path2:before {
  content: "\ee1f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-scan-barcode .path3:before {
  content: "\ee20";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-scan-barcode .path4:before {
  content: "\ee21";
  position: absolute;
  left: 0;
}

.ki-scan-barcode .path5:before {
  content: "\ee22";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-scan-barcode .path6:before {
  content: "\ee23";
  position: absolute;
  left: 0;
}

.ki-scan-barcode .path7:before {
  content: "\ee24";
  position: absolute;
  left: 0;
}

.ki-scan-barcode .path8:before {
  content: "\ee25";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-scooter-2:before {
  content: "\ee26";
}

.ki-scooter .path1:before {
  content: "\ee27";
}

.ki-scooter .path2:before {
  content: "\ee28";
  position: absolute;
  left: 0;
}

.ki-scooter .path3:before {
  content: "\ee29";
  position: absolute;
  left: 0;
}

.ki-scooter .path4:before {
  content: "\ee2a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-scooter .path5:before {
  content: "\ee2b";
  position: absolute;
  left: 0;
}

.ki-scooter .path6:before {
  content: "\ee2c";
  position: absolute;
  left: 0;
}

.ki-scooter .path7:before {
  content: "\ee2d";
  position: absolute;
  left: 0;
}

.ki-screen .path1:before {
  content: "\ee2e";
  opacity: 0.3;
}

.ki-screen .path2:before {
  content: "\ee2f";
  position: absolute;
  left: 0;
}

.ki-screen .path3:before {
  content: "\ee30";
  position: absolute;
  left: 0;
}

.ki-screen .path4:before {
  content: "\ee31";
  position: absolute;
  left: 0;
}

.ki-scroll .path1:before {
  content: "\ee32";
  opacity: 0.3;
}

.ki-scroll .path2:before {
  content: "\ee33";
  position: absolute;
  left: 0;
}

.ki-scroll .path3:before {
  content: "\ee34";
  position: absolute;
  left: 0;
}

.ki-search-list .path1:before {
  content: "\ee35";
  opacity: 0.3;
}

.ki-search-list .path2:before {
  content: "\ee36";
  position: absolute;
  left: 0;
}

.ki-search-list .path3:before {
  content: "\ee37";
  position: absolute;
  left: 0;
}

.ki-security-check .path1:before {
  content: "\ee38";
}

.ki-security-check .path2:before {
  content: "\ee39";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-security-check .path3:before {
  content: "\ee3a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-security-check .path4:before {
  content: "\ee3b";
  position: absolute;
  left: 0;
}

.ki-security-user .path1:before {
  content: "\ee3c";
  opacity: 0.3;
}

.ki-security-user .path2:before {
  content: "\ee3d";
  position: absolute;
  left: 0;
}

.ki-send .path1:before {
  content: "\ee3e";
}

.ki-send .path2:before {
  content: "\ee3f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-setting-2 .path1:before {
  content: "\ee40";
  opacity: 0.3;
}

.ki-setting-2 .path2:before {
  content: "\ee41";
  position: absolute;
  left: 0;
}

.ki-setting-3 .path1:before {
  content: "\ee42";
  opacity: 0.3;
}

.ki-setting-3 .path2:before {
  content: "\ee43";
  position: absolute;
  left: 0;
}

.ki-setting-3 .path3:before {
  content: "\ee44";
  position: absolute;
  left: 0;
}

.ki-setting-3 .path4:before {
  content: "\ee45";
  position: absolute;
  left: 0;
}

.ki-setting-3 .path5:before {
  content: "\ee46";
  position: absolute;
  left: 0;
}

.ki-setting-4:before {
  content: "\ee47";
}

.ki-setting .path1:before {
  content: "\ee48";
  opacity: 0.3;
}

.ki-setting .path2:before {
  content: "\ee49";
  position: absolute;
  left: 0;
}

.ki-share .path1:before {
  content: "\ee4a";
  opacity: 0.3;
}

.ki-share .path2:before {
  content: "\ee4b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-share .path3:before {
  content: "\ee4c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-share .path4:before {
  content: "\ee4d";
  position: absolute;
  left: 0;
}

.ki-share .path5:before {
  content: "\ee4e";
  position: absolute;
  left: 0;
}

.ki-share .path6:before {
  content: "\ee4f";
  position: absolute;
  left: 0;
}

.ki-shield-cross .path1:before {
  content: "\ee50";
  opacity: 0.3;
}

.ki-shield-cross .path2:before {
  content: "\ee51";
  position: absolute;
  left: 0;
}

.ki-shield-cross .path3:before {
  content: "\ee52";
  position: absolute;
  left: 0;
}

.ki-shield-search .path1:before {
  content: "\ee53";
  opacity: 0.3;
}

.ki-shield-search .path2:before {
  content: "\ee54";
  position: absolute;
  left: 0;
}

.ki-shield-search .path3:before {
  content: "\ee55";
  position: absolute;
  left: 0;
}

.ki-shield-slash .path1:before {
  content: "\ee56";
}

.ki-shield-slash .path2:before {
  content: "\ee57";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-shield-slash .path3:before {
  content: "\ee58";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-shield-tick .path1:before {
  content: "\ee59";
  opacity: 0.3;
}

.ki-shield-tick .path2:before {
  content: "\ee5a";
  position: absolute;
  left: 0;
}

.ki-shield .path1:before {
  content: "\ee5b";
  opacity: 0.3;
}

.ki-shield .path2:before {
  content: "\ee5c";
  position: absolute;
  left: 0;
}

.ki-ship .path1:before {
  content: "\ee5d";
  opacity: 0.3;
}

.ki-ship .path2:before {
  content: "\ee5e";
  position: absolute;
  left: 0;
}

.ki-ship .path3:before {
  content: "\ee5f";
  position: absolute;
  left: 0;
}

.ki-shop .path1:before {
  content: "\ee60";
}

.ki-shop .path2:before {
  content: "\ee61";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-shop .path3:before {
  content: "\ee62";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-shop .path4:before {
  content: "\ee63";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-shop .path5:before {
  content: "\ee64";
  position: absolute;
  left: 0;
}

.ki-simcard-2 .path1:before {
  content: "\ee65";
  opacity: 0.3;
}

.ki-simcard-2 .path2:before {
  content: "\ee66";
  position: absolute;
  left: 0;
}

.ki-simcard .path1:before {
  content: "\ee67";
  opacity: 0.3;
}

.ki-simcard .path2:before {
  content: "\ee68";
  position: absolute;
  left: 0;
}

.ki-simcard .path3:before {
  content: "\ee69";
  position: absolute;
  left: 0;
}

.ki-simcard .path4:before {
  content: "\ee6a";
  position: absolute;
  left: 0;
}

.ki-simcard .path5:before {
  content: "\ee6b";
  position: absolute;
  left: 0;
}

.ki-size .path1:before {
  content: "\ee6c";
  opacity: 0.3;
}

.ki-size .path2:before {
  content: "\ee6d";
  position: absolute;
  left: 0;
}

.ki-slack .path1:before {
  content: "\ee6e";
}

.ki-slack .path2:before {
  content: "\ee6f";
  position: absolute;
  left: 0;
}

.ki-slack .path3:before {
  content: "\ee70";
  position: absolute;
  left: 0;
}

.ki-slack .path4:before {
  content: "\ee71";
  position: absolute;
  left: 0;
}

.ki-slack .path5:before {
  content: "\ee72";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slack .path6:before {
  content: "\ee73";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slack .path7:before {
  content: "\ee74";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slack .path8:before {
  content: "\ee75";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slider-horizontal-2 .path1:before {
  content: "\ee76";
}

.ki-slider-horizontal-2 .path2:before {
  content: "\ee77";
  position: absolute;
  left: 0;
}

.ki-slider-horizontal-2 .path3:before {
  content: "\ee78";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slider-horizontal .path1:before {
  content: "\ee79";
  opacity: 0.3;
}

.ki-slider-horizontal .path2:before {
  content: "\ee7a";
  position: absolute;
  left: 0;
}

.ki-slider-horizontal .path3:before {
  content: "\ee7b";
  position: absolute;
  left: 0;
}

.ki-slider-vertical-2 .path1:before {
  content: "\ee7c";
  opacity: 0.3;
}

.ki-slider-vertical-2 .path2:before {
  content: "\ee7d";
  position: absolute;
  left: 0;
}

.ki-slider-vertical-2 .path3:before {
  content: "\ee7e";
  position: absolute;
  left: 0;
}

.ki-slider-vertical .path1:before {
  content: "\ee7f";
}

.ki-slider-vertical .path2:before {
  content: "\ee80";
  position: absolute;
  left: 0;
}

.ki-slider-vertical .path3:before {
  content: "\ee81";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-slider .path1:before {
  content: "\ee82";
  opacity: 0.3;
}

.ki-slider .path2:before {
  content: "\ee83";
  position: absolute;
  left: 0;
}

.ki-slider .path3:before {
  content: "\ee84";
  position: absolute;
  left: 0;
}

.ki-slider .path4:before {
  content: "\ee85";
  position: absolute;
  left: 0;
}

.ki-sms .path1:before {
  content: "\ee86";
  opacity: 0.3;
}

.ki-sms .path2:before {
  content: "\ee87";
  position: absolute;
  left: 0;
}

.ki-snapchat .path1:before {
  content: "\ee88";
}

.ki-snapchat .path2:before {
  content: "\ee89";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-social-media .path1:before {
  content: "\ee8a";
}

.ki-social-media .path2:before {
  content: "\ee8b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-soft-2 .path1:before {
  content: "\ee8c";
  opacity: 0.3;
}

.ki-soft-2 .path2:before {
  content: "\ee8d";
  position: absolute;
  left: 0;
}

.ki-soft-3 .path1:before {
  content: "\ee8e";
}

.ki-soft-3 .path2:before {
  content: "\ee8f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-soft .path1:before {
  content: "\ee90";
  opacity: 0.3;
}

.ki-soft .path2:before {
  content: "\ee91";
  position: absolute;
  left: 0;
}

.ki-soft .path3:before {
  content: "\ee92";
  position: absolute;
  left: 0;
}

.ki-soft .path4:before {
  content: "\ee93";
  position: absolute;
  left: 0;
}

.ki-soft .path5:before {
  content: "\ee94";
  position: absolute;
  left: 0;
}

.ki-soft .path6:before {
  content: "\ee95";
  position: absolute;
  left: 0;
}

.ki-some-files .path1:before {
  content: "\ee96";
  opacity: 0.3;
}

.ki-some-files .path2:before {
  content: "\ee97";
  position: absolute;
  left: 0;
}

.ki-sort .path1:before {
  content: "\ee98";
  opacity: 0.3;
}

.ki-sort .path2:before {
  content: "\ee99";
  position: absolute;
  left: 0;
}

.ki-sort .path3:before {
  content: "\ee9a";
  position: absolute;
  left: 0;
}

.ki-sort .path4:before {
  content: "\ee9b";
  position: absolute;
  left: 0;
}

.ki-speaker .path1:before {
  content: "\ee9c";
  opacity: 0.3;
}

.ki-speaker .path2:before {
  content: "\ee9d";
  position: absolute;
  left: 0;
}

.ki-speaker .path3:before {
  content: "\ee9e";
  position: absolute;
  left: 0;
}

.ki-spotify .path1:before {
  content: "\ee9f";
}

.ki-spotify .path2:before {
  content: "\eea0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-spring-framework:before {
  content: "\eea1";
}

.ki-square-brackets .path1:before {
  content: "\eea2";
  opacity: 0.3;
}

.ki-square-brackets .path2:before {
  content: "\eea3";
  position: absolute;
  left: 0;
}

.ki-square-brackets .path3:before {
  content: "\eea4";
  position: absolute;
  left: 0;
}

.ki-square-brackets .path4:before {
  content: "\eea5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-star:before {
  content: "\eea6";
}

.ki-status .path1:before {
  content: "\eea7";
  opacity: 0.3;
}

.ki-status .path2:before {
  content: "\eea8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-status .path3:before {
  content: "\eea9";
  position: absolute;
  left: 0;
}

.ki-subtitle .path1:before {
  content: "\eeaa";
  opacity: 0.3;
}

.ki-subtitle .path2:before {
  content: "\eeab";
  position: absolute;
  left: 0;
}

.ki-subtitle .path3:before {
  content: "\eeac";
  position: absolute;
  left: 0;
}

.ki-subtitle .path4:before {
  content: "\eead";
  position: absolute;
  left: 0;
}

.ki-subtitle .path5:before {
  content: "\eeae";
  position: absolute;
  left: 0;
}

.ki-sun .path1:before {
  content: "\eeaf";
}

.ki-sun .path2:before {
  content: "\eeb0";
  position: absolute;
  left: 0;
}

.ki-sun .path3:before {
  content: "\eeb1";
  position: absolute;
  left: 0;
}

.ki-sun .path4:before {
  content: "\eeb2";
  position: absolute;
  left: 0;
}

.ki-sun .path5:before {
  content: "\eeb3";
  position: absolute;
  left: 0;
}

.ki-sun .path6:before {
  content: "\eeb4";
  position: absolute;
  left: 0;
}

.ki-sun .path7:before {
  content: "\eeb5";
  position: absolute;
  left: 0;
}

.ki-sun .path8:before {
  content: "\eeb6";
  position: absolute;
  left: 0;
}

.ki-sun .path9:before {
  content: "\eeb7";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-support-24 .path1:before {
  content: "\eeb8";
  opacity: 0.3;
}

.ki-support-24 .path2:before {
  content: "\eeb9";
  position: absolute;
  left: 0;
}

.ki-support-24 .path3:before {
  content: "\eeba";
  position: absolute;
  left: 0;
}

.ki-switch .path1:before {
  content: "\eebb";
}

.ki-switch .path2:before {
  content: "\eebc";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-syringe .path1:before {
  content: "\eebd";
  opacity: 0.3;
}

.ki-syringe .path2:before {
  content: "\eebe";
  position: absolute;
  left: 0;
}

.ki-syringe .path3:before {
  content: "\eebf";
  position: absolute;
  left: 0;
}

.ki-tablet-book .path1:before {
  content: "\eec0";
  opacity: 0.3;
}

.ki-tablet-book .path2:before {
  content: "\eec1";
  position: absolute;
  left: 0;
}

.ki-tablet-delete .path1:before {
  content: "\eec2";
}

.ki-tablet-delete .path2:before {
  content: "\eec3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-tablet-delete .path3:before {
  content: "\eec4";
  position: absolute;
  left: 0;
}

.ki-tablet-down .path1:before {
  content: "\eec5";
  opacity: 0.3;
}

.ki-tablet-down .path2:before {
  content: "\eec6";
  position: absolute;
  left: 0;
}

.ki-tablet-down .path3:before {
  content: "\eec7";
  position: absolute;
  left: 0;
}

.ki-tablet-ok .path1:before {
  content: "\eec8";
}

.ki-tablet-ok .path2:before {
  content: "\eec9";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-tablet-ok .path3:before {
  content: "\eeca";
  position: absolute;
  left: 0;
}

.ki-tablet-text-down .path1:before {
  content: "\eecb";
}

.ki-tablet-text-down .path2:before {
  content: "\eecc";
  position: absolute;
  left: 0;
}

.ki-tablet-text-down .path3:before {
  content: "\eecd";
  position: absolute;
  left: 0;
}

.ki-tablet-text-down .path4:before {
  content: "\eece";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-tablet-text-up .path1:before {
  content: "\eecf";
}

.ki-tablet-text-up .path2:before {
  content: "\eed0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-tablet-up .path1:before {
  content: "\eed1";
  opacity: 0.3;
}

.ki-tablet-up .path2:before {
  content: "\eed2";
  position: absolute;
  left: 0;
}

.ki-tablet-up .path3:before {
  content: "\eed3";
  position: absolute;
  left: 0;
}

.ki-tablet .path1:before {
  content: "\eed4";
  opacity: 0.3;
}

.ki-tablet .path2:before {
  content: "\eed5";
  position: absolute;
  left: 0;
}

.ki-tablet .path3:before {
  content: "\eed6";
  position: absolute;
  left: 0;
}

.ki-tag-cross .path1:before {
  content: "\eed7";
  opacity: 0.3;
}

.ki-tag-cross .path2:before {
  content: "\eed8";
  position: absolute;
  left: 0;
}

.ki-tag-cross .path3:before {
  content: "\eed9";
  position: absolute;
  left: 0;
}

.ki-tag .path1:before {
  content: "\eeda";
  opacity: 0.3;
}

.ki-tag .path2:before {
  content: "\eedb";
  position: absolute;
  left: 0;
}

.ki-tag .path3:before {
  content: "\eedc";
  position: absolute;
  left: 0;
}

.ki-teacher .path1:before {
  content: "\eedd";
  opacity: 0.3;
}

.ki-teacher .path2:before {
  content: "\eede";
  position: absolute;
  left: 0;
}

.ki-tech-wifi .path1:before {
  content: "\eedf";
  opacity: 0.3;
}

.ki-tech-wifi .path2:before {
  content: "\eee0";
  position: absolute;
  left: 0;
}

.ki-technology-2 .path1:before {
  content: "\eee1";
  opacity: 0.3;
}

.ki-technology-2 .path2:before {
  content: "\eee2";
  position: absolute;
  left: 0;
}

.ki-technology-3 .path1:before {
  content: "\eee3";
}

.ki-technology-3 .path2:before {
  content: "\eee4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology-3 .path3:before {
  content: "\eee5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology-3 .path4:before {
  content: "\eee6";
  position: absolute;
  left: 0;
}

.ki-technology-4 .path1:before {
  content: "\eee7";
}

.ki-technology-4 .path2:before {
  content: "\eee8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology-4 .path3:before {
  content: "\eee9";
  position: absolute;
  left: 0;
}

.ki-technology-4 .path4:before {
  content: "\eeea";
  position: absolute;
  left: 0;
}

.ki-technology-4 .path5:before {
  content: "\eeeb";
  position: absolute;
  left: 0;
}

.ki-technology-4 .path6:before {
  content: "\eeec";
  position: absolute;
  left: 0;
}

.ki-technology-4 .path7:before {
  content: "\eeed";
  position: absolute;
  left: 0;
}

.ki-technology .path1:before {
  content: "\eeee";
}

.ki-technology .path2:before {
  content: "\eeef";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path3:before {
  content: "\eef0";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path4:before {
  content: "\eef1";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path5:before {
  content: "\eef2";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path6:before {
  content: "\eef3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path7:before {
  content: "\eef4";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path8:before {
  content: "\eef5";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-technology .path9:before {
  content: "\eef6";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-telephone-geolocation .path1:before {
  content: "\eef7";
}

.ki-telephone-geolocation .path2:before {
  content: "\eef8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-telephone-geolocation .path3:before {
  content: "\eef9";
  position: absolute;
  left: 0;
}

.ki-test-tubes .path1:before {
  content: "\eefa";
  opacity: 0.3;
}

.ki-test-tubes .path2:before {
  content: "\eefb";
  position: absolute;
  left: 0;
}

.ki-text-align-center .path1:before {
  content: "\eefc";
}

.ki-text-align-center .path2:before {
  content: "\eefd";
  position: absolute;
  left: 0;
}

.ki-text-align-center .path3:before {
  content: "\eefe";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-center .path4:before {
  content: "\eeff";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-justify-center .path1:before {
  content: "\ef00";
}

.ki-text-align-justify-center .path2:before {
  content: "\ef01";
  position: absolute;
  left: 0;
}

.ki-text-align-justify-center .path3:before {
  content: "\ef02";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-justify-center .path4:before {
  content: "\ef03";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-left .path1:before {
  content: "\ef04";
}

.ki-text-align-left .path2:before {
  content: "\ef05";
  position: absolute;
  left: 0;
}

.ki-text-align-left .path3:before {
  content: "\ef06";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-left .path4:before {
  content: "\ef07";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-right .path1:before {
  content: "\ef08";
}

.ki-text-align-right .path2:before {
  content: "\ef09";
  position: absolute;
  left: 0;
}

.ki-text-align-right .path3:before {
  content: "\ef0a";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-align-right .path4:before {
  content: "\ef0b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-bold .path1:before {
  content: "\ef0c";
  opacity: 0.3;
}

.ki-text-bold .path2:before {
  content: "\ef0d";
  position: absolute;
  left: 0;
}

.ki-text-bold .path3:before {
  content: "\ef0e";
  position: absolute;
  left: 0;
}

.ki-text-circle .path1:before {
  content: "\ef0f";
}

.ki-text-circle .path2:before {
  content: "\ef10";
  position: absolute;
  left: 0;
}

.ki-text-circle .path3:before {
  content: "\ef11";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-circle .path4:before {
  content: "\ef12";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-circle .path5:before {
  content: "\ef13";
  position: absolute;
  left: 0;
}

.ki-text-circle .path6:before {
  content: "\ef14";
  position: absolute;
  left: 0;
}

.ki-text-italic .path1:before {
  content: "\ef15";
  opacity: 0.3;
}

.ki-text-italic .path2:before {
  content: "\ef16";
  position: absolute;
  left: 0;
}

.ki-text-italic .path3:before {
  content: "\ef17";
  position: absolute;
  left: 0;
}

.ki-text-italic .path4:before {
  content: "\ef18";
  position: absolute;
  left: 0;
}

.ki-text-number .path1:before {
  content: "\ef19";
}

.ki-text-number .path2:before {
  content: "\ef1a";
  position: absolute;
  left: 0;
}

.ki-text-number .path3:before {
  content: "\ef1b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-number .path4:before {
  content: "\ef1c";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-number .path5:before {
  content: "\ef1d";
  position: absolute;
  left: 0;
}

.ki-text-number .path6:before {
  content: "\ef1e";
  position: absolute;
  left: 0;
}

.ki-text-strikethrough .path1:before {
  content: "\ef1f";
  opacity: 0.3;
}

.ki-text-strikethrough .path2:before {
  content: "\ef20";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-text-strikethrough .path3:before {
  content: "\ef21";
  position: absolute;
  left: 0;
}

.ki-text-underline .path1:before {
  content: "\ef22";
  opacity: 0.3;
}

.ki-text-underline .path2:before {
  content: "\ef23";
  position: absolute;
  left: 0;
}

.ki-text-underline .path3:before {
  content: "\ef24";
  position: absolute;
  left: 0;
}

.ki-text:before {
  content: "\ef25";
}

.ki-thermometer .path1:before {
  content: "\ef26";
  opacity: 0.3;
}

.ki-thermometer .path2:before {
  content: "\ef27";
  position: absolute;
  left: 0;
}

.ki-theta .path1:before {
  content: "\ef28";
}

.ki-theta .path2:before {
  content: "\ef29";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-tiktok .path1:before {
  content: "\ef2a";
  opacity: 0.3;
}

.ki-tiktok .path2:before {
  content: "\ef2b";
  position: absolute;
  left: 0;
}

.ki-time .path1:before {
  content: "\ef2c";
  opacity: 0.3;
}

.ki-time .path2:before {
  content: "\ef2d";
  position: absolute;
  left: 0;
}

.ki-timer .path1:before {
  content: "\ef2e";
  opacity: 0.3;
}

.ki-timer .path2:before {
  content: "\ef2f";
  position: absolute;
  left: 0;
}

.ki-timer .path3:before {
  content: "\ef30";
  position: absolute;
  left: 0;
}

.ki-to-left:before {
  content: "\ef31";
}

.ki-to-right:before {
  content: "\ef32";
}

.ki-toggle-off-circle .path1:before {
  content: "\ef33";
  opacity: 0.3;
}

.ki-toggle-off-circle .path2:before {
  content: "\ef34";
  position: absolute;
  left: 0;
}

.ki-toggle-off .path1:before {
  content: "\ef35";
  opacity: 0.3;
}

.ki-toggle-off .path2:before {
  content: "\ef36";
  position: absolute;
  left: 0;
}

.ki-toggle-on-circle .path1:before {
  content: "\ef37";
  opacity: 0.3;
}

.ki-toggle-on-circle .path2:before {
  content: "\ef38";
  position: absolute;
  left: 0;
}

.ki-toggle-on .path1:before {
  content: "\ef39";
  opacity: 0.3;
}

.ki-toggle-on .path2:before {
  content: "\ef3a";
  position: absolute;
  left: 0;
}

.ki-trailer .path1:before {
  content: "\ef3b";
}

.ki-trailer .path2:before {
  content: "\ef3c";
  position: absolute;
  left: 0;
}

.ki-trailer .path3:before {
  content: "\ef3d";
  position: absolute;
  left: 0;
}

.ki-trailer .path4:before {
  content: "\ef3e";
  position: absolute;
  left: 0;
}

.ki-trailer .path5:before {
  content: "\ef3f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-trash-square .path1:before {
  content: "\ef40";
  opacity: 0.3;
}

.ki-trash-square .path2:before {
  content: "\ef41";
  position: absolute;
  left: 0;
}

.ki-trash-square .path3:before {
  content: "\ef42";
  position: absolute;
  left: 0;
}

.ki-trash-square .path4:before {
  content: "\ef43";
  position: absolute;
  left: 0;
}

.ki-trash .path1:before {
  content: "\ef44";
  opacity: 0.3;
}

.ki-trash .path2:before {
  content: "\ef45";
  position: absolute;
  left: 0;
}

.ki-trash .path3:before {
  content: "\ef46";
  position: absolute;
  left: 0;
}

.ki-trash .path4:before {
  content: "\ef47";
  position: absolute;
  left: 0;
}

.ki-trash .path5:before {
  content: "\ef48";
  position: absolute;
  left: 0;
}

.ki-tree .path1:before {
  content: "\ef49";
  opacity: 0.3;
}

.ki-tree .path2:before {
  content: "\ef4a";
  position: absolute;
  left: 0;
}

.ki-tree .path3:before {
  content: "\ef4b";
  position: absolute;
  left: 0;
}

.ki-trello .path1:before {
  content: "\ef4c";
  opacity: 0.3;
}

.ki-trello .path2:before {
  content: "\ef4d";
  position: absolute;
  left: 0;
}

.ki-trello .path3:before {
  content: "\ef4e";
  position: absolute;
  left: 0;
}

.ki-triangle .path1:before {
  content: "\ef4f";
}

.ki-triangle .path2:before {
  content: "\ef50";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-triangle .path3:before {
  content: "\ef51";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-truck .path1:before {
  content: "\ef52";
}

.ki-truck .path2:before {
  content: "\ef53";
  position: absolute;
  left: 0;
}

.ki-truck .path3:before {
  content: "\ef54";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-truck .path4:before {
  content: "\ef55";
  position: absolute;
  left: 0;
}

.ki-truck .path5:before {
  content: "\ef56";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-ts .path1:before {
  content: "\ef57";
  opacity: 0.3;
}

.ki-ts .path2:before {
  content: "\ef58";
  position: absolute;
  left: 0;
}

.ki-ts .path3:before {
  content: "\ef59";
  position: absolute;
  left: 0;
}

.ki-twitch .path1:before {
  content: "\ef5a";
  opacity: 0.3;
}

.ki-twitch .path2:before {
  content: "\ef5b";
  position: absolute;
  left: 0;
}

.ki-twitch .path3:before {
  content: "\ef5c";
  position: absolute;
  left: 0;
}

.ki-twitter .path1:before {
  content: "\ef5d";
  opacity: 0.3;
}

.ki-twitter .path2:before {
  content: "\ef5e";
  position: absolute;
  left: 0;
}

.ki-two-credit-cart .path1:before {
  content: "\ef5f";
}

.ki-two-credit-cart .path2:before {
  content: "\ef60";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-two-credit-cart .path3:before {
  content: "\ef61";
  position: absolute;
  left: 0;
}

.ki-two-credit-cart .path4:before {
  content: "\ef62";
  position: absolute;
  left: 0;
}

.ki-two-credit-cart .path5:before {
  content: "\ef63";
  position: absolute;
  left: 0;
}

.ki-underlining .path1:before {
  content: "\ef64";
  opacity: 0.3;
}

.ki-underlining .path2:before {
  content: "\ef65";
  position: absolute;
  left: 0;
}

.ki-underlining .path3:before {
  content: "\ef66";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-up-down .path1:before {
  content: "\ef67";
  opacity: 0.3;
}

.ki-up-down .path2:before {
  content: "\ef68";
  position: absolute;
  left: 0;
}

.ki-up-down .path3:before {
  content: "\ef69";
  position: absolute;
  left: 0;
}

.ki-up-square .path1:before {
  content: "\ef6a";
  opacity: 0.3;
}

.ki-up-square .path2:before {
  content: "\ef6b";
  position: absolute;
  left: 0;
}

.ki-up:before {
  content: "\ef6c";
}

.ki-update-file .path1:before {
  content: "\ef6d";
  opacity: 0.3;
}

.ki-update-file .path2:before {
  content: "\ef6e";
  position: absolute;
  left: 0;
}

.ki-update-file .path3:before {
  content: "\ef6f";
  position: absolute;
  left: 0;
}

.ki-update-file .path4:before {
  content: "\ef70";
  position: absolute;
  left: 0;
}

.ki-update-folder .path1:before {
  content: "\ef71";
  opacity: 0.3;
}

.ki-update-folder .path2:before {
  content: "\ef72";
  position: absolute;
  left: 0;
}

.ki-user-edit .path1:before {
  content: "\ef73";
}

.ki-user-edit .path2:before {
  content: "\ef74";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-user-edit .path3:before {
  content: "\ef75";
  position: absolute;
  left: 0;
}

.ki-user-square .path1:before {
  content: "\ef76";
  opacity: 0.3;
}

.ki-user-square .path2:before {
  content: "\ef77";
  position: absolute;
  left: 0;
}

.ki-user-square .path3:before {
  content: "\ef78";
  position: absolute;
  left: 0;
}

.ki-user-tick .path1:before {
  content: "\ef79";
}

.ki-user-tick .path2:before {
  content: "\ef7a";
  position: absolute;
  left: 0;
}

.ki-user-tick .path3:before {
  content: "\ef7b";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-user .path1:before {
  content: "\ef7c";
  opacity: 0.3;
}

.ki-user .path2:before {
  content: "\ef7d";
  position: absolute;
  left: 0;
}

.ki-verify .path1:before {
  content: "\ef7e";
  opacity: 0.3;
}

.ki-verify .path2:before {
  content: "\ef7f";
  position: absolute;
  left: 0;
}

.ki-vibe .path1:before {
  content: "\ef80";
}

.ki-vibe .path2:before {
  content: "\ef81";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-virus .path1:before {
  content: "\ef82";
  opacity: 0.3;
}

.ki-virus .path2:before {
  content: "\ef83";
  position: absolute;
  left: 0;
}

.ki-virus .path3:before {
  content: "\ef84";
  position: absolute;
  left: 0;
}

.ki-vue .path1:before {
  content: "\ef85";
  opacity: 0.3;
}

.ki-vue .path2:before {
  content: "\ef86";
  position: absolute;
  left: 0;
}

.ki-vuesax .path1:before {
  content: "\ef87";
}

.ki-vuesax .path2:before {
  content: "\ef88";
  position: absolute;
  left: 0;
  opacity: 0.4;
}

.ki-vuesax .path3:before {
  content: "\ef89";
  position: absolute;
  left: 0;
}

.ki-wallet .path1:before {
  content: "\ef8a";
  opacity: 0.3;
}

.ki-wallet .path2:before {
  content: "\ef8b";
  position: absolute;
  left: 0;
}

.ki-wallet .path3:before {
  content: "\ef8c";
  position: absolute;
  left: 0;
}

.ki-wallet .path4:before {
  content: "\ef8d";
  position: absolute;
  left: 0;
}

.ki-wanchain .path1:before {
  content: "\ef8e";
}

.ki-wanchain .path2:before {
  content: "\ef8f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-watch .path1:before {
  content: "\ef90";
  opacity: 0.3;
}

.ki-watch .path2:before {
  content: "\ef91";
  position: absolute;
  left: 0;
}

.ki-whatsapp .path1:before {
  content: "\ef92";
  opacity: 0.4;
}

.ki-whatsapp .path2:before {
  content: "\ef93";
  position: absolute;
  left: 0;
}

.ki-wifi-home .path1:before {
  content: "\ef94";
  opacity: 0.3;
}

.ki-wifi-home .path2:before {
  content: "\ef95";
  position: absolute;
  left: 0;
}

.ki-wifi-home .path3:before {
  content: "\ef96";
  position: absolute;
  left: 0;
}

.ki-wifi-home .path4:before {
  content: "\ef97";
  position: absolute;
  left: 0;
}

.ki-wifi-square .path1:before {
  content: "\ef98";
  opacity: 0.3;
}

.ki-wifi-square .path2:before {
  content: "\ef99";
  position: absolute;
  left: 0;
}

.ki-wifi-square .path3:before {
  content: "\ef9a";
  position: absolute;
  left: 0;
}

.ki-wifi-square .path4:before {
  content: "\ef9b";
  position: absolute;
  left: 0;
}

.ki-wifi .path1:before {
  content: "\ef9c";
}

.ki-wifi .path2:before {
  content: "\ef9d";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-wifi .path3:before {
  content: "\ef9e";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-wifi .path4:before {
  content: "\ef9f";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-wrench .path1:before {
  content: "\efa0";
  opacity: 0.3;
}

.ki-wrench .path2:before {
  content: "\efa1";
  position: absolute;
  left: 0;
}

.ki-xaomi .path1:before {
  content: "\efa2";
}

.ki-xaomi .path2:before {
  content: "\efa3";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-xd .path1:before {
  content: "\efa4";
  opacity: 0.3;
}

.ki-xd .path2:before {
  content: "\efa5";
  position: absolute;
  left: 0;
}

.ki-xd .path3:before {
  content: "\efa6";
  position: absolute;
  left: 0;
}

.ki-xmr .path1:before {
  content: "\efa7";
}

.ki-xmr .path2:before {
  content: "\efa8";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-yii .path1:before {
  content: "\efa9";
  opacity: 0.3;
}

.ki-yii .path2:before {
  content: "\efaa";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-yii .path3:before {
  content: "\efab";
  position: absolute;
  left: 0;
}

.ki-youtube .path1:before {
  content: "\efac";
}

.ki-youtube .path2:before {
  content: "\efad";
  position: absolute;
  left: 0;
  opacity: 0.3;
}

.ki-duotone i {
  font-style: normal;
}

@font-face {
  font-family: "keenicons-outline";
  src: url("keenicons-outline.eot?fzo4bm");
  src: url("keenicons-outline.eot?fzo4bm#iefix") format("embedded-opentype"), url("keenicons-outline.ttf?fzo4bm") format("truetype"), url("keenicons-outline.woff?fzo4bm") format("woff"), url("keenicons-outline.svg?fzo4bm#keenicons-outline") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ki-outline {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "keenicons-outline" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ki-abstract-1.ki-outline:before {
  content: "\e900";
}

.ki-abstract-2.ki-outline:before {
  content: "\e901";
}

.ki-abstract-3.ki-outline:before {
  content: "\e902";
}

.ki-abstract-4.ki-outline:before {
  content: "\e903";
}

.ki-abstract-5.ki-outline:before {
  content: "\e904";
}

.ki-abstract-6.ki-outline:before {
  content: "\e905";
}

.ki-abstract-7.ki-outline:before {
  content: "\e906";
}

.ki-abstract-8.ki-outline:before {
  content: "\e907";
}

.ki-abstract-9.ki-outline:before {
  content: "\e908";
}

.ki-abstract-10.ki-outline:before {
  content: "\e909";
}

.ki-abstract-11.ki-outline:before {
  content: "\e90a";
}

.ki-abstract-12.ki-outline:before {
  content: "\e90b";
}

.ki-abstract-13.ki-outline:before {
  content: "\e90c";
}

.ki-abstract-14.ki-outline:before {
  content: "\e90d";
}

.ki-abstract-15.ki-outline:before {
  content: "\e90e";
}

.ki-abstract-16.ki-outline:before {
  content: "\e90f";
}

.ki-abstract-17.ki-outline:before {
  content: "\e910";
}

.ki-abstract-18.ki-outline:before {
  content: "\e911";
}

.ki-abstract-19.ki-outline:before {
  content: "\e912";
}

.ki-abstract-20.ki-outline:before {
  content: "\e913";
}

.ki-abstract-21.ki-outline:before {
  content: "\e914";
}

.ki-abstract-22.ki-outline:before {
  content: "\e915";
}

.ki-abstract-23.ki-outline:before {
  content: "\e916";
}

.ki-abstract-24.ki-outline:before {
  content: "\e917";
}

.ki-abstract-25.ki-outline:before {
  content: "\e918";
}

.ki-abstract-26.ki-outline:before {
  content: "\e919";
}

.ki-abstract-27.ki-outline:before {
  content: "\e91a";
}

.ki-abstract-28.ki-outline:before {
  content: "\e91b";
}

.ki-abstract-29.ki-outline:before {
  content: "\e91c";
}

.ki-abstract-30.ki-outline:before {
  content: "\e91d";
}

.ki-abstract-31.ki-outline:before {
  content: "\e91e";
}

.ki-abstract-32.ki-outline:before {
  content: "\e91f";
}

.ki-abstract-33.ki-outline:before {
  content: "\e920";
}

.ki-abstract-34.ki-outline:before {
  content: "\e921";
}

.ki-abstract-35.ki-outline:before {
  content: "\e922";
}

.ki-abstract-36.ki-outline:before {
  content: "\e923";
}

.ki-abstract-37.ki-outline:before {
  content: "\e924";
}

.ki-abstract-38.ki-outline:before {
  content: "\e925";
}

.ki-abstract-39.ki-outline:before {
  content: "\e926";
}

.ki-abstract-40.ki-outline:before {
  content: "\e927";
}

.ki-abstract-41.ki-outline:before {
  content: "\e928";
}

.ki-abstract-42.ki-outline:before {
  content: "\e929";
}

.ki-abstract-43.ki-outline:before {
  content: "\e92a";
}

.ki-abstract-44.ki-outline:before {
  content: "\e92b";
}

.ki-abstract-45.ki-outline:before {
  content: "\e92c";
}

.ki-abstract-46.ki-outline:before {
  content: "\e92d";
}

.ki-abstract-47.ki-outline:before {
  content: "\e92e";
}

.ki-abstract-48.ki-outline:before {
  content: "\e92f";
}

.ki-abstract-49.ki-outline:before {
  content: "\e930";
}

.ki-abstract.ki-outline:before {
  content: "\e931";
}

.ki-add-files.ki-outline:before {
  content: "\e932";
}

.ki-add-folder.ki-outline:before {
  content: "\e933";
}

.ki-add-item.ki-outline:before {
  content: "\e934";
}

.ki-add-notepad.ki-outline:before {
  content: "\e935";
}

.ki-address-book.ki-outline:before {
  content: "\e936";
}

.ki-airplane-square.ki-outline:before {
  content: "\e937";
}

.ki-airplane.ki-outline:before {
  content: "\e938";
}

.ki-airpod.ki-outline:before {
  content: "\e939";
}

.ki-android.ki-outline:before {
  content: "\e93a";
}

.ki-angular.ki-outline:before {
  content: "\e93b";
}

.ki-apple.ki-outline:before {
  content: "\e93c";
}

.ki-archive-tick.ki-outline:before {
  content: "\e93d";
}

.ki-archive.ki-outline:before {
  content: "\e93e";
}

.ki-arrow-circle-left.ki-outline:before {
  content: "\e93f";
}

.ki-arrow-circle-right.ki-outline:before {
  content: "\e940";
}

.ki-arrow-diagonal.ki-outline:before {
  content: "\e941";
}

.ki-arrow-down-left.ki-outline:before {
  content: "\e942";
}

.ki-arrow-down-refraction.ki-outline:before {
  content: "\e943";
}

.ki-arrow-down-right.ki-outline:before {
  content: "\e944";
}

.ki-arrow-down.ki-outline:before {
  content: "\e945";
}

.ki-arrow-left.ki-outline:before {
  content: "\e946";
}

.ki-arrow-mix.ki-outline:before {
  content: "\e947";
}

.ki-arrow-right-left.ki-outline:before {
  content: "\e948";
}

.ki-arrow-right.ki-outline:before {
  content: "\e949";
}

.ki-arrow-two-diagonals.ki-outline:before {
  content: "\e94a";
}

.ki-arrow-up-down.ki-outline:before {
  content: "\e94b";
}

.ki-arrow-up-left.ki-outline:before {
  content: "\e94c";
}

.ki-arrow-up-refraction.ki-outline:before {
  content: "\e94d";
}

.ki-arrow-up-right.ki-outline:before {
  content: "\e94e";
}

.ki-arrow-up.ki-outline:before {
  content: "\e94f";
}

.ki-arrow-zigzag.ki-outline:before {
  content: "\e950";
}

.ki-arrows-circle.ki-outline:before {
  content: "\e951";
}

.ki-arrows-loop.ki-outline:before {
  content: "\e952";
}

.ki-artificial-intelligence.ki-outline:before {
  content: "\e953";
}

.ki-auto-brightness.ki-outline:before {
  content: "\e954";
}

.ki-avalanche.ki-outline:before {
  content: "\e955";
}

.ki-award.ki-outline:before {
  content: "\e956";
}

.ki-badge.ki-outline:before {
  content: "\e957";
}

.ki-bandage.ki-outline:before {
  content: "\e958";
}

.ki-bank.ki-outline:before {
  content: "\e959";
}

.ki-barcode.ki-outline:before {
  content: "\e95a";
}

.ki-basket-ok.ki-outline:before {
  content: "\e95b";
}

.ki-basket.ki-outline:before {
  content: "\e95c";
}

.ki-behance.ki-outline:before {
  content: "\e95d";
}

.ki-bill.ki-outline:before {
  content: "\e95e";
}

.ki-binance-usd.ki-outline:before {
  content: "\e95f";
}

.ki-binance.ki-outline:before {
  content: "\e960";
}

.ki-bitcoin.ki-outline:before {
  content: "\e961";
}

.ki-black-down.ki-outline:before {
  content: "\e962";
}

.ki-black-left-line.ki-outline:before {
  content: "\e963";
}

.ki-black-left.ki-outline:before {
  content: "\e964";
}

.ki-black-right-line.ki-outline:before {
  content: "\e965";
}

.ki-black-right.ki-outline:before {
  content: "\e966";
}

.ki-black-up.ki-outline:before {
  content: "\e967";
}

.ki-bluetooth.ki-outline:before {
  content: "\e968";
}

.ki-book-open.ki-outline:before {
  content: "\e969";
}

.ki-book-square.ki-outline:before {
  content: "\e96a";
}

.ki-book.ki-outline:before {
  content: "\e96b";
}

.ki-bookmark-2.ki-outline:before {
  content: "\e96c";
}

.ki-bookmark.ki-outline:before {
  content: "\e96d";
}

.ki-bootstrap.ki-outline:before {
  content: "\e96e";
}

.ki-briefcase.ki-outline:before {
  content: "\e96f";
}

.ki-brifecase-cros.ki-outline:before {
  content: "\e970";
}

.ki-brifecase-tick.ki-outline:before {
  content: "\e971";
}

.ki-brifecase-timer.ki-outline:before {
  content: "\e972";
}

.ki-brush.ki-outline:before {
  content: "\e973";
}

.ki-bucket-square.ki-outline:before {
  content: "\e974";
}

.ki-bucket.ki-outline:before {
  content: "\e975";
}

.ki-burger-menu-1.ki-outline:before {
  content: "\e976";
}

.ki-burger-menu-2.ki-outline:before {
  content: "\e977";
}

.ki-burger-menu-3.ki-outline:before {
  content: "\e978";
}

.ki-burger-menu-4.ki-outline:before {
  content: "\e979";
}

.ki-burger-menu-5.ki-outline:before {
  content: "\e97a";
}

.ki-burger-menu-6.ki-outline:before {
  content: "\e97b";
}

.ki-burger-menu.ki-outline:before {
  content: "\e97c";
}

.ki-bus.ki-outline:before {
  content: "\e97d";
}

.ki-calculator.ki-outline:before {
  content: "\e97e";
}

.ki-calendar-2.ki-outline:before {
  content: "\e97f";
}

.ki-calendar-8.ki-outline:before {
  content: "\e980";
}

.ki-calendar-add.ki-outline:before {
  content: "\e981";
}

.ki-calendar-edit.ki-outline:before {
  content: "\e982";
}

.ki-calendar-remove.ki-outline:before {
  content: "\e983";
}

.ki-calendar-search.ki-outline:before {
  content: "\e984";
}

.ki-calendar-tick.ki-outline:before {
  content: "\e985";
}

.ki-calendar.ki-outline:before {
  content: "\e986";
}

.ki-call.ki-outline:before {
  content: "\e987";
}

.ki-capsule.ki-outline:before {
  content: "\e988";
}

.ki-car-2.ki-outline:before {
  content: "\e989";
}

.ki-car-3.ki-outline:before {
  content: "\e98a";
}

.ki-car.ki-outline:before {
  content: "\e98b";
}

.ki-category.ki-outline:before {
  content: "\e98c";
}

.ki-cd.ki-outline:before {
  content: "\e98d";
}

.ki-celsius.ki-outline:before {
  content: "\e98e";
}

.ki-chart-line-down-2.ki-outline:before {
  content: "\e98f";
}

.ki-chart-line-down.ki-outline:before {
  content: "\e990";
}

.ki-chart-line-star.ki-outline:before {
  content: "\e991";
}

.ki-chart-line-up-2.ki-outline:before {
  content: "\e992";
}

.ki-chart-line-up.ki-outline:before {
  content: "\e993";
}

.ki-chart-line.ki-outline:before {
  content: "\e994";
}

.ki-chart-pie-3.ki-outline:before {
  content: "\e995";
}

.ki-chart-pie-4.ki-outline:before {
  content: "\e996";
}

.ki-chart-pie-simple.ki-outline:before {
  content: "\e997";
}

.ki-chart-pie-too.ki-outline:before {
  content: "\e998";
}

.ki-chart-simple-2.ki-outline:before {
  content: "\e999";
}

.ki-chart-simple-3.ki-outline:before {
  content: "\e99a";
}

.ki-chart-simple.ki-outline:before {
  content: "\e99b";
}

.ki-chart.ki-outline:before {
  content: "\e99c";
}

.ki-check-circle.ki-outline:before {
  content: "\e99d";
}

.ki-check-square.ki-outline:before {
  content: "\e99e";
}

.ki-check.ki-outline:before {
  content: "\e99f";
}

.ki-cheque.ki-outline:before {
  content: "\e9a0";
}

.ki-chrome.ki-outline:before {
  content: "\e9a1";
}

.ki-classmates.ki-outline:before {
  content: "\e9a2";
}

.ki-click.ki-outline:before {
  content: "\e9a3";
}

.ki-clipboard.ki-outline:before {
  content: "\e9a4";
}

.ki-cloud-add.ki-outline:before {
  content: "\e9a5";
}

.ki-cloud-change.ki-outline:before {
  content: "\e9a6";
}

.ki-cloud-download.ki-outline:before {
  content: "\e9a7";
}

.ki-cloud.ki-outline:before {
  content: "\e9a8";
}

.ki-code.ki-outline:before {
  content: "\e9a9";
}

.ki-coffee.ki-outline:before {
  content: "\e9aa";
}

.ki-color-swatch.ki-outline:before {
  content: "\e9ab";
}

.ki-colors-square.ki-outline:before {
  content: "\e9ac";
}

.ki-compass.ki-outline:before {
  content: "\e9ad";
}

.ki-copy-success.ki-outline:before {
  content: "\e9ae";
}

.ki-copy.ki-outline:before {
  content: "\e9af";
}

.ki-courier-express.ki-outline:before {
  content: "\e9b0";
}

.ki-courier.ki-outline:before {
  content: "\e9b1";
}

.ki-credit-cart.ki-outline:before {
  content: "\e9b2";
}

.ki-cross-circle.ki-outline:before {
  content: "\e9b3";
}

.ki-cross-square.ki-outline:before {
  content: "\e9b4";
}

.ki-cross.ki-outline:before {
  content: "\e9b5";
}

.ki-crown-2.ki-outline:before {
  content: "\e9b6";
}

.ki-crown.ki-outline:before {
  content: "\e9b7";
}

.ki-css.ki-outline:before {
  content: "\e9b8";
}

.ki-cube-2.ki-outline:before {
  content: "\e9b9";
}

.ki-cube-3.ki-outline:before {
  content: "\e9ba";
}

.ki-cup.ki-outline:before {
  content: "\e9bb";
}

.ki-dash.ki-outline:before {
  content: "\e9bc";
}

.ki-data.ki-outline:before {
  content: "\e9bd";
}

.ki-delete-files.ki-outline:before {
  content: "\e9be";
}

.ki-delete-folder.ki-outline:before {
  content: "\e9bf";
}

.ki-delivery-2.ki-outline:before {
  content: "\e9c0";
}

.ki-delivery-3.ki-outline:before {
  content: "\e9c1";
}

.ki-delivery-24.ki-outline:before {
  content: "\e9c2";
}

.ki-delivery-door.ki-outline:before {
  content: "\e9c3";
}

.ki-delivery-geolocation.ki-outline:before {
  content: "\e9c4";
}

.ki-delivery-time.ki-outline:before {
  content: "\e9c5";
}

.ki-delivery.ki-outline:before {
  content: "\e9c6";
}

.ki-design-2.ki-outline:before {
  content: "\e9c7";
}

.ki-design-frame.ki-outline:before {
  content: "\e9c8";
}

.ki-design-mask.ki-outline:before {
  content: "\e9c9";
}

.ki-design.ki-outline:before {
  content: "\e9ca";
}

.ki-devices-2.ki-outline:before {
  content: "\e9cb";
}

.ki-devices.ki-outline:before {
  content: "\e9cc";
}

.ki-diamonds.ki-outline:before {
  content: "\e9cd";
}

.ki-directbox-default.ki-outline:before {
  content: "\e9ce";
}

.ki-disconnect.ki-outline:before {
  content: "\e9cf";
}

.ki-discount.ki-outline:before {
  content: "\e9d0";
}

.ki-disk.ki-outline:before {
  content: "\e9d1";
}

.ki-dislike.ki-outline:before {
  content: "\e9d2";
}

.ki-dj.ki-outline:before {
  content: "\e9d3";
}

.ki-document.ki-outline:before {
  content: "\e9d4";
}

.ki-dollar.ki-outline:before {
  content: "\e9d5";
}

.ki-dots-circle-vertical.ki-outline:before {
  content: "\e9d6";
}

.ki-dots-circle.ki-outline:before {
  content: "\e9d7";
}

.ki-dots-horizontal.ki-outline:before {
  content: "\e9d8";
}

.ki-dots-square-vertical.ki-outline:before {
  content: "\e9d9";
}

.ki-dots-square.ki-outline:before {
  content: "\e9da";
}

.ki-dots-vertical.ki-outline:before {
  content: "\e9db";
}

.ki-double-check-circle.ki-outline:before {
  content: "\e9dc";
}

.ki-double-check.ki-outline:before {
  content: "\e9dd";
}

.ki-double-down.ki-outline:before {
  content: "\e9de";
}

.ki-double-left-arrow.ki-outline:before {
  content: "\e9df";
}

.ki-double-left.ki-outline:before {
  content: "\e9e0";
}

.ki-double-right-arrow.ki-outline:before {
  content: "\e9e1";
}

.ki-double-right.ki-outline:before {
  content: "\e9e2";
}

.ki-double-up.ki-outline:before {
  content: "\e9e3";
}

.ki-down-square.ki-outline:before {
  content: "\e9e4";
}

.ki-down.ki-outline:before {
  content: "\e9e5";
}

.ki-dribbble.ki-outline:before {
  content: "\e9e6";
}

.ki-drop.ki-outline:before {
  content: "\e9e7";
}

.ki-dropbox.ki-outline:before {
  content: "\e9e8";
}

.ki-educare.ki-outline:before {
  content: "\e9e9";
}

.ki-electricity.ki-outline:before {
  content: "\e9ea";
}

.ki-electronic-clock.ki-outline:before {
  content: "\e9eb";
}

.ki-element-1.ki-outline:before {
  content: "\e9ec";
}

.ki-element-2.ki-outline:before {
  content: "\e9ed";
}

.ki-element-3.ki-outline:before {
  content: "\e9ee";
}

.ki-element-4.ki-outline:before {
  content: "\e9ef";
}

.ki-element-5.ki-outline:before {
  content: "\e9f0";
}

.ki-element-6.ki-outline:before {
  content: "\e9f1";
}

.ki-element-7.ki-outline:before {
  content: "\e9f2";
}

.ki-element-8.ki-outline:before {
  content: "\e9f3";
}

.ki-element-9.ki-outline:before {
  content: "\e9f4";
}

.ki-element-10.ki-outline:before {
  content: "\e9f5";
}

.ki-element-11.ki-outline:before {
  content: "\e9f6";
}

.ki-element-12.ki-outline:before {
  content: "\e9f7";
}

.ki-element-equal.ki-outline:before {
  content: "\e9f8";
}

.ki-element-plus.ki-outline:before {
  content: "\e9f9";
}

.ki-emoji-happy.ki-outline:before {
  content: "\e9fa";
}

.ki-enjin-coin.ki-outline:before {
  content: "\e9fb";
}

.ki-entrance-left.ki-outline:before {
  content: "\e9fc";
}

.ki-entrance-right.ki-outline:before {
  content: "\e9fd";
}

.ki-eraser.ki-outline:before {
  content: "\e9fe";
}

.ki-euro.ki-outline:before {
  content: "\e9ff";
}

.ki-exit-down.ki-outline:before {
  content: "\ea00";
}

.ki-exit-left.ki-outline:before {
  content: "\ea01";
}

.ki-exit-right-corner.ki-outline:before {
  content: "\ea02";
}

.ki-exit-right.ki-outline:before {
  content: "\ea03";
}

.ki-exit-up.ki-outline:before {
  content: "\ea04";
}

.ki-external-drive.ki-outline:before {
  content: "\ea05";
}

.ki-eye-slash.ki-outline:before {
  content: "\ea06";
}

.ki-eye.ki-outline:before {
  content: "\ea07";
}

.ki-facebook.ki-outline:before {
  content: "\ea08";
}

.ki-faceid.ki-outline:before {
  content: "\ea09";
}

.ki-fasten.ki-outline:before {
  content: "\ea0a";
}

.ki-fat-rows.ki-outline:before {
  content: "\ea0b";
}

.ki-feather.ki-outline:before {
  content: "\ea0c";
}

.ki-figma.ki-outline:before {
  content: "\ea0d";
}

.ki-file-added.ki-outline:before {
  content: "\ea0e";
}

.ki-file-deleted.ki-outline:before {
  content: "\ea0f";
}

.ki-file-down.ki-outline:before {
  content: "\ea10";
}

.ki-file-left.ki-outline:before {
  content: "\ea11";
}

.ki-file-right.ki-outline:before {
  content: "\ea12";
}

.ki-file-sheet.ki-outline:before {
  content: "\ea13";
}

.ki-file-up.ki-outline:before {
  content: "\ea14";
}

.ki-file.ki-outline:before {
  content: "\ea15";
}

.ki-files-tablet.ki-outline:before {
  content: "\ea16";
}

.ki-filter-edit.ki-outline:before {
  content: "\ea17";
}

.ki-filter-search.ki-outline:before {
  content: "\ea18";
}

.ki-filter-square.ki-outline:before {
  content: "\ea19";
}

.ki-filter-tablet.ki-outline:before {
  content: "\ea1a";
}

.ki-filter-tick.ki-outline:before {
  content: "\ea1b";
}

.ki-filter.ki-outline:before {
  content: "\ea1c";
}

.ki-finance-calculator.ki-outline:before {
  content: "\ea1d";
}

.ki-financial-schedule.ki-outline:before {
  content: "\ea1e";
}

.ki-fingerprint-scanning.ki-outline:before {
  content: "\ea1f";
}

.ki-flag.ki-outline:before {
  content: "\ea20";
}

.ki-flash-circle.ki-outline:before {
  content: "\ea21";
}

.ki-flask.ki-outline:before {
  content: "\ea22";
}

.ki-focus.ki-outline:before {
  content: "\ea23";
}

.ki-folder-added.ki-outline:before {
  content: "\ea24";
}

.ki-folder-down.ki-outline:before {
  content: "\ea25";
}

.ki-folder-up.ki-outline:before {
  content: "\ea26";
}

.ki-folder.ki-outline:before {
  content: "\ea27";
}

.ki-frame.ki-outline:before {
  content: "\ea28";
}

.ki-gear.ki-outline:before {
  content: "\ea29";
}

.ki-general-mouse.ki-outline:before {
  content: "\ea2a";
}

.ki-geolocation-home.ki-outline:before {
  content: "\ea2b";
}

.ki-geolocation.ki-outline:before {
  content: "\ea2c";
}

.ki-ghost.ki-outline:before {
  content: "\ea2d";
}

.ki-gift.ki-outline:before {
  content: "\ea2e";
}

.ki-github.ki-outline:before {
  content: "\ea2f";
}

.ki-glass.ki-outline:before {
  content: "\ea30";
}

.ki-google-play.ki-outline:before {
  content: "\ea31";
}

.ki-google.ki-outline:before {
  content: "\ea32";
}

.ki-graph-2.ki-outline:before {
  content: "\ea33";
}

.ki-graph-3.ki-outline:before {
  content: "\ea34";
}

.ki-graph-4.ki-outline:before {
  content: "\ea35";
}

.ki-graph-up.ki-outline:before {
  content: "\ea36";
}

.ki-graph.ki-outline:before {
  content: "\ea37";
}

.ki-grid-2.ki-outline:before {
  content: "\ea38";
}

.ki-grid-frame.ki-outline:before {
  content: "\ea39";
}

.ki-grid.ki-outline:before {
  content: "\ea3a";
}

.ki-handcart.ki-outline:before {
  content: "\ea3b";
}

.ki-happy-emoji.ki-outline:before {
  content: "\ea3c";
}

.ki-heart-circle.ki-outline:before {
  content: "\ea3d";
}

.ki-heart.ki-outline:before {
  content: "\ea3e";
}

.ki-home-1.ki-outline:before {
  content: "\ea3f";
}

.ki-home-2.ki-outline:before {
  content: "\ea40";
}

.ki-home-3.ki-outline:before {
  content: "\ea41";
}

.ki-home.ki-outline:before {
  content: "\ea42";
}

.ki-html.ki-outline:before {
  content: "\ea43";
}

.ki-icon.ki-outline:before {
  content: "\ea44";
}

.ki-illustrator.ki-outline:before {
  content: "\ea45";
}

.ki-information-2.ki-outline:before {
  content: "\ea46";
}

.ki-information-3.ki-outline:before {
  content: "\ea47";
}

.ki-information-4.ki-outline:before {
  content: "\ea48";
}

.ki-information-5.ki-outline:before {
  content: "\ea49";
}

.ki-information.ki-outline:before {
  content: "\ea4a";
}

.ki-instagram.ki-outline:before {
  content: "\ea4b";
}

.ki-joystick.ki-outline:before {
  content: "\ea4c";
}

.ki-js-2.ki-outline:before {
  content: "\ea4d";
}

.ki-js.ki-outline:before {
  content: "\ea4e";
}

.ki-kanban.ki-outline:before {
  content: "\ea4f";
}

.ki-key-square.ki-outline:before {
  content: "\ea50";
}

.ki-key.ki-outline:before {
  content: "\ea51";
}

.ki-keyboard.ki-outline:before {
  content: "\ea52";
}

.ki-laptop.ki-outline:before {
  content: "\ea53";
}

.ki-laravel.ki-outline:before {
  content: "\ea54";
}

.ki-left-square.ki-outline:before {
  content: "\ea55";
}

.ki-left.ki-outline:before {
  content: "\ea56";
}

.ki-like-2.ki-outline:before {
  content: "\ea57";
}

.ki-like-folder.ki-outline:before {
  content: "\ea58";
}

.ki-like-shapes.ki-outline:before {
  content: "\ea59";
}

.ki-like-tag.ki-outline:before {
  content: "\ea5a";
}

.ki-like.ki-outline:before {
  content: "\ea5b";
}

.ki-loading.ki-outline:before {
  content: "\ea5c";
}

.ki-lock-2.ki-outline:before {
  content: "\ea5d";
}

.ki-lock-3.ki-outline:before {
  content: "\ea5e";
}

.ki-lock.ki-outline:before {
  content: "\ea5f";
}

.ki-logistic.ki-outline:before {
  content: "\ea60";
}

.ki-lots-shopping.ki-outline:before {
  content: "\ea61";
}

.ki-lovely.ki-outline:before {
  content: "\ea62";
}

.ki-lts.ki-outline:before {
  content: "\ea63";
}

.ki-magnifier.ki-outline:before {
  content: "\ea64";
}

.ki-map.ki-outline:before {
  content: "\ea65";
}

.ki-mask.ki-outline:before {
  content: "\ea66";
}

.ki-maximize.ki-outline:before {
  content: "\ea67";
}

.ki-medal-star.ki-outline:before {
  content: "\ea68";
}

.ki-menu.ki-outline:before {
  content: "\ea69";
}

.ki-message-add.ki-outline:before {
  content: "\ea6a";
}

.ki-message-edit.ki-outline:before {
  content: "\ea6b";
}

.ki-message-minus.ki-outline:before {
  content: "\ea6c";
}

.ki-message-notif.ki-outline:before {
  content: "\ea6d";
}

.ki-message-programming.ki-outline:before {
  content: "\ea6e";
}

.ki-message-question.ki-outline:before {
  content: "\ea6f";
}

.ki-message-text-2.ki-outline:before {
  content: "\ea70";
}

.ki-message-text.ki-outline:before {
  content: "\ea71";
}

.ki-messages.ki-outline:before {
  content: "\ea72";
}

.ki-microsoft.ki-outline:before {
  content: "\ea73";
}

.ki-milk.ki-outline:before {
  content: "\ea74";
}

.ki-minus-circle.ki-outline:before {
  content: "\ea75";
}

.ki-minus-folder.ki-outline:before {
  content: "\ea76";
}

.ki-minus-square.ki-outline:before {
  content: "\ea77";
}

.ki-minus.ki-outline:before {
  content: "\ea78";
}

.ki-monitor-mobile.ki-outline:before {
  content: "\ea79";
}

.ki-moon.ki-outline:before {
  content: "\ea7a";
}

.ki-more-2.ki-outline:before {
  content: "\ea7b";
}

.ki-mouse-circle.ki-outline:before {
  content: "\ea7c";
}

.ki-mouse-square.ki-outline:before {
  content: "\ea7d";
}

.ki-mouse.ki-outline:before {
  content: "\ea7e";
}

.ki-nexo.ki-outline:before {
  content: "\ea7f";
}

.ki-night-day.ki-outline:before {
  content: "\ea80";
}

.ki-note-2.ki-outline:before {
  content: "\ea81";
}

.ki-note.ki-outline:before {
  content: "\ea82";
}

.ki-notepad-bookmark.ki-outline:before {
  content: "\ea83";
}

.ki-notepad-edit.ki-outline:before {
  content: "\ea84";
}

.ki-notepad.ki-outline:before {
  content: "\ea85";
}

.ki-notification-2.ki-outline:before {
  content: "\ea86";
}

.ki-notification-bing.ki-outline:before {
  content: "\ea87";
}

.ki-notification-circle.ki-outline:before {
  content: "\ea88";
}

.ki-notification-favorite.ki-outline:before {
  content: "\ea89";
}

.ki-notification-on.ki-outline:before {
  content: "\ea8a";
}

.ki-notification-status.ki-outline:before {
  content: "\ea8b";
}

.ki-notification.ki-outline:before {
  content: "\ea8c";
}

.ki-ocean.ki-outline:before {
  content: "\ea8d";
}

.ki-office-bag.ki-outline:before {
  content: "\ea8e";
}

.ki-package.ki-outline:before {
  content: "\ea8f";
}

.ki-pails.ki-outline:before {
  content: "\ea90";
}

.ki-paintbucket.ki-outline:before {
  content: "\ea91";
}

.ki-paper-clip.ki-outline:before {
  content: "\ea92";
}

.ki-parcel-tracking.ki-outline:before {
  content: "\ea93";
}

.ki-parcel.ki-outline:before {
  content: "\ea94";
}

.ki-password-check.ki-outline:before {
  content: "\ea95";
}

.ki-paypal.ki-outline:before {
  content: "\ea96";
}

.ki-pencil.ki-outline:before {
  content: "\ea97";
}

.ki-people.ki-outline:before {
  content: "\ea98";
}

.ki-percentage.ki-outline:before {
  content: "\ea99";
}

.ki-phone.ki-outline:before {
  content: "\ea9a";
}

.ki-photoshop.ki-outline:before {
  content: "\ea9b";
}

.ki-picture.ki-outline:before {
  content: "\ea9c";
}

.ki-pill.ki-outline:before {
  content: "\ea9d";
}

.ki-pin.ki-outline:before {
  content: "\ea9e";
}

.ki-plus-circle.ki-outline:before {
  content: "\ea9f";
}

.ki-plus-square.ki-outline:before {
  content: "\eaa0";
}

.ki-plus.ki-outline:before {
  content: "\eaa1";
}

.ki-pointers.ki-outline:before {
  content: "\eaa2";
}

.ki-price-tag.ki-outline:before {
  content: "\eaa3";
}

.ki-printer.ki-outline:before {
  content: "\eaa4";
}

.ki-profile-circle.ki-outline:before {
  content: "\eaa5";
}

.ki-profile-user.ki-outline:before {
  content: "\eaa6";
}

.ki-pulse.ki-outline:before {
  content: "\eaa7";
}

.ki-purchase.ki-outline:before {
  content: "\eaa8";
}

.ki-python.ki-outline:before {
  content: "\eaa9";
}

.ki-question-2.ki-outline:before {
  content: "\eaaa";
}

.ki-question.ki-outline:before {
  content: "\eaab";
}

.ki-questionnaire-tablet.ki-outline:before {
  content: "\eaac";
}

.ki-ranking.ki-outline:before {
  content: "\eaad";
}

.ki-react.ki-outline:before {
  content: "\eaae";
}

.ki-receipt-square.ki-outline:before {
  content: "\eaaf";
}

.ki-rescue.ki-outline:before {
  content: "\eab0";
}

.ki-right-left.ki-outline:before {
  content: "\eab1";
}

.ki-right-square.ki-outline:before {
  content: "\eab2";
}

.ki-right.ki-outline:before {
  content: "\eab3";
}

.ki-rocket.ki-outline:before {
  content: "\eab4";
}

.ki-route.ki-outline:before {
  content: "\eab5";
}

.ki-router.ki-outline:before {
  content: "\eab6";
}

.ki-row-horizontal.ki-outline:before {
  content: "\eab7";
}

.ki-row-vertical.ki-outline:before {
  content: "\eab8";
}

.ki-safe-home.ki-outline:before {
  content: "\eab9";
}

.ki-satellite.ki-outline:before {
  content: "\eaba";
}

.ki-save-2.ki-outline:before {
  content: "\eabb";
}

.ki-save-deposit.ki-outline:before {
  content: "\eabc";
}

.ki-scan-barcode.ki-outline:before {
  content: "\eabd";
}

.ki-scooter-2.ki-outline:before {
  content: "\eabe";
}

.ki-scooter.ki-outline:before {
  content: "\eabf";
}

.ki-screen.ki-outline:before {
  content: "\eac0";
}

.ki-scroll.ki-outline:before {
  content: "\eac1";
}

.ki-search-list.ki-outline:before {
  content: "\eac2";
}

.ki-security-check.ki-outline:before {
  content: "\eac3";
}

.ki-security-user.ki-outline:before {
  content: "\eac4";
}

.ki-send.ki-outline:before {
  content: "\eac5";
}

.ki-setting-2.ki-outline:before {
  content: "\eac6";
}

.ki-setting-3.ki-outline:before {
  content: "\eac7";
}

.ki-setting-4.ki-outline:before {
  content: "\eac8";
}

.ki-setting.ki-outline:before {
  content: "\eac9";
}

.ki-share.ki-outline:before {
  content: "\eaca";
}

.ki-shield-cross.ki-outline:before {
  content: "\eacb";
}

.ki-shield-search.ki-outline:before {
  content: "\eacc";
}

.ki-shield-slash.ki-outline:before {
  content: "\eacd";
}

.ki-shield-tick.ki-outline:before {
  content: "\eace";
}

.ki-shield.ki-outline:before {
  content: "\eacf";
}

.ki-ship.ki-outline:before {
  content: "\ead0";
}

.ki-shop.ki-outline:before {
  content: "\ead1";
}

.ki-simcard-2.ki-outline:before {
  content: "\ead2";
}

.ki-simcard.ki-outline:before {
  content: "\ead3";
}

.ki-size.ki-outline:before {
  content: "\ead4";
}

.ki-slack.ki-outline:before {
  content: "\ead5";
}

.ki-slider-horizontal-2.ki-outline:before {
  content: "\ead6";
}

.ki-slider-horizontal.ki-outline:before {
  content: "\ead7";
}

.ki-slider-vertical-2.ki-outline:before {
  content: "\ead8";
}

.ki-slider-vertical.ki-outline:before {
  content: "\ead9";
}

.ki-slider.ki-outline:before {
  content: "\eada";
}

.ki-sms.ki-outline:before {
  content: "\eadb";
}

.ki-snapchat.ki-outline:before {
  content: "\eadc";
}

.ki-social-media.ki-outline:before {
  content: "\eadd";
}

.ki-soft-2.ki-outline:before {
  content: "\eade";
}

.ki-soft-3.ki-outline:before {
  content: "\eadf";
}

.ki-soft.ki-outline:before {
  content: "\eae0";
}

.ki-some-files.ki-outline:before {
  content: "\eae1";
}

.ki-sort.ki-outline:before {
  content: "\eae2";
}

.ki-speaker.ki-outline:before {
  content: "\eae3";
}

.ki-spotify.ki-outline:before {
  content: "\eae4";
}

.ki-spring-framework.ki-outline:before {
  content: "\eae5";
}

.ki-square-brackets.ki-outline:before {
  content: "\eae6";
}

.ki-star.ki-outline:before {
  content: "\eae7";
}

.ki-status.ki-outline:before {
  content: "\eae8";
}

.ki-subtitle.ki-outline:before {
  content: "\eae9";
}

.ki-sun.ki-outline:before {
  content: "\eaea";
}

.ki-support-24.ki-outline:before {
  content: "\eaeb";
}

.ki-switch.ki-outline:before {
  content: "\eaec";
}

.ki-syringe.ki-outline:before {
  content: "\eaed";
}

.ki-tablet-book.ki-outline:before {
  content: "\eaee";
}

.ki-tablet-delete.ki-outline:before {
  content: "\eaef";
}

.ki-tablet-down.ki-outline:before {
  content: "\eaf0";
}

.ki-tablet-ok.ki-outline:before {
  content: "\eaf1";
}

.ki-tablet-text-down.ki-outline:before {
  content: "\eaf2";
}

.ki-tablet-text-up.ki-outline:before {
  content: "\eaf3";
}

.ki-tablet-up.ki-outline:before {
  content: "\eaf4";
}

.ki-tablet.ki-outline:before {
  content: "\eaf5";
}

.ki-tag-cross.ki-outline:before {
  content: "\eaf6";
}

.ki-tag.ki-outline:before {
  content: "\eaf7";
}

.ki-teacher.ki-outline:before {
  content: "\eaf8";
}

.ki-tech-wifi.ki-outline:before {
  content: "\eaf9";
}

.ki-technology-2.ki-outline:before {
  content: "\eafa";
}

.ki-technology-3.ki-outline:before {
  content: "\eafb";
}

.ki-technology-4.ki-outline:before {
  content: "\eafc";
}

.ki-technology.ki-outline:before {
  content: "\eafd";
}

.ki-telephone-geolocation.ki-outline:before {
  content: "\eafe";
}

.ki-test-tubes.ki-outline:before {
  content: "\eaff";
}

.ki-text-align-center.ki-outline:before {
  content: "\eb00";
}

.ki-text-align-justify-center.ki-outline:before {
  content: "\eb01";
}

.ki-text-align-left.ki-outline:before {
  content: "\eb02";
}

.ki-text-align-right.ki-outline:before {
  content: "\eb03";
}

.ki-text-bold.ki-outline:before {
  content: "\eb04";
}

.ki-text-circle.ki-outline:before {
  content: "\eb05";
}

.ki-text-italic.ki-outline:before {
  content: "\eb06";
}

.ki-text-number.ki-outline:before {
  content: "\eb07";
}

.ki-text-strikethrough.ki-outline:before {
  content: "\eb08";
}

.ki-text-underline.ki-outline:before {
  content: "\eb09";
}

.ki-text.ki-outline:before {
  content: "\eb0a";
}

.ki-thermometer.ki-outline:before {
  content: "\eb0b";
}

.ki-theta.ki-outline:before {
  content: "\eb0c";
}

.ki-tiktok.ki-outline:before {
  content: "\eb0d";
}

.ki-time.ki-outline:before {
  content: "\eb0e";
}

.ki-timer.ki-outline:before {
  content: "\eb0f";
}

.ki-to-left.ki-outline:before {
  content: "\eb10";
}

.ki-to-right.ki-outline:before {
  content: "\eb11";
}

.ki-toggle-off-circle.ki-outline:before {
  content: "\eb12";
}

.ki-toggle-off.ki-outline:before {
  content: "\eb13";
}

.ki-toggle-on-circle.ki-outline:before {
  content: "\eb14";
}

.ki-toggle-on.ki-outline:before {
  content: "\eb15";
}

.ki-trailer.ki-outline:before {
  content: "\eb16";
}

.ki-trash-square.ki-outline:before {
  content: "\eb17";
}

.ki-trash.ki-outline:before {
  content: "\eb18";
}

.ki-tree.ki-outline:before {
  content: "\eb19";
}

.ki-trello.ki-outline:before {
  content: "\eb1a";
}

.ki-triangle.ki-outline:before {
  content: "\eb1b";
}

.ki-truck.ki-outline:before {
  content: "\eb1c";
}

.ki-ts.ki-outline:before {
  content: "\eb1d";
}

.ki-twitch.ki-outline:before {
  content: "\eb1e";
}

.ki-twitter.ki-outline:before {
  content: "\eb1f";
}

.ki-two-credit-cart.ki-outline:before {
  content: "\eb20";
}

.ki-underlining.ki-outline:before {
  content: "\eb21";
}

.ki-up-down.ki-outline:before {
  content: "\eb22";
}

.ki-up-square.ki-outline:before {
  content: "\eb23";
}

.ki-up.ki-outline:before {
  content: "\eb24";
}

.ki-update-file.ki-outline:before {
  content: "\eb25";
}

.ki-update-folder.ki-outline:before {
  content: "\eb26";
}

.ki-user-edit.ki-outline:before {
  content: "\eb27";
}

.ki-user-square.ki-outline:before {
  content: "\eb28";
}

.ki-user-tick.ki-outline:before {
  content: "\eb29";
}

.ki-user.ki-outline:before {
  content: "\eb2a";
}

.ki-verify.ki-outline:before {
  content: "\eb2b";
}

.ki-vibe.ki-outline:before {
  content: "\eb2c";
}

.ki-virus.ki-outline:before {
  content: "\eb2d";
}

.ki-vue.ki-outline:before {
  content: "\eb2e";
}

.ki-vuesax.ki-outline:before {
  content: "\eb2f";
}

.ki-wallet.ki-outline:before {
  content: "\eb30";
}

.ki-wanchain.ki-outline:before {
  content: "\eb31";
}

.ki-watch.ki-outline:before {
  content: "\eb32";
}

.ki-whatsapp.ki-outline:before {
  content: "\eb33";
}

.ki-wifi-home.ki-outline:before {
  content: "\eb34";
}

.ki-wifi-square.ki-outline:before {
  content: "\eb35";
}

.ki-wifi.ki-outline:before {
  content: "\eb36";
}

.ki-wrench.ki-outline:before {
  content: "\eb37";
}

.ki-xaomi.ki-outline:before {
  content: "\eb38";
}

.ki-xd.ki-outline:before {
  content: "\eb39";
}

.ki-xmr.ki-outline:before {
  content: "\eb3a";
}

.ki-yii.ki-outline:before {
  content: "\eb3b";
}

.ki-youtube.ki-outline:before {
  content: "\eb3c";
}

@font-face {
  font-family: "keenicons-solid";
  src: url("keenicons-solid.eot?812fv7");
  src: url("keenicons-solid.eot?812fv7#iefix") format("embedded-opentype"), url("keenicons-solid.ttf?812fv7") format("truetype"), url("keenicons-solid.woff?812fv7") format("woff"), url("keenicons-solid.svg?812fv7#keenicons-solid") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.ki-solid {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "keenicons-solid" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ki-abstract-1.ki-solid:before {
  content: "\e900";
}

.ki-abstract-2.ki-solid:before {
  content: "\e901";
}

.ki-abstract-3.ki-solid:before {
  content: "\e902";
}

.ki-abstract-4.ki-solid:before {
  content: "\e903";
}

.ki-abstract-5.ki-solid:before {
  content: "\e904";
}

.ki-abstract-6.ki-solid:before {
  content: "\e905";
}

.ki-abstract-7.ki-solid:before {
  content: "\e906";
}

.ki-abstract-8.ki-solid:before {
  content: "\e907";
}

.ki-abstract-9.ki-solid:before {
  content: "\e908";
}

.ki-abstract-10.ki-solid:before {
  content: "\e909";
}

.ki-abstract-11.ki-solid:before {
  content: "\e90a";
}

.ki-abstract-12.ki-solid:before {
  content: "\e90b";
}

.ki-abstract-13.ki-solid:before {
  content: "\e90c";
}

.ki-abstract-14.ki-solid:before {
  content: "\e90d";
}

.ki-abstract-15.ki-solid:before {
  content: "\e90e";
}

.ki-abstract-16.ki-solid:before {
  content: "\e90f";
}

.ki-abstract-17.ki-solid:before {
  content: "\e910";
}

.ki-abstract-18.ki-solid:before {
  content: "\e911";
}

.ki-abstract-19.ki-solid:before {
  content: "\e912";
}

.ki-abstract-20.ki-solid:before {
  content: "\e913";
}

.ki-abstract-21.ki-solid:before {
  content: "\e914";
}

.ki-abstract-22.ki-solid:before {
  content: "\e915";
}

.ki-abstract-23.ki-solid:before {
  content: "\e916";
}

.ki-abstract-24.ki-solid:before {
  content: "\e917";
}

.ki-abstract-25.ki-solid:before {
  content: "\e918";
}

.ki-abstract-26.ki-solid:before {
  content: "\e919";
}

.ki-abstract-27.ki-solid:before {
  content: "\e91a";
}

.ki-abstract-28.ki-solid:before {
  content: "\e91b";
}

.ki-abstract-29.ki-solid:before {
  content: "\e91c";
}

.ki-abstract-30.ki-solid:before {
  content: "\e91d";
}

.ki-abstract-31.ki-solid:before {
  content: "\e91e";
}

.ki-abstract-32.ki-solid:before {
  content: "\e91f";
}

.ki-abstract-33.ki-solid:before {
  content: "\e920";
}

.ki-abstract-34.ki-solid:before {
  content: "\e921";
}

.ki-abstract-35.ki-solid:before {
  content: "\e922";
}

.ki-abstract-36.ki-solid:before {
  content: "\e923";
}

.ki-abstract-37.ki-solid:before {
  content: "\e924";
}

.ki-abstract-38.ki-solid:before {
  content: "\e925";
}

.ki-abstract-39.ki-solid:before {
  content: "\e926";
}

.ki-abstract-40.ki-solid:before {
  content: "\e927";
}

.ki-abstract-41.ki-solid:before {
  content: "\e928";
}

.ki-abstract-42.ki-solid:before {
  content: "\e929";
}

.ki-abstract-43.ki-solid:before {
  content: "\e92a";
}

.ki-abstract-44.ki-solid:before {
  content: "\e92b";
}

.ki-abstract-45.ki-solid:before {
  content: "\e92c";
}

.ki-abstract-46.ki-solid:before {
  content: "\e92d";
}

.ki-abstract-47.ki-solid:before {
  content: "\e92e";
}

.ki-abstract-48.ki-solid:before {
  content: "\e92f";
}

.ki-abstract-49.ki-solid:before {
  content: "\e930";
}

.ki-abstract.ki-solid:before {
  content: "\e931";
}

.ki-add-files.ki-solid:before {
  content: "\e932";
}

.ki-add-folder.ki-solid:before {
  content: "\e933";
}

.ki-add-item.ki-solid:before {
  content: "\e934";
}

.ki-add-notepad.ki-solid:before {
  content: "\e935";
}

.ki-address-book.ki-solid:before {
  content: "\e936";
}

.ki-airplane-square.ki-solid:before {
  content: "\e937";
}

.ki-airplane.ki-solid:before {
  content: "\e938";
}

.ki-airpod.ki-solid:before {
  content: "\e939";
}

.ki-android.ki-solid:before {
  content: "\e93a";
}

.ki-angular.ki-solid:before {
  content: "\e93b";
}

.ki-apple.ki-solid:before {
  content: "\e93c";
}

.ki-archive-tick.ki-solid:before {
  content: "\e93d";
}

.ki-archive.ki-solid:before {
  content: "\e93e";
}

.ki-arrow-circle-left.ki-solid:before {
  content: "\e93f";
}

.ki-arrow-circle-right.ki-solid:before {
  content: "\e940";
}

.ki-arrow-diagonal.ki-solid:before {
  content: "\e941";
}

.ki-arrow-down-left.ki-solid:before {
  content: "\e942";
}

.ki-arrow-down-refraction.ki-solid:before {
  content: "\e943";
}

.ki-arrow-down-right.ki-solid:before {
  content: "\e944";
}

.ki-arrow-down.ki-solid:before {
  content: "\e945";
}

.ki-arrow-left.ki-solid:before {
  content: "\e946";
}

.ki-arrow-mix.ki-solid:before {
  content: "\e947";
}

.ki-arrow-right-left.ki-solid:before {
  content: "\e948";
}

.ki-arrow-right.ki-solid:before {
  content: "\e949";
}

.ki-arrow-two-diagonals.ki-solid:before {
  content: "\e94a";
}

.ki-arrow-up-down.ki-solid:before {
  content: "\e94b";
}

.ki-arrow-up-left.ki-solid:before {
  content: "\e94c";
}

.ki-arrow-up-refraction.ki-solid:before {
  content: "\e94d";
}

.ki-arrow-up-right.ki-solid:before {
  content: "\e94e";
}

.ki-arrow-up.ki-solid:before {
  content: "\e94f";
}

.ki-arrow-zigzag.ki-solid:before {
  content: "\e950";
}

.ki-arrows-circle.ki-solid:before {
  content: "\e951";
}

.ki-arrows-loop.ki-solid:before {
  content: "\e952";
}

.ki-artificial-intelligence.ki-solid:before {
  content: "\e953";
}

.ki-auto-brightness.ki-solid:before {
  content: "\e954";
}

.ki-avalanche.ki-solid:before {
  content: "\e955";
}

.ki-award.ki-solid:before {
  content: "\e956";
}

.ki-badge.ki-solid:before {
  content: "\e957";
}

.ki-bandage.ki-solid:before {
  content: "\e958";
}

.ki-bank.ki-solid:before {
  content: "\e959";
}

.ki-barcode.ki-solid:before {
  content: "\e95a";
}

.ki-basket-ok.ki-solid:before {
  content: "\e95b";
}

.ki-basket.ki-solid:before {
  content: "\e95c";
}

.ki-behance.ki-solid:before {
  content: "\e95d";
}

.ki-bill.ki-solid:before {
  content: "\e95e";
}

.ki-binance-usd.ki-solid:before {
  content: "\e95f";
}

.ki-binance.ki-solid:before {
  content: "\e960";
}

.ki-bitcoin.ki-solid:before {
  content: "\e961";
}

.ki-black-down.ki-solid:before {
  content: "\e962";
}

.ki-black-left-line.ki-solid:before {
  content: "\e963";
}

.ki-black-left.ki-solid:before {
  content: "\e964";
}

.ki-black-right-line.ki-solid:before {
  content: "\e965";
}

.ki-black-right.ki-solid:before {
  content: "\e966";
}

.ki-black-up.ki-solid:before {
  content: "\e967";
}

.ki-bluetooth.ki-solid:before {
  content: "\e968";
}

.ki-book-open.ki-solid:before {
  content: "\e969";
}

.ki-book-square.ki-solid:before {
  content: "\e96a";
}

.ki-book.ki-solid:before {
  content: "\e96b";
}

.ki-bookmark-2.ki-solid:before {
  content: "\e96c";
}

.ki-bookmark.ki-solid:before {
  content: "\e96d";
}

.ki-bootstrap.ki-solid:before {
  content: "\e96e";
}

.ki-briefcase.ki-solid:before {
  content: "\e96f";
}

.ki-brifecase-cros.ki-solid:before {
  content: "\e970";
}

.ki-brifecase-tick.ki-solid:before {
  content: "\e971";
}

.ki-brifecase-timer.ki-solid:before {
  content: "\e972";
}

.ki-brush.ki-solid:before {
  content: "\e973";
}

.ki-bucket-square.ki-solid:before {
  content: "\e974";
}

.ki-bucket.ki-solid:before {
  content: "\e975";
}

.ki-burger-menu-1.ki-solid:before {
  content: "\e976";
}

.ki-burger-menu-2.ki-solid:before {
  content: "\e977";
}

.ki-burger-menu-3.ki-solid:before {
  content: "\e978";
}

.ki-burger-menu-4.ki-solid:before {
  content: "\e979";
}

.ki-burger-menu-5.ki-solid:before {
  content: "\e97a";
}

.ki-burger-menu-6.ki-solid:before {
  content: "\e97b";
}

.ki-burger-menu.ki-solid:before {
  content: "\e97c";
}

.ki-bus.ki-solid:before {
  content: "\e97d";
}

.ki-calculator.ki-solid:before {
  content: "\e97e";
}

.ki-calendar-2.ki-solid:before {
  content: "\e97f";
}

.ki-calendar-8.ki-solid:before {
  content: "\e980";
}

.ki-calendar-add.ki-solid:before {
  content: "\e981";
}

.ki-calendar-edit.ki-solid:before {
  content: "\e982";
}

.ki-calendar-remove.ki-solid:before {
  content: "\e983";
}

.ki-calendar-search.ki-solid:before {
  content: "\e984";
}

.ki-calendar-tick.ki-solid:before {
  content: "\e985";
}

.ki-calendar.ki-solid:before {
  content: "\e986";
}

.ki-call.ki-solid:before {
  content: "\e987";
}

.ki-capsule.ki-solid:before {
  content: "\e988";
}

.ki-car-2.ki-solid:before {
  content: "\e989";
}

.ki-car-3.ki-solid:before {
  content: "\e98a";
}

.ki-car.ki-solid:before {
  content: "\e98b";
}

.ki-category.ki-solid:before {
  content: "\e98c";
}

.ki-cd.ki-solid:before {
  content: "\e98d";
}

.ki-celsius.ki-solid:before {
  content: "\e98e";
}

.ki-chart-line-down-2.ki-solid:before {
  content: "\e98f";
}

.ki-chart-line-down.ki-solid:before {
  content: "\e990";
}

.ki-chart-line-star.ki-solid:before {
  content: "\e991";
}

.ki-chart-line-up-2.ki-solid:before {
  content: "\e992";
}

.ki-chart-line-up.ki-solid:before {
  content: "\e993";
}

.ki-chart-line.ki-solid:before {
  content: "\e994";
}

.ki-chart-pie-3.ki-solid:before {
  content: "\e995";
}

.ki-chart-pie-4.ki-solid:before {
  content: "\e996";
}

.ki-chart-pie-simple.ki-solid:before {
  content: "\e997";
}

.ki-chart-pie-too.ki-solid:before {
  content: "\e998";
}

.ki-chart-simple-2.ki-solid:before {
  content: "\e999";
}

.ki-chart-simple-3.ki-solid:before {
  content: "\e99a";
}

.ki-chart-simple.ki-solid:before {
  content: "\e99b";
}

.ki-chart.ki-solid:before {
  content: "\e99c";
}

.ki-check-circle.ki-solid:before {
  content: "\e99d";
}

.ki-check-square.ki-solid:before {
  content: "\e99e";
}

.ki-check.ki-solid:before {
  content: "\e99f";
}

.ki-cheque.ki-solid:before {
  content: "\e9a0";
}

.ki-chrome.ki-solid:before {
  content: "\e9a1";
}

.ki-classmates.ki-solid:before {
  content: "\e9a2";
}

.ki-click.ki-solid:before {
  content: "\e9a3";
}

.ki-clipboard.ki-solid:before {
  content: "\e9a4";
}

.ki-cloud-add.ki-solid:before {
  content: "\e9a5";
}

.ki-cloud-change.ki-solid:before {
  content: "\e9a6";
}

.ki-cloud-download.ki-solid:before {
  content: "\e9a7";
}

.ki-cloud.ki-solid:before {
  content: "\e9a8";
}

.ki-code.ki-solid:before {
  content: "\e9a9";
}

.ki-coffee.ki-solid:before {
  content: "\e9aa";
}

.ki-color-swatch.ki-solid:before {
  content: "\e9ab";
}

.ki-colors-square.ki-solid:before {
  content: "\e9ac";
}

.ki-compass.ki-solid:before {
  content: "\e9ad";
}

.ki-copy-success.ki-solid:before {
  content: "\e9ae";
}

.ki-copy.ki-solid:before {
  content: "\e9af";
}

.ki-courier-express.ki-solid:before {
  content: "\e9b0";
}

.ki-courier.ki-solid:before {
  content: "\e9b1";
}

.ki-credit-cart.ki-solid:before {
  content: "\e9b2";
}

.ki-cross-circle.ki-solid:before {
  content: "\e9b3";
}

.ki-cross-square.ki-solid:before {
  content: "\e9b4";
}

.ki-cross.ki-solid:before {
  content: "\e9b5";
}

.ki-crown-2.ki-solid:before {
  content: "\e9b6";
}

.ki-crown.ki-solid:before {
  content: "\e9b7";
}

.ki-css.ki-solid:before {
  content: "\e9b8";
}

.ki-cube-2.ki-solid:before {
  content: "\e9b9";
}

.ki-cube-3.ki-solid:before {
  content: "\e9ba";
}

.ki-cup.ki-solid:before {
  content: "\e9bb";
}

.ki-dash.ki-solid:before {
  content: "\e9bc";
}

.ki-data.ki-solid:before {
  content: "\e9bd";
}

.ki-delete-files.ki-solid:before {
  content: "\e9be";
}

.ki-delete-folder.ki-solid:before {
  content: "\e9bf";
}

.ki-delivery-2.ki-solid:before {
  content: "\e9c0";
}

.ki-delivery-3.ki-solid:before {
  content: "\e9c1";
}

.ki-delivery-24.ki-solid:before {
  content: "\e9c2";
}

.ki-delivery-door.ki-solid:before {
  content: "\e9c3";
}

.ki-delivery-geolocation.ki-solid:before {
  content: "\e9c4";
}

.ki-delivery-time.ki-solid:before {
  content: "\e9c5";
}

.ki-delivery.ki-solid:before {
  content: "\e9c6";
}

.ki-design-2.ki-solid:before {
  content: "\e9c7";
}

.ki-design-frame.ki-solid:before {
  content: "\e9c8";
}

.ki-design-mask.ki-solid:before {
  content: "\e9c9";
}

.ki-design.ki-solid:before {
  content: "\e9ca";
}

.ki-devices-2.ki-solid:before {
  content: "\e9cb";
}

.ki-devices.ki-solid:before {
  content: "\e9cc";
}

.ki-diamonds.ki-solid:before {
  content: "\e9cd";
}

.ki-directbox-default.ki-solid:before {
  content: "\e9ce";
}

.ki-disconnect.ki-solid:before {
  content: "\e9cf";
}

.ki-discount.ki-solid:before {
  content: "\e9d0";
}

.ki-disk.ki-solid:before {
  content: "\e9d1";
}

.ki-dislike.ki-solid:before {
  content: "\e9d2";
}

.ki-dj.ki-solid:before {
  content: "\e9d3";
}

.ki-document.ki-solid:before {
  content: "\e9d4";
}

.ki-dollar.ki-solid:before {
  content: "\e9d5";
}

.ki-dots-circle-vertical.ki-solid:before {
  content: "\e9d6";
}

.ki-dots-circle.ki-solid:before {
  content: "\e9d7";
}

.ki-dots-horizontal.ki-solid:before {
  content: "\e9d8";
}

.ki-dots-square-vertical.ki-solid:before {
  content: "\e9d9";
}

.ki-dots-square.ki-solid:before {
  content: "\e9da";
}

.ki-dots-vertical.ki-solid:before {
  content: "\e9db";
}

.ki-double-check-circle.ki-solid:before {
  content: "\e9dc";
}

.ki-double-check.ki-solid:before {
  content: "\e9dd";
}

.ki-double-down.ki-solid:before {
  content: "\e9de";
}

.ki-double-left-arrow.ki-solid:before {
  content: "\e9df";
}

.ki-double-left.ki-solid:before {
  content: "\e9e0";
}

.ki-double-right-arrow.ki-solid:before {
  content: "\e9e1";
}

.ki-double-right.ki-solid:before {
  content: "\e9e2";
}

.ki-double-up.ki-solid:before {
  content: "\e9e3";
}

.ki-down-square.ki-solid:before {
  content: "\e9e4";
}

.ki-down.ki-solid:before {
  content: "\e9e5";
}

.ki-dribbble.ki-solid:before {
  content: "\e9e6";
}

.ki-drop.ki-solid:before {
  content: "\e9e7";
}

.ki-dropbox.ki-solid:before {
  content: "\e9e8";
}

.ki-educare.ki-solid:before {
  content: "\e9e9";
}

.ki-electricity.ki-solid:before {
  content: "\e9ea";
}

.ki-electronic-clock.ki-solid:before {
  content: "\e9eb";
}

.ki-element-1.ki-solid:before {
  content: "\e9ec";
}

.ki-element-2.ki-solid:before {
  content: "\e9ed";
}

.ki-element-3.ki-solid:before {
  content: "\e9ee";
}

.ki-element-4.ki-solid:before {
  content: "\e9ef";
}

.ki-element-5.ki-solid:before {
  content: "\e9f0";
}

.ki-element-6.ki-solid:before {
  content: "\e9f1";
}

.ki-element-7.ki-solid:before {
  content: "\e9f2";
}

.ki-element-8.ki-solid:before {
  content: "\e9f3";
}

.ki-element-9.ki-solid:before {
  content: "\e9f4";
}

.ki-element-10.ki-solid:before {
  content: "\e9f5";
}

.ki-element-11.ki-solid:before {
  content: "\e9f6";
}

.ki-element-12.ki-solid:before {
  content: "\e9f7";
}

.ki-element-equal.ki-solid:before {
  content: "\e9f8";
}

.ki-element-plus.ki-solid:before {
  content: "\e9f9";
}

.ki-emoji-happy.ki-solid:before {
  content: "\e9fa";
}

.ki-enjin-coin.ki-solid:before {
  content: "\e9fb";
}

.ki-entrance-left.ki-solid:before {
  content: "\e9fc";
}

.ki-entrance-right.ki-solid:before {
  content: "\e9fd";
}

.ki-eraser.ki-solid:before {
  content: "\e9fe";
}

.ki-euro.ki-solid:before {
  content: "\e9ff";
}

.ki-exit-down.ki-solid:before {
  content: "\ea00";
}

.ki-exit-left.ki-solid:before {
  content: "\ea01";
}

.ki-exit-right-corner.ki-solid:before {
  content: "\ea02";
}

.ki-exit-right.ki-solid:before {
  content: "\ea03";
}

.ki-exit-up.ki-solid:before {
  content: "\ea04";
}

.ki-external-drive.ki-solid:before {
  content: "\ea05";
}

.ki-eye-slash.ki-solid:before {
  content: "\ea06";
}

.ki-eye.ki-solid:before {
  content: "\ea07";
}

.ki-facebook.ki-solid:before {
  content: "\ea08";
}

.ki-faceid.ki-solid:before {
  content: "\ea09";
}

.ki-fasten.ki-solid:before {
  content: "\ea0a";
}

.ki-fat-rows.ki-solid:before {
  content: "\ea0b";
}

.ki-feather.ki-solid:before {
  content: "\ea0c";
}

.ki-figma.ki-solid:before {
  content: "\ea0d";
}

.ki-file-added.ki-solid:before {
  content: "\ea0e";
}

.ki-file-deleted.ki-solid:before {
  content: "\ea0f";
}

.ki-file-down.ki-solid:before {
  content: "\ea10";
}

.ki-file-left.ki-solid:before {
  content: "\ea11";
}

.ki-file-right.ki-solid:before {
  content: "\ea12";
}

.ki-file-sheet.ki-solid:before {
  content: "\ea13";
}

.ki-file-up.ki-solid:before {
  content: "\ea14";
}

.ki-file.ki-solid:before {
  content: "\ea15";
}

.ki-files-tablet.ki-solid:before {
  content: "\ea16";
}

.ki-filter-edit.ki-solid:before {
  content: "\ea17";
}

.ki-filter-search.ki-solid:before {
  content: "\ea18";
}

.ki-filter-square.ki-solid:before {
  content: "\ea19";
}

.ki-filter-tablet.ki-solid:before {
  content: "\ea1a";
}

.ki-filter-tick.ki-solid:before {
  content: "\ea1b";
}

.ki-filter.ki-solid:before {
  content: "\ea1c";
}

.ki-finance-calculator.ki-solid:before {
  content: "\ea1d";
}

.ki-financial-schedule.ki-solid:before {
  content: "\ea1e";
}

.ki-fingerprint-scanning.ki-solid:before {
  content: "\ea1f";
}

.ki-flag.ki-solid:before {
  content: "\ea20";
}

.ki-flash-circle.ki-solid:before {
  content: "\ea21";
}

.ki-flask.ki-solid:before {
  content: "\ea22";
}

.ki-focus.ki-solid:before {
  content: "\ea23";
}

.ki-folder-added.ki-solid:before {
  content: "\ea24";
}

.ki-folder-down.ki-solid:before {
  content: "\ea25";
}

.ki-folder-up.ki-solid:before {
  content: "\ea26";
}

.ki-folder.ki-solid:before {
  content: "\ea27";
}

.ki-frame.ki-solid:before {
  content: "\ea28";
}

.ki-gear.ki-solid:before {
  content: "\ea29";
}

.ki-general-mouse.ki-solid:before {
  content: "\ea2a";
}

.ki-geolocation-home.ki-solid:before {
  content: "\ea2b";
}

.ki-geolocation.ki-solid:before {
  content: "\ea2c";
}

.ki-ghost.ki-solid:before {
  content: "\ea2d";
}

.ki-gift.ki-solid:before {
  content: "\ea2e";
}

.ki-github.ki-solid:before {
  content: "\ea2f";
}

.ki-glass.ki-solid:before {
  content: "\ea30";
}

.ki-google-play.ki-solid:before {
  content: "\ea31";
}

.ki-google.ki-solid:before {
  content: "\ea32";
}

.ki-graph-2.ki-solid:before {
  content: "\ea33";
}

.ki-graph-3.ki-solid:before {
  content: "\ea34";
}

.ki-graph-4.ki-solid:before {
  content: "\ea35";
}

.ki-graph-up.ki-solid:before {
  content: "\ea36";
}

.ki-graph.ki-solid:before {
  content: "\ea37";
}

.ki-grid-2.ki-solid:before {
  content: "\ea38";
}

.ki-grid-frame.ki-solid:before {
  content: "\ea39";
}

.ki-grid.ki-solid:before {
  content: "\ea3a";
}

.ki-handcart.ki-solid:before {
  content: "\ea3b";
}

.ki-happy-emoji.ki-solid:before {
  content: "\ea3c";
}

.ki-heart-circle.ki-solid:before {
  content: "\ea3d";
}

.ki-heart.ki-solid:before {
  content: "\ea3e";
}

.ki-home-1.ki-solid:before {
  content: "\ea3f";
}

.ki-home-2.ki-solid:before {
  content: "\ea40";
}

.ki-home-3.ki-solid:before {
  content: "\ea41";
}

.ki-home.ki-solid:before {
  content: "\ea42";
}

.ki-html.ki-solid:before {
  content: "\ea43";
}

.ki-icon.ki-solid:before {
  content: "\ea44";
}

.ki-illustrator.ki-solid:before {
  content: "\ea45";
}

.ki-information-2.ki-solid:before {
  content: "\ea46";
}

.ki-information-3.ki-solid:before {
  content: "\ea47";
}

.ki-information-4.ki-solid:before {
  content: "\ea48";
}

.ki-information-5.ki-solid:before {
  content: "\ea49";
}

.ki-information.ki-solid:before {
  content: "\ea4a";
}

.ki-instagram.ki-solid:before {
  content: "\ea4b";
}

.ki-joystick.ki-solid:before {
  content: "\ea4c";
}

.ki-js-2.ki-solid:before {
  content: "\ea4d";
}

.ki-js.ki-solid:before {
  content: "\ea4e";
}

.ki-kanban.ki-solid:before {
  content: "\ea4f";
}

.ki-key-square.ki-solid:before {
  content: "\ea50";
}

.ki-key.ki-solid:before {
  content: "\ea51";
}

.ki-keyboard.ki-solid:before {
  content: "\ea52";
}

.ki-laptop.ki-solid:before {
  content: "\ea53";
}

.ki-laravel.ki-solid:before {
  content: "\ea54";
}

.ki-left-square.ki-solid:before {
  content: "\ea55";
}

.ki-left.ki-solid:before {
  content: "\ea56";
}

.ki-like-2.ki-solid:before {
  content: "\ea57";
}

.ki-like-folder.ki-solid:before {
  content: "\ea58";
}

.ki-like-shapes.ki-solid:before {
  content: "\ea59";
}

.ki-like-tag.ki-solid:before {
  content: "\ea5a";
}

.ki-like.ki-solid:before {
  content: "\ea5b";
}

.ki-loading.ki-solid:before {
  content: "\ea5c";
}

.ki-lock-2.ki-solid:before {
  content: "\ea5d";
}

.ki-lock-3.ki-solid:before {
  content: "\ea5e";
}

.ki-lock.ki-solid:before {
  content: "\ea5f";
}

.ki-logistic.ki-solid:before {
  content: "\ea60";
}

.ki-lots-shopping.ki-solid:before {
  content: "\ea61";
}

.ki-lovely.ki-solid:before {
  content: "\ea62";
}

.ki-lts.ki-solid:before {
  content: "\ea63";
}

.ki-magnifier.ki-solid:before {
  content: "\ea64";
}

.ki-map.ki-solid:before {
  content: "\ea65";
}

.ki-mask.ki-solid:before {
  content: "\ea66";
}

.ki-maximize.ki-solid:before {
  content: "\ea67";
}

.ki-medal-star.ki-solid:before {
  content: "\ea68";
}

.ki-menu.ki-solid:before {
  content: "\ea69";
}

.ki-message-add.ki-solid:before {
  content: "\ea6a";
}

.ki-message-edit.ki-solid:before {
  content: "\ea6b";
}

.ki-message-minus.ki-solid:before {
  content: "\ea6c";
}

.ki-message-notif.ki-solid:before {
  content: "\ea6d";
}

.ki-message-programming.ki-solid:before {
  content: "\ea6e";
}

.ki-message-question.ki-solid:before {
  content: "\ea6f";
}

.ki-message-text-2.ki-solid:before {
  content: "\ea70";
}

.ki-message-text.ki-solid:before {
  content: "\ea71";
}

.ki-messages.ki-solid:before {
  content: "\ea72";
}

.ki-microsoft.ki-solid:before {
  content: "\ea73";
}

.ki-milk.ki-solid:before {
  content: "\ea74";
}

.ki-minus-circle.ki-solid:before {
  content: "\ea75";
}

.ki-minus-folder.ki-solid:before {
  content: "\ea76";
}

.ki-minus-square.ki-solid:before {
  content: "\ea77";
}

.ki-minus.ki-solid:before {
  content: "\ea78";
}

.ki-monitor-mobile.ki-solid:before {
  content: "\ea79";
}

.ki-moon.ki-solid:before {
  content: "\ea7a";
}

.ki-more-2.ki-solid:before {
  content: "\ea7b";
}

.ki-mouse-circle.ki-solid:before {
  content: "\ea7c";
}

.ki-mouse-square.ki-solid:before {
  content: "\ea7d";
}

.ki-mouse.ki-solid:before {
  content: "\ea7e";
}

.ki-nexo.ki-solid:before {
  content: "\ea7f";
}

.ki-night-day.ki-solid:before {
  content: "\ea80";
}

.ki-note-2.ki-solid:before {
  content: "\ea81";
}

.ki-note.ki-solid:before {
  content: "\ea82";
}

.ki-notepad-bookmark.ki-solid:before {
  content: "\ea83";
}

.ki-notepad-edit.ki-solid:before {
  content: "\ea84";
}

.ki-notepad.ki-solid:before {
  content: "\ea85";
}

.ki-notification-2.ki-solid:before {
  content: "\ea86";
}

.ki-notification-bing.ki-solid:before {
  content: "\ea87";
}

.ki-notification-circle.ki-solid:before {
  content: "\ea88";
}

.ki-notification-favorite.ki-solid:before {
  content: "\ea89";
}

.ki-notification-on.ki-solid:before {
  content: "\ea8a";
}

.ki-notification-status.ki-solid:before {
  content: "\ea8b";
}

.ki-notification.ki-solid:before {
  content: "\ea8c";
}

.ki-ocean.ki-solid:before {
  content: "\ea8d";
}

.ki-office-bag.ki-solid:before {
  content: "\ea8e";
}

.ki-package.ki-solid:before {
  content: "\ea8f";
}

.ki-pails.ki-solid:before {
  content: "\ea90";
}

.ki-paintbucket.ki-solid:before {
  content: "\ea91";
}

.ki-paper-clip.ki-solid:before {
  content: "\ea92";
}

.ki-parcel-tracking.ki-solid:before {
  content: "\ea93";
}

.ki-parcel.ki-solid:before {
  content: "\ea94";
}

.ki-password-check.ki-solid:before {
  content: "\ea95";
}

.ki-paypal.ki-solid:before {
  content: "\ea96";
}

.ki-pencil.ki-solid:before {
  content: "\ea97";
}

.ki-people.ki-solid:before {
  content: "\ea98";
}

.ki-percentage.ki-solid:before {
  content: "\ea99";
}

.ki-phone.ki-solid:before {
  content: "\ea9a";
}

.ki-photoshop.ki-solid:before {
  content: "\ea9b";
}

.ki-picture.ki-solid:before {
  content: "\ea9c";
}

.ki-pill.ki-solid:before {
  content: "\ea9d";
}

.ki-pin.ki-solid:before {
  content: "\ea9e";
}

.ki-plus-circle.ki-solid:before {
  content: "\ea9f";
}

.ki-plus-square.ki-solid:before {
  content: "\eaa0";
}

.ki-plus.ki-solid:before {
  content: "\eaa1";
}

.ki-pointers.ki-solid:before {
  content: "\eaa2";
}

.ki-price-tag.ki-solid:before {
  content: "\eaa3";
}

.ki-printer.ki-solid:before {
  content: "\eaa4";
}

.ki-profile-circle.ki-solid:before {
  content: "\eaa5";
}

.ki-profile-user.ki-solid:before {
  content: "\eaa6";
}

.ki-pulse.ki-solid:before {
  content: "\eaa7";
}

.ki-purchase.ki-solid:before {
  content: "\eaa8";
}

.ki-python.ki-solid:before {
  content: "\eaa9";
}

.ki-question-2.ki-solid:before {
  content: "\eaaa";
}

.ki-question.ki-solid:before {
  content: "\eaab";
}

.ki-questionnaire-tablet.ki-solid:before {
  content: "\eaac";
}

.ki-ranking.ki-solid:before {
  content: "\eaad";
}

.ki-react.ki-solid:before {
  content: "\eaae";
}

.ki-receipt-square.ki-solid:before {
  content: "\eaaf";
}

.ki-rescue.ki-solid:before {
  content: "\eab0";
}

.ki-right-left.ki-solid:before {
  content: "\eab1";
}

.ki-right-square.ki-solid:before {
  content: "\eab2";
}

.ki-right.ki-solid:before {
  content: "\eab3";
}

.ki-rocket.ki-solid:before {
  content: "\eab4";
}

.ki-route.ki-solid:before {
  content: "\eab5";
}

.ki-router.ki-solid:before {
  content: "\eab6";
}

.ki-row-horizontal.ki-solid:before {
  content: "\eab7";
}

.ki-row-vertical.ki-solid:before {
  content: "\eab8";
}

.ki-safe-home.ki-solid:before {
  content: "\eab9";
}

.ki-satellite.ki-solid:before {
  content: "\eaba";
}

.ki-save-2.ki-solid:before {
  content: "\eabb";
}

.ki-save-deposit.ki-solid:before {
  content: "\eabc";
}

.ki-scan-barcode.ki-solid:before {
  content: "\eabd";
}

.ki-scooter-2.ki-solid:before {
  content: "\eabe";
}

.ki-scooter.ki-solid:before {
  content: "\eabf";
}

.ki-screen.ki-solid:before {
  content: "\eac0";
}

.ki-scroll.ki-solid:before {
  content: "\eac1";
}

.ki-search-list.ki-solid:before {
  content: "\eac2";
}

.ki-security-check.ki-solid:before {
  content: "\eac3";
}

.ki-security-user.ki-solid:before {
  content: "\eac4";
}

.ki-send.ki-solid:before {
  content: "\eac5";
}

.ki-setting-2.ki-solid:before {
  content: "\eac6";
}

.ki-setting-3.ki-solid:before {
  content: "\eac7";
}

.ki-setting-4.ki-solid:before {
  content: "\eac8";
}

.ki-setting.ki-solid:before {
  content: "\eac9";
}

.ki-share.ki-solid:before {
  content: "\eaca";
}

.ki-shield-cross.ki-solid:before {
  content: "\eacb";
}

.ki-shield-search.ki-solid:before {
  content: "\eacc";
}

.ki-shield-slash.ki-solid:before {
  content: "\eacd";
}

.ki-shield-tick.ki-solid:before {
  content: "\eace";
}

.ki-shield.ki-solid:before {
  content: "\eacf";
}

.ki-ship.ki-solid:before {
  content: "\ead0";
}

.ki-shop.ki-solid:before {
  content: "\ead1";
}

.ki-simcard-2.ki-solid:before {
  content: "\ead2";
}

.ki-simcard.ki-solid:before {
  content: "\ead3";
}

.ki-size.ki-solid:before {
  content: "\ead4";
}

.ki-slack.ki-solid:before {
  content: "\ead5";
}

.ki-slider-horizontal-2.ki-solid:before {
  content: "\ead6";
}

.ki-slider-horizontal.ki-solid:before {
  content: "\ead7";
}

.ki-slider-vertical-2.ki-solid:before {
  content: "\ead8";
}

.ki-slider-vertical.ki-solid:before {
  content: "\ead9";
}

.ki-slider.ki-solid:before {
  content: "\eada";
}

.ki-sms.ki-solid:before {
  content: "\eadb";
}

.ki-snapchat.ki-solid:before {
  content: "\eadc";
}

.ki-social-media.ki-solid:before {
  content: "\eadd";
}

.ki-soft-2.ki-solid:before {
  content: "\eade";
}

.ki-soft-3.ki-solid:before {
  content: "\eadf";
}

.ki-soft.ki-solid:before {
  content: "\eae0";
}

.ki-some-files.ki-solid:before {
  content: "\eae1";
}

.ki-sort.ki-solid:before {
  content: "\eae2";
}

.ki-speaker.ki-solid:before {
  content: "\eae3";
}

.ki-spotify.ki-solid:before {
  content: "\eae4";
}

.ki-spring-framework.ki-solid:before {
  content: "\eae5";
}

.ki-square-brackets.ki-solid:before {
  content: "\eae6";
}

.ki-star.ki-solid:before {
  content: "\eae7";
}

.ki-status.ki-solid:before {
  content: "\eae8";
}

.ki-subtitle.ki-solid:before {
  content: "\eae9";
}

.ki-sun.ki-solid:before {
  content: "\eaea";
}

.ki-support-24.ki-solid:before {
  content: "\eaeb";
}

.ki-switch.ki-solid:before {
  content: "\eaec";
}

.ki-syringe.ki-solid:before {
  content: "\eaed";
}

.ki-tablet-book.ki-solid:before {
  content: "\eaee";
}

.ki-tablet-delete.ki-solid:before {
  content: "\eaef";
}

.ki-tablet-down.ki-solid:before {
  content: "\eaf0";
}

.ki-tablet-ok.ki-solid:before {
  content: "\eaf1";
}

.ki-tablet-text-down.ki-solid:before {
  content: "\eaf2";
}

.ki-tablet-text-up.ki-solid:before {
  content: "\eaf3";
}

.ki-tablet-up.ki-solid:before {
  content: "\eaf4";
}

.ki-tablet.ki-solid:before {
  content: "\eaf5";
}

.ki-tag-cross.ki-solid:before {
  content: "\eaf6";
}

.ki-tag.ki-solid:before {
  content: "\eaf7";
}

.ki-teacher.ki-solid:before {
  content: "\eaf8";
}

.ki-tech-wifi.ki-solid:before {
  content: "\eaf9";
}

.ki-technology-2.ki-solid:before {
  content: "\eafa";
}

.ki-technology-3.ki-solid:before {
  content: "\eafb";
}

.ki-technology-4.ki-solid:before {
  content: "\eafc";
}

.ki-technology.ki-solid:before {
  content: "\eafd";
}

.ki-telephone-geolocation.ki-solid:before {
  content: "\eafe";
}

.ki-test-tubes.ki-solid:before {
  content: "\eaff";
}

.ki-text-align-center.ki-solid:before {
  content: "\eb00";
}

.ki-text-align-justify-center.ki-solid:before {
  content: "\eb01";
}

.ki-text-align-left.ki-solid:before {
  content: "\eb02";
}

.ki-text-align-right.ki-solid:before {
  content: "\eb03";
}

.ki-text-bold.ki-solid:before {
  content: "\eb04";
}

.ki-text-circle.ki-solid:before {
  content: "\eb05";
}

.ki-text-italic.ki-solid:before {
  content: "\eb06";
}

.ki-text-number.ki-solid:before {
  content: "\eb07";
}

.ki-text-strikethrough.ki-solid:before {
  content: "\eb08";
}

.ki-text-underline.ki-solid:before {
  content: "\eb09";
}

.ki-text.ki-solid:before {
  content: "\eb0a";
}

.ki-thermometer.ki-solid:before {
  content: "\eb0b";
}

.ki-theta.ki-solid:before {
  content: "\eb0c";
}

.ki-tiktok.ki-solid:before {
  content: "\eb0d";
}

.ki-time.ki-solid:before {
  content: "\eb0e";
}

.ki-timer.ki-solid:before {
  content: "\eb0f";
}

.ki-to-left.ki-solid:before {
  content: "\eb10";
}

.ki-to-right.ki-solid:before {
  content: "\eb11";
}

.ki-toggle-off-circle.ki-solid:before {
  content: "\eb12";
}

.ki-toggle-off.ki-solid:before {
  content: "\eb13";
}

.ki-toggle-on-circle.ki-solid:before {
  content: "\eb14";
}

.ki-toggle-on.ki-solid:before {
  content: "\eb15";
}

.ki-trailer.ki-solid:before {
  content: "\eb16";
}

.ki-trash-square.ki-solid:before {
  content: "\eb17";
}

.ki-trash.ki-solid:before {
  content: "\eb18";
}

.ki-tree.ki-solid:before {
  content: "\eb19";
}

.ki-trello.ki-solid:before {
  content: "\eb1a";
}

.ki-triangle.ki-solid:before {
  content: "\eb1b";
}

.ki-truck.ki-solid:before {
  content: "\eb1c";
}

.ki-ts.ki-solid:before {
  content: "\eb1d";
}

.ki-twitch.ki-solid:before {
  content: "\eb1e";
}

.ki-twitter.ki-solid:before {
  content: "\eb1f";
}

.ki-two-credit-cart.ki-solid:before {
  content: "\eb20";
}

.ki-underlining.ki-solid:before {
  content: "\eb21";
}

.ki-up-down.ki-solid:before {
  content: "\eb22";
}

.ki-up-square.ki-solid:before {
  content: "\eb23";
}

.ki-up.ki-solid:before {
  content: "\eb24";
}

.ki-update-file.ki-solid:before {
  content: "\eb25";
}

.ki-update-folder.ki-solid:before {
  content: "\eb26";
}

.ki-user-edit.ki-solid:before {
  content: "\eb27";
}

.ki-user-square.ki-solid:before {
  content: "\eb28";
}

.ki-user-tick.ki-solid:before {
  content: "\eb29";
}

.ki-user.ki-solid:before {
  content: "\eb2a";
}

.ki-verify.ki-solid:before {
  content: "\eb2b";
}

.ki-vibe.ki-solid:before {
  content: "\eb2c";
}

.ki-virus.ki-solid:before {
  content: "\eb2d";
}

.ki-vue.ki-solid:before {
  content: "\eb2e";
}

.ki-vuesax.ki-solid:before {
  content: "\eb2f";
}

.ki-wallet.ki-solid:before {
  content: "\eb30";
}

.ki-wanchain.ki-solid:before {
  content: "\eb31";
}

.ki-watch.ki-solid:before {
  content: "\eb32";
}

.ki-whatsapp.ki-solid:before {
  content: "\eb33";
}

.ki-wifi-home.ki-solid:before {
  content: "\eb34";
}

.ki-wifi-square.ki-solid:before {
  content: "\eb35";
}

.ki-wifi.ki-solid:before {
  content: "\eb36";
}

.ki-wrench.ki-solid:before {
  content: "\eb37";
}

.ki-xaomi.ki-solid:before {
  content: "\eb38";
}

.ki-xd.ki-solid:before {
  content: "\eb39";
}

.ki-xmr.ki-solid:before {
  content: "\eb3a";
}

.ki-yii.ki-solid:before {
  content: "\eb3b";
}

.ki-youtube.ki-solid:before {
  content: "\eb3c";
}
